import React from 'react';

const useDynamicRefs = () => {
    const refsStore = React.useRef({}).current;

    const getRef = (key) => {
        if (!refsStore[key]) {
            refsStore[key] = React.createRef();
        }
        return refsStore[key];
    };

    const getAllValues = (formId) => {
        const allValues = {};
        Object.keys(refsStore).forEach(key => {
            if (!formId || key.startsWith(formId)) {
                const element = refsStore[key].current;
                if (element) {
                    const adjustedKey = formId ? key.replace(`${formId}-`, '') : key;
                    allValues[adjustedKey] = element.value;
                }
            }
        });
        return allValues;
    };

    return { getRef, getAllValues };
};


export default useDynamicRefs;