const validDateAfterOrEquals = (input, date) => {
    let inputJsonDateTime = new Date(input).toJSON();
    let dateJsonDateTime = new Date(date).toJSON();
    if (inputJsonDateTime === null || dateJsonDateTime === null)
        return false;

    let inputDate = new Date(inputJsonDateTime.slice(0, 10));
    let dateDate = new Date(dateJsonDateTime.slice(0, 10));
    if (dateDate.getTime() > inputDate.getTime())
        return false;

    return true;
};

const validDateBeforeOrEquals = (input, date) => {
    let inputJsonDateTime = new Date(input).toJSON();
    let dateJsonDateTime = new Date(date).toJSON();
    if (inputJsonDateTime === null || dateJsonDateTime === null)
        return false;

    let inputDate = new Date(inputJsonDateTime.slice(0, 10));
    let dateDate = new Date(dateJsonDateTime.slice(0, 10));
    if (dateDate.getTime() < inputDate.getTime())
        return false;

    return true;
};

const validateZamerName = (input) => {
    let val = input.value;
    let data = input.list;
    let optionExists = false;
    for (let i = 0; i < data.options.length; i++) {
        if (data.options[i].value.indexOf(val) > -1) {
            optionExists = true;
            break;
        }
    }
    input.setCustomValidity(optionExists ? '' : 'Zámer nenalezen');
    input.reportValidity();
};

const checkZamerStrictName = (input) => {
    let val = input.value;
    let data = input.list.options;
    for (let i = 0; i < data.length; i++) {
        if (data[i].value === val) {
            return true;
        }
    }

    return false;
};

const  dateValidation = (date = '') => {
    if (!date || !date?.trim()) {
        return false;
    }

    let dateObj = new Date(date).getFullYear();

    if ((dateObj / 1000) >= 1 && (dateObj / 1000) < 10) {
        return false;
    }
    
    return true;
};

const affectedPropertyNotAdded = (form, parcelAndConstructionData) => {
    console.log('this is parcelncons: ', parcelAndConstructionData);
    return Boolean(form?.adjacentLand && ((!parcelAndConstructionData?.affectedBuildConstructions || parcelAndConstructionData.affectedBuildConstructions.length < 1)
        && (!parcelAndConstructionData?.affectedBuildParcels || parcelAndConstructionData.affectedBuildParcels.length < 1)));
};

export {
    validDateAfterOrEquals,
    validDateBeforeOrEquals,
    validateZamerName,
    checkZamerStrictName,
    dateValidation,
    affectedPropertyNotAdded
};
