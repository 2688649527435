import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { shouldNotSave, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { CharCount } from '../../components/index.js';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent15 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ focused, setFocused ] = React.useState({
        thingName: false,
        applicationSupplement: false
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            thingName: '',
            applicationSupplement: '',
        }, 
    });
    const prevRequest= usePrevious();
    const firstRender = useFirstRender();
    const form = request?.form;
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response?.data?.buildApplicationForms[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form, thingName: loadedData.thingName ?? '', applicationSupplement: loadedData.applicationSupplement ?? ''} }));
                }
            }
            else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;   
            console.log(form);
            // throws error
            if (!form?.thingName?.trim())
            {
                err = true;
            }
            else if (!form?.applicationSupplement?.trim())
            {
                err = true;
            }
    
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));
    
            return !err;
        }
    }));

    const hasError = (!form?.thingName?.trim() && (error || focused.thingName));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Doplnění`}</p>
            </Row>

            <Row className=''>
                <p className='p-0 mb-4 title secondary'>Popis doplnění žádosti či odstranění vad žádosti</p>
                <form className='p-0'>
                    <Col md={6}>
                        <label className='d-flex flex-column p-0 mt-4 textarea-input' style={{color: hasError ? '#C52A3A' : ''}}>
                        Věc
                            <input
                                required
                                className='id-input2'
                                defaultValue={form?.thingName ?? ''}
                                {...(focused.thingName && { focused: 'true' })}
                                style={{border: hasError ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => { 
                                    setRequest(state => ({...state, form: {...state.form, thingName: e.target.value }})); 
                                    handleFocused('thingName');
                                }}
                                type='text'
                            />
                        </label>
                    </Col>
                    {hasError && <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                        Bez vyplnění pole &quot;Věc&quot; není možné pokračovat.
                    </Container>}
                    <label className='d-flex flex-column p-0 mt-4 textarea-input' style={{color: (!form?.applicationSupplement?.trim() && (error || focused.applicationSupplement)) ? '#C52A3A' : ''}}>
                    Popis doplnění žádosti či odstranění vad žádosti
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2 text-area--validation'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                required
                                {...(focused.applicationSupplement && { focused: 'true' })}
                                style={{border: error && !form?.applicationSupplement?.trim() ? '#C52A3A solid 1px' : ''}}
                                rows="5"
                                defaultValue={form?.applicationSupplement ?? ''}
                                onBlur={(e) => { 
                                    setRequest(state => ({...state, form: {...state.form, applicationSupplement: e.target.value }})); 
                                    handleFocused('applicationSupplement');
                                }}
                            />
                            <CharCount 
                                value={form?.applicationSupplement} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                    </label>
                </form>
            </Row>
            
        </Container>
    );
};

RozsahComponent15.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent15;