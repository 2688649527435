import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { errorIcon } from '../assets';
import PropTypes from 'prop-types';
import { getPrijemceDetailWithId, isRequestOK } from '../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Container } from 'react-bootstrap';
import { shouldNotSetAuthority } from '../helperFunctions/helpers';
import DatalistInput from 'react-datalist-input';
import { AUTHORITY_MODEL } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import { CustomListElement } from './index';

const SearchWithDataList = ({
    request, // obj
    authorityType, // str
    setRequest, // func
    paramFirst, // str
    paramSecond, // str
    setSearchRequest, // func
    searchRequest, // obj
    listData, // arr
    setListData, // func
    dynamicVal, // str
    authorityObj,
    stavebniUradText, //str
    isSearchLoading = false,
    setIsSearchLoading = () => {},
}) => {
    const { token } = useContext(AuthContext);
    const datalistRef = useRef(null);
    const {stagesArr, stepValue} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const inputArea = datalistRef.current?.childNodes[1];
    const selectedAuthorityName = request.buildIntention?.[authorityType]?.authorityName ?? '';
    const abortControllerRef = useRef(new AbortController());

    const fetchAndUpdate = async (id) => {
        setIsSearchLoading(true);
        const response = await getPrijemceDetailWithId(id, token, abortControllerRef.current.signal);
        
        if (!abortControllerRef.current.signal.aborted && isRequestOK(response.status)) {
            const authority = response.data?.data;

            if (!shouldNotSetAuthority(authority)) {
                setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, [authorityType]: authority }}));
                setSearchRequest(prev => ({...prev, [paramFirst]: authority.authorityName ?? '' }));
            }
            setIsSearchLoading(false);
        } else {
            setIsSearchLoading(false);
        }
    };
    
    const handleFocused = (e) => {
        if (e.target.type !== 'text') {
            return;
        }

        if (!authorityObj?.authorityName?.trim()) {
            inputArea?.classList.add('error');
        } else if (authorityObj?.authorityName?.trim()) {
            inputArea?.classList.remove('error');
        }
    };

    const handleChange = (e) => {   
        setSearchRequest(prev => ({...prev, [paramFirst]: e.target.value, [paramSecond]: dynamicVal}));
        
        if (e.target.value?.trim() && authorityObj?.authorityName?.trim()) {
            inputArea?.classList.remove('error');
        } else if (!e.target.value?.trim()) {
            inputArea?.classList.add('error');
            setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, [authorityType]: AUTHORITY_MODEL }}));
            setListData([]);
        }
    };

    const handleOnSelect = useCallback((selectedItem) => {
        fetchAndUpdate(selectedItem.id);
        inputArea?.classList.remove('error');
        setListData([]);
    }, []);

    const dataListItems = useMemo(
        () => {
            return (selectedAuthorityName !== searchRequest?.[paramFirst]) ? 
                (listData || []).map((item) => ({
                    id: item?.id,
                    //value: item[firstKeyToShow],
                    node: <CustomListElement item={item} />,
                })) : [];
        }, [listData, selectedAuthorityName, searchRequest[paramFirst]]
    );

    useEffect(() => {
        const controller = abortControllerRef.current;

        if (!error && !authorityObj?.authorityName?.trim()) {
            inputArea?.focus();
        }

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (!error) {
            inputArea?.classList.remove('error');
        } else if (error) {
            inputArea?.classList.add('error');
        }
    }, [error]);

    return (
        <Container fluid className='mt-2'>
            {(stavebniUradText) && (error && !authorityObj?.authorityName?.trim()) &&
                <p className='p-0 title' style={{color: '#C52A3A'}}>Stavební úřad</p> || stavebniUradText &&
                <p className='p-0 title'>Stavební úřad</p>}
            <DatalistInput
                ref={datalistRef}
                className={`${isSearchLoading ? 'with-loading' : ''}`}
                placeholder='Hledat' 
                items={dataListItems ?? []} 
                onSelect={handleOnSelect} 
                onChange={handleChange}
                onBlur={handleFocused}
                value={searchRequest[paramFirst] ?? ''}
            />
            {(error && !authorityObj?.authorityName?.trim()) && <Container className='mt-3'>
                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-1' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění tohoto pole není možné pokračovat.</p>
            </Container>}
        </Container>
    );
};

SearchWithDataList.propTypes = {
    searchRequest: PropTypes.shape({
        paramFirst: PropTypes.string,
        paramSecond: PropTypes.string,
    }),
    dynamicVal: PropTypes.string,
    authorityType: PropTypes.string,
    paramFirst: PropTypes.string,
    paramSecond: PropTypes.string,
    setSearchRequest: PropTypes.func,
    setListData: PropTypes.func,
    setRequest: PropTypes.func,
    request: PropTypes.object,
    listData: PropTypes.arrayOf(Object),
    authorityObj: PropTypes.object,
    stavebniUradText: PropTypes.bool,
    isSearchLoading: PropTypes.bool,
    setIsSearchLoading: PropTypes.func
};


export default SearchWithDataList;
