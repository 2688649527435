import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import {  shouldNotSave, spreadObject, spreadParcelsAndConstructions, checkLimitAndInsertText } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { errorIcon, infoCircle, warningUnfilled } from '../../assets/index.js';
import { COUNTRIES, PROPERTY_DEVELOPER_MODEL } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { FindAndProcessIco, StavbyTable } from '../index';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const StavbaComponent12 = ({ setCheckbox, urlPath, progressElements, setProgressElements }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = React.useState({
        nazevStavby: false,
        obec: false,
        datumDokonceni: false,
        predcasneUzivani: false,
        predcasneUzivaniDuvod: false,
        draftConditions: false,
        estimatedCompletionDate: false,
        mapIdentifier: false,
        confirmedGeometricPlan: false,
        descriptionOfDeviations: false,
        icoNum: false,
        names: false,
        lastName: false,
        city: false,
        zip: false,
        poName: false,
    });
    const [isExceeded, setIsExceeded] = useState(false);
    
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        approvedConstructions: [],
    });
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        expectedEndDate: false,
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            draftConditions: '',
            earlyUsePeriod: '',
            requestJustification: '',
        },
        buildIntention: {
            buildingName: '',
            address: {
                city: '',
                cityPart: '',
            },
            confirmedGeometricPlan: '',
            mapIdentifier: '',
            estimatedCompletionDate: '',
            notCarriedWithDeviations: true,
            carriedWithDeviations: null,
            descriptionOfDeviations: '',
            selfHelp: true,
            propertyDeveloper: false,
            setOfBuildings: false,
            propertyDeveloperDetails: PROPERTY_DEVELOPER_MODEL,
        }
    });
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const buildIntention =  request?.buildIntention;
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
        [1]: {count: 0, limit: 10000},
        [2]: {count: 0, limit: 1000},
    });
    
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );

                const loadedData = response?.data?.buildIntention;
                const loadedDataForm = response?.data?.buildApplicationForms[0];
                if (loadedData) {
                    // eslint-disable-next-line no-unused-vars
                    const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = loadedData;
                    setRequest(state => ({
                        ...state,
                        buildIntention: {...state.buildIntention, ...spreadObject(rest), notCarriedWithDeviations: loadedData.notCarriedWithDeviations ?? true},
                        ...(loadedDataForm ? { 
                            form: {
                                ...state.form,
                                draftConditions: loadedDataForm.draftConditions ?? '',
                                earlyUsePeriod: loadedDataForm.earlyUsePeriod ?? '',
                                requestJustification: loadedDataForm.requestJustification ?? '',
                            } 
                        } : {})
                    }));
                }
            }
            else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (buildIntention.carriedWithDeviations === null) {
            return;
        }

        setCheckbox({
            first: buildIntention.carriedWithDeviations ?? false,
        });
    }, [buildIntention.carriedWithDeviations]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        const isPropertyDeveloper = Boolean(request.buildIntention.propertyDeveloper);
        if (!isPropertyDeveloper) {
            const updatedRequest = {...request, buildIntention: {...request.buildIntention, propertyDeveloperDetails: {
                ...PROPERTY_DEVELOPER_MODEL,
                nationality: ''
            }} };
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }

        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (request?.buildIntention?.buildConstructions && parcelAndConstructionData.buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildConstructions: prev?.buildConstructions?.length === 0 ? [...(request?.buildIntention?.buildConstructions ?? [])] : prev.buildConstructions,
            }));
        }

        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.form, request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
    
            if (!buildIntention?.buildingName?.trim()) {
                err = true;
            } 
            // else if (!buildIntention?.address?.city?.trim()) {
            //     err = true;
            // } 
            else if (!buildIntention?.estimatedCompletionDate?.trim() || isDateError.expectedEndDate) {
                err = true;
            } else if (!request?.form?.earlyUsePeriod?.trim()) {
                err = true;
            } else if (!request?.form?.requestJustification?.trim()) {
                err = true;
            } else if (buildIntention?.selfHelp && !request?.form?.draftConditions?.trim()) {
                err = true;
            } else if (buildIntention?.carriedWithDeviations && !buildIntention?.descriptionOfDeviations?.trim()) {
                err = true;
            }
    
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));
    
            return !err;
        }
    }));
    
    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 m-0 main-title'>{`${stepValue}. Stavba`}</h2>
            </Row>
            <Row className='row-wrapper'>
                <p className='p-0 section-title'>Základní údaje o prováděné stavbě</p>
            </Row>
            <Col md={6} className='mb-4'>
                <label className='p-0 povoleni-label' style={{color: (!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) ? '#C52A3A' : ''}}>
                    Název stavby
                    <input 
                        type='text'
                        required
                        {...(focused.nazevStavby && { focused: 'true' })}
                        defaultValue={buildIntention?.buildingName}
                        style={{border: error && !buildIntention?.buildingName?.trim() ? '#C52A3A solid 1px' : ''}}
                        onBlur={(e) => { 
                            setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, buildingName: e.target.value}}));
                            handleFocused('nazevStavby');
                        }}
                        className=' id-input2 bg-white mt-1'
                    />
                    {(!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Název stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>

            <Row className='border p-4 row-wrapper'>
                <h3 className='p-0 id-title'>Místo stavby</h3>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !buildIntention?.address?.city?.trim() || (focused.obec && !buildIntention?.address?.city?.trim()) ? '#C52A3A' : ''}} */>
                        Obec (nepovinné)
                            <input
                                type='text'
                                required
                                // {...(focused.obec && { focused: 'true' })}
                                defaultValue={buildIntention?.address?.city}
                                // style={{border: error && !buildIntention?.address?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention?.address, city: e.target.value}}}));
                                    // handleFocused('obec');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                            </Container>}
                            {
                                error
                                &&
                                !buildIntention?.address?.city?.trim()
                                &&
                                <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                                </Container>
                            } */}
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                        Část obce (nepovinné)
                            <input type='text' defaultValue={buildIntention?.address?.cityPart}
                                onBlur={(e) => {setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention?.address, cityPart: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
            </Row>

            <Row className='mb-4'>
                <Col md={6}>
                    <label className='povoleni-label'>
                    Číslo geometrického plánu potvrzeného katastrálním úřadem
                        <input 
                            type='text'
                            defaultValue={buildIntention?.confirmedGeometricPlan}
                            // style={{ border: error && !buildIntention?.verifiedDocumentationId?.trim() ? 'solid 1px #C52A3A' : '' }}
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, confirmedGeometricPlan: e.target.value } }));
                            }}
                            className='povoleni-input bg-white p-2 mt-1'
                        />
                    </label>
                </Col>
                <Col md={6} className='d-flex align-items-end'>
                    <p className='povoleni-help-text mt-3 mt-md-4 ps-md-3'>Pokud je stavba předmětem evidence v katastru nemovitostí nebo její výstavbou dochází k rozdělení pozemku</p>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={6}>
                    <label className='povoleni-label'>
                    Identifikátor záznamu, ve kterém byly zapsány změny týkající se obsahu digitální technické mapy kraje, nebo předány podklady pro jejich zápis
                        <input 
                            type='text'
                            defaultValue={buildIntention?.mapIdentifier}
                            // style={{ border: error && !buildIntention?.verifiedDocumentationId?.trim() ? 'solid 1px #C52A3A' : '' }}
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, mapIdentifier: e.target.value } }));
                            }}
                            className='povoleni-input bg-white p-2 mt-1'
                        />
                    </label>
                </Col>
                <Col md={6} className='d-flex align-items-end'>
                    <p className='povoleni-help-text mt-3 mt-md-4 ps-md-3'>Pokud jsou údaje o stavbě obsahem digitální technické mapy kraje</p>
                </Col>
            </Row>

            <Row className='mb-4'>
                <form className='d-flex flex-column p-0 form'>
                    <label className='id-checkbox-label'>
                        <input
                            type="checkbox"
                            className='id-checkbox-input'
                            checked={request?.buildIntention?.setOfBuildings}
                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} />
                            Stavba byla povolena jako součást souboru staveb
                    </label>
                </form>
            </Row>

            <Col md={6} className='row-wrapper'>
                <label className='p-0 povoleni-label' style={{color: (!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.datumDokonceni)) || isDateError.expectedEndDate ? '#C52A3A' : ''}}>
                    Předpokládaný termín dokončení stavby
                    <input 
                        type='date' 
                        className='id-input2'
                        required
                        style={{border: ((error && !buildIntention?.estimatedCompletionDate?.trim()) || isDateError.expectedEndDate) ? '#C52A3A solid 1px' : ''}}
                        value={buildIntention?.estimatedCompletionDate ?? ''}
                        {...(focused.datumDokonceni && { focused: 'true' })}
                        onChange={e => setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, estimatedCompletionDate: e.target.value } }))}
                        onBlur={(e) => { 
                            const validatedDate = dateValidation(e.target.value); 
                            validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, estimatedCompletionDate: '' } }));
                            setIsDateError(state => ({...state, expectedEndDate: validatedDate}));
                            handleFocused('datumDokonceni');
                        }}
                    />
                    {isDateError.expectedEndDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    {(!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.datumDokonceni)) && !isDateError.expectedEndDate &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Předpokládaný termín dokončení stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>

            <Row className='row-wrapper'>
                <StavbyTable
                    title={'Údaje o stavbě'}
                    progressElements={progressElements}
                    setProgressElements={setProgressElements}
                    urlPath={urlPath}
                    dataToAdd={parcelAndConstructionData.approvedConstructions}
                    approvedConstructions={true}
                    setIsExceeded={setIsExceeded}
                    setDataToAdd={setParcelAndConstructionData}
                />
                {isExceeded &&  <p className='d-flex align-items-start p-0 mt-3 stavba-info__text'> <img src={infoCircle} alt="infoCircle" className='me-2' />V jedné žádosti je možné zadat nejvýše 5 staveb.</p>}
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Nepodstatné odchylky</p>
                <Row className='d-flex pb-2'>
                    <Col md={6}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='deviations'
                                className='radio'
                                checked={buildIntention?.notCarriedWithDeviations}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, notCarriedWithDeviations: true, carriedWithDeviations: false }})); }}
                            />
                        Stavba nebyla provedena s nepodstatnými odchylkami od ověřené projektové dokumentace 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='deviations'
                                className='radio'
                                checked={buildIntention?.carriedWithDeviations ?? false}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention,  notCarriedWithDeviations: false, carriedWithDeviations: true } })); }}
                            />
                        Stavba byla provedena s nepodstatnými odchylkami od ověřené projektové dokumentace
                        </label>
                    </Col>                    
                    <Col md={6}>
                        <p className='mt-3 mt-md-0 building-change ps-md-3'>
                        U stavby prováděné dodavatelsky přiložte dohodu se stavebním podnikatelem, obsahující jeho souhlas, popřípadě sjednané podmínky předčasného užívání stavby. Dohoda je součástí žádosti.
                        </p>
                    </Col>
                </Row>
            </Row>

            {buildIntention?.carriedWithDeviations && 
                <>
                    <Row className='pt-2 pb-0 justification-container'>
                        <Col>
                            <span className='px-0 m-0 justification-text' style={{color: (!buildIntention?.descriptionOfDeviations?.trim() && (error || focused.descriptionOfDeviations)) ? '#C52A3A' : ''}}>Popis a zdůvodnění nepodstatných odchylek</span>
                        </Col>
                    </Row>
                    <Row className='py-2 mb-4'>
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count p-2 text-area--validation'
                                required
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    

                                {...(focused.descriptionOfDeviations && { focused: 'true' })}
                                style={{border: error && !buildIntention?.descriptionOfDeviations?.trim() ? '#C52A3A solid 1px' : ''}}
                                defaultValue={buildIntention?.descriptionOfDeviations}
                                onBlur={(e) => {
                                    setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, descriptionOfDeviations: e.target.value}}));
                                    handleFocused('descriptionOfDeviations');
                                }}
                            />
                            <CharCount 
                                value={buildIntention?.descriptionOfDeviations} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                        {(count[0].count >= count[0].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[0].limit === 10000 && count[0].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </Row>
                </>}

            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Způsob provádění</p>
                <Row className='d-flex pb-2'>
                    <Col md={6}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention?.selfHelp)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev, 
                                        buildIntention: { 
                                            ...prev.buildIntention, 
                                            selfHelp: true, 
                                            propertyDeveloper: false, 
                                            propertyDeveloperDetails: {
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                nationality: ''
                                            } 
                                        }
                                    })); }}
                            />
                            Svépomocí 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention?.propertyDeveloper)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev, 
                                        buildIntention: { 
                                            ...prev.buildIntention,  
                                            selfHelp: false, 
                                            propertyDeveloper: true, 
                                            propertyDeveloperDetails: {
                                                ...prev.buildIntention?.propertyDeveloperDetails, 
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                            } 
                                        } })); }}
                            />
                            Stavebním podnikatelem                    
                        </label>
                    </Col>                    
                    <Col md={6}>
                        <p className='mt-3 mt-md-0 building-change ps-md-3'>
                        U stavby prováděné dodavatelsky přiložte dohodu se stavebním podnikatelem, obsahující jeho souhlas, popřípadě sjednané podmínky předčasného užívání stavby. Dohoda je součástí žádosti.
                        </p>
                    </Col>
                </Row>
            </Row>

            {buildIntention?.selfHelp
                &&
                <>
                    <Row className='pt-2 pb-0 justification-container'>
                        <Col>
                            <span className='px-0 m-0 justification-text' style={{color: (!request?.form?.draftConditions?.trim() && (error || focused.draftConditions)) ? '#C52A3A' : ''}}>Návrh podmínek předčasného užívání stavby prováděné svépomocí</span>
                        </Col>
                    </Row>
                    <Row className='py-2 mb-4'>
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count p-2 text-area--validation'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}
                                required
                                {...(focused.draftConditions && { focused: 'true' })}
                                style={{border: error && !request?.form?.draftConditions?.trim() ? '#C52A3A solid 1px' : ''}}
                                defaultValue={request?.form?.draftConditions}
                                onBlur={(e) => { 
                                    setRequest(prev => ({ ...prev, form: {...prev.form, draftConditions: e.target.value}}));
                                    handleFocused('draftConditions');
                                }}
                            />
                            <CharCount 
                                value={request?.form?.draftConditions} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                            />
                        </Row>
                        {(count[1].count >= count[1].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[1].limit === 10000 && count[1].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </Row>
                </>}

            {buildIntention?.propertyDeveloper
                &&
            <Container className='py-2'>
                <p className='p-0 pb-4 section-title'>Stavební podnikatel</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isFOBusiness ?? true}
                                onChange={() => {
                                    setRequest(prev => ({
                                        ...prev, buildIntention: { 
                                            ...prev.buildIntention,
                                            propertyDeveloperDetails: {
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                isFOBusiness: true,
                                                isPO: false,
                                                id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                            }  
                                        }
                                    }));
                                }}
                            />
                            fyzická osoba podnikající
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isPO ?? false}
                                onChange={() => {
                                    setRequest(prev => ({ 
                                        ...prev, buildIntention: { 
                                            ...prev.buildIntention, 
                                            propertyDeveloperDetails: {
                                                ...PROPERTY_DEVELOPER_MODEL, 
                                                isFOBusiness: false,
                                                isPO: true,
                                                id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                            }  
                                        }
                                    }));
                                }}
                            />
                            právnická osoba
                        </label>
                    </Col>                     
                </Row>
            </Container>}

            {buildIntention?.propertyDeveloper && 
            <Container className='py-2' fluid>
                <Row className='mb-4'>
                    <p className='p-0 section-title'>Identifikační údaje stavebního podnikatele</p>
                </Row>
                <Row className='pb-4'>
                    <FindAndProcessIco
                        updateProp={'propertyDeveloperDetails'}
                        parentProp={'buildIntention'}
                        focusKey={'icoNum'}
                        handleFocused={handleFocused}
                        focused={focused} 
                        request={request} 
                        setRequest={setRequest}
                    />
                </Row>
                {buildIntention?.propertyDeveloperDetails?.isFOBusiness && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Jméno
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.names ?? ''}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, names: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Uveďte všechna jména
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Příjmení
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastName ?? ''}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastName: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Rodné příjmení
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastNameOrigin ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastNameOrigin: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Container fluid>
                            <Col md={6}>
                                <label className='label p-0'>
                                Státní příslušnost
                                    <select
                                        type='select'
                                        defaultValue={buildIntention?.propertyDeveloperDetails?.nationality ?? ''}
                                        onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, nationality: e.target.value}  }})); }}
                                        className='id-input2 dropdown-input'
                                        //ref={(refEl) => cleanUpFields.current['nationality'] = refEl}
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                        
                                        
                                    </select>
                                </label>
                            </Col>
                        </Container>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                            Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.appendix ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, appendix: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </>
                
                }
                {buildIntention?.propertyDeveloperDetails?.isPO && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                            Název nebo obchodní firma
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.poName  ?? ''}
                                    onBlur={(e) => { 
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, poName: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </>}

                {(buildIntention?.propertyDeveloperDetails?.isFOBusiness || buildIntention?.propertyDeveloperDetails?.isPO) && 
                <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>{buildIntention?.propertyDeveloperDetails?.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Obec
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.city ?? ''}
                                    onBlur={(e) => { 
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, city: e.target.value}}  }})); 
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Část obce
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.cityPart ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, cityPart: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Ulice
                                <input 
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.street ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, street: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Číslo popisné
                                <input
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.descNum ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, descNum: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Číslo orientační
                                <input 
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.orientNum ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, orientNum: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                PSČ
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.zip ?? ''}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, zip: e.target.value}}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </Row>}
            </Container>}
            
            <p className='p-0 pb-4 section-title'>Předčasné užívání stavby</p>
            <Col md={6}>
                <label className='label mb-2' style={{color: (!request?.form?.earlyUsePeriod?.trim() && (error || focused.predcasneUzivani)) ? '#C52A3A' : ''}}>
                Doba předčasného užívání stavby (počet měsíců)
                    <input 
                        type='text'
                        required
                        style={{border: error && !request?.form?.earlyUsePeriod?.trim() ? '#C52A3A solid 1px' : ''}}
                        defaultValue={request.form.earlyUsePeriod}
                        {...(focused.predcasneUzivani && { focused: 'true' })}
                        onBlur={(e) => {
                            setRequest(prev => ({...prev, form: {...prev.form, earlyUsePeriod: e.target.value}}));
                            handleFocused('predcasneUzivani');
                        }}
                        className='mt-1 id-input2'
                    />
                    {(!request?.form?.earlyUsePeriod?.trim() && (error || focused.predcasneUzivani)) &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Doba předčasného užívání stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>
            <Row className='pt-2 pb-0 justification-container mb-2'>
                <Col>
                    <label htmlFor='predcasneUzivaniDuvod' className='px-0 m-0 justification-text' style={{color: (!request?.form?.requestJustification?.trim() && (error || focused.predcasneUzivaniDuvod)) ? '#C52A3A' : ''}}>Odůvodnění žádosti o předčasné užívání stavby</label>
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='position-relative textarea-with-count-container'>
                    <textarea
                        className='textarea-with-count text-area--validation p-2'
                        onChange={(e) => { checkLimitAndInsertText(e.target, 2, count, setCount); }}
                        
                        id='predcasneUzivaniDuvod'
                        required
                        style={{border: error && !request?.form?.requestJustification?.trim() ? '#C52A3A solid 1px' : ''}}
                        defaultValue={request.form.requestJustification}
                        {...(focused.predcasneUzivaniDuvod && { focused: 'true' })}
                        onBlur={(e) => {
                            setRequest(prev => ({...prev, form: {...prev.form, requestJustification: e.target.value}}));
                            handleFocused('predcasneUzivaniDuvod');
                        }}
                    />
                    <CharCount 
                        value={request.form.requestJustification} setCount={setCount} propToUpdate={2} count={count[2].count} limit={count[2].limit}
                    />
                </Row>
                {(count[2].count >= count[2].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                {(count[2].limit === 10000 && count[2].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
            </Row>

        </Container>
    );
};

StavbaComponent12.propTypes = {
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
};

export default StavbaComponent12;

