import React from 'react';
import '../../css/zadostiDetail.css';
import '../../css/reusable.css';
import { Container, Row, Col/* , Image */ } from 'react-bootstrap';
import { GovBreadcrumbs } from '@gov-design-system-ce/react';
import { arrowRight/* , iconInfo */ } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ZadostiDetailHeaderSection = ({zadostiDetail}) => {

    return (
        <Container fluid className='zadosti-detail-header p-3 mt-3 p-md-0 d-flex flex-column align-items-center'>
            <Row className='breadcrumbs-section-detail base-width'>
                <Col className='d-flex justify-content-end align-items-start flex-column'>
                    <GovBreadcrumbs wcag-label="Aktuálně se necházíte na této úrovni">
                        <ul className='header__ul'>
                            <li className='d-flex align-items-center'><Link to="/">Úvod</Link><img src={arrowRight} style={{marginLeft: '10px', marginRight: '16px', height: '10px', width: '10px'}}/></li>
                            <li className='d-flex align-items-center'><Link to="/zadosti">Žádost </Link><img src={arrowRight} style={{marginLeft: '10px', marginRight: '16px', height: '10px', width: '10px'}}/></li>
                            <li className='d-flex align-items-center header__li-rizeni-id fw-bold'>{zadostiDetail?.detail?.processId}</li>
                        </ul>
                    </GovBreadcrumbs>
                </Col>
            </Row>
            <Row className='base-width pt-5'>
                <Col xs={12} md={6}>
                    <Row className='d-flex flex-column'>
                        <Col className='header__detail-id-big mb-2'>{zadostiDetail?.detail?.applicationName ?? ''}</Col>
                        <Col className='header__detail-typography mb-2'>{`PID: ${zadostiDetail?.detail?.applicationPid ?? ''}`}</Col>
                        <Col className='header__detail-typography-1 mb-2'>{`Záměr: ${zadostiDetail?.detail?.icsId ?? ''}`}</Col>
                        <Col className='header__detail-typography-1'>{zadostiDetail?.detail?.intentionName ?? ''}</Col>
                    </Row>                    
                </Col>            
                {/* {
                    zadostiDetail?.qr?.data
                    &&
                    <Col xs={12} md={6} className='d-flex flex-column mt-4 align-items-md-end mt-md-0'>
                        <div className='zadosti-barcode'>
                            <Image src={zadostiDetail.qr.data} className='zadosti-barcode-image' />
                            <Container className='d-flex zadosti-barcode-info'>
                                <Image src={iconInfo} className='zadosti-icon-info'/>
                                <p className='barcode-text'>Jak kód použít?</p>
                            </Container>
                        </div>
                    </Col>
                } */}
            </Row>
        </Container>
    );

};

ZadostiDetailHeaderSection.propTypes = {
    zadostiDetail: PropTypes.shape({
        detail: PropTypes.shape({
            applicationPid: PropTypes.string,
            applicationName: PropTypes.string,
            intentionName: PropTypes.string,
            projectId: PropTypes.string,
            processId: PropTypes.string,
            state: PropTypes.string,
            date: PropTypes.string,
            mode: PropTypes.string,
            icsId: PropTypes.string
        }),
        qr: PropTypes.shape({
            data: PropTypes.string,
        })
    }),
};

export default ZadostiDetailHeaderSection;