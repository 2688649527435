import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { useOnlyRequiredKeys, shouldNotSave } from '../../helperFunctions/helpers';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { errorIcon } from '../../assets/index.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent19 = ({ urlPath }) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        eventDate: false
    });
    const [focused, setFocused] = useState({
        eventDescription: false,
        eventDate: false
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            eventDescription: '',
            eventDate: '',
            terrainAdjustments: false,
            building: false,
        },
    });
    const form = request?.form;
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {  
                const loadedData = response?.data?.buildApplicationForms[0];
                const formStructure = request?.form;
                const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                if (loadedData) {
                    setRequest(state => ({
                        ...state,
                        form: {
                            ...state.form,
                            ...filteredDataToSave,
                        },
                    }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({ ...prev, [elementToFocus]: true }));
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            if (!form?.eventDate?.trim() || isDateError.eventDate) {
                err = true;
            } else if (!form?.eventDescription?.trim()) {
                err = true;
            } else if (!form.building && !form.terrainAdjustments) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {

                    return { ...stg, error: err ?? false };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>

            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Oznamuji obnovu</p>
                <form className='p-0'>
                    <Row>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.building ?? false}
                                onChange={(e) => {
                                    setRequest(state => ({ ...state, form: { ...state.form, building: e.target.checked } }));
                                }}
                            />
                            stavby
                        </label>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={form?.terrainAdjustments ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({...state, form: {...state.form, terrainAdjustments: e.target.checked }}));
                                    }}
                                />
                                terénní úpravy
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='ps-md-3 mt-3 mt-md-0 povoleni-help-text'>
                            Oznamovatel oznamuje stavebnímu úřadu obnovu stavby nebo terénní úpravy podle původního povolení a dokumentace, podle níž byla provedena.
                            </p>
                        </Col>
                    </Row>
                    {error && !form?.building && !form?.terrainAdjustments &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vybrání alespoň jednoho pole není možné pokračovat.
                            </Container>}
                </form>
            </Row>

            <Container fluid className='mb-4'>
                <p className='p-0 mb-4 title secondary'>Mimořádná událost</p>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='d-flex flex-column' style={{ color: (!form?.eventDescription?.trim() && (error || focused.eventDescription)) ? '#C52A3A' : 'black' }}>
                        Charakter mimořádné události
                            <input 
                                type='text' 
                                defaultValue={form?.eventDescription ?? ''}
                                style={{ border: (!form?.eventDescription?.trim() && (error || focused.eventDescription)) ? 'solid 1px #C52A3A' : '' }}
                                {...(focused.eventDescription && { focused: 'true' })}
                                onBlur={(e) => {
                                    setRequest(prev => ({ ...prev, form: { ...prev?.form, eventDescription: e.target.value } }));
                                    handleFocused('eventDescription');
                                }}
                                className='mt-1 id-input2'
                            />
                        </label>
                    </Col>
                    <Col md={6} className='d-flex'>
                        <p className='building-change ps-md-3 mb-md-3 mt-2 align-content-end'>
                        Např. povodeň, tornádo, sesuv půdy apod.
                        </p>
                    </Col>
                    {(!form?.eventDescription?.trim() && (error || focused.eventDescription)) &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Charakter mimořádné události&quot; není možné pokračovat.
                            </Container>}
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='mb-4 label p-0' style={{ color: (!form?.eventDate?.trim() && (error || focused.eventDate)) ? '#C52A3A' : 'black' }}>
                        K mimořádné události došlo dne
                            <input
                                type='date'
                                required
                                className='id-input2'
                                style={{ border: (!form?.eventDate?.trim() && (error || focused.eventDate)) ? 'solid 1px #C52A3A' : '' }}
                                {...(focused.eventDate && { focused: 'true' })}
                                value={form?.eventDate ?? ''}
                                onChange={e => setRequest(prev => ({ ...prev, form: { ...prev.form, eventDate: e.target.value } }))}
                                onBlur={(e) => {
                                    const validatedDate = dateValidation(e.target.value);
                                    validatedDate && setRequest(state => ({ ...state, form: { ...state?.form, eventDate: '' } }));
                                    setIsDateError(state => ({ ...state, eventDate: dateValidation(e.target.value) }));
                                    handleFocused('eventDate');
                                }}
                            />
                            {isDateError.eventDate && <Container className='mt-2' style={{ color: '#C52A3A', fontSize: '0.751rem' }}>
                                <img src={errorIcon} alt='error icon' style={{ marginRight: '.5rem', height: '1.1rem', width: '1.1rem' }} />
                                Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                            {(!form?.eventDate && (error || focused.eventDate)) && !isDateError.eventDate &&
                                <Container className='' style={{ color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem' }}>
                                    <img src={errorIcon} alt='error icon' style={{ marginRight: '.5rem', height: '1.1rem', width: '1.1rem' }} />
                                    Bez vyplnění pole &quot;K mimořádné události došlo dne&quot; není možné pokračovat.
                                </Container>}
                        </label>
                    </Col>
                    <Col md={6} className='d-flex'>
                        <p className='building-change ps-md-3 mb-md-3 mt-2 align-content-end'>
                        Oznámit obnovu stavby nebo terénní úpravy lze do 6 měsíců ode dne, kdy došlo ke zničení nebo poškození stavby nebo terénní úpravy.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

RozsahComponent19.propTypes = {
    urlPath: PropTypes.string.isRequired,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,


};

export default RozsahComponent19;