import React, { useState, useContext, useEffect, Fragment, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadatelComponent.css';
import PropTypes from 'prop-types';
import { FindAndProcessIco, FormInput } from '../index';
import { arrowDownBlue } from '../../assets';
import {
    DOCUMENT_PROCESSOR_BUSINESS, 
    DOCUMENT_PROCESSOR_PO,
    DOCUMENT_PROCESSOR_PERSON_AUTHORIZED,
    DOCUMENT_PROCESSOR_CONTACTS,
    DOCUMENT_PROCESSOR_CONTACTS_PO,
    DOCUMENT_PROCESSOR_LOCATION,
    ADDITION_PROCESSOR,
} from '../../constants/form18.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { shouldNotSave } from '../../helperFunctions/helpers';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { COUNTRIES, PERSON_MODEL } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const ZpracovatelComponent18 = ({ 
    urlPath,
    receivedToken,
}) => {
    const {intention, stepValue, id, validationRef, setStagesArr} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            documentProcessor: PERSON_MODEL,
            a21InputDoc: '',
            a22InputDoc: '',
            a23InputDoc: '',
            a24InputDoc: '',
            a25InputDoc: '',
            a26InputDoc: '',
            a27InputDoc: '',
            a28InputDoc: '',
            a29InputDoc: '',
        },
    });
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const isFOBusiness = request.accompanyingDocument.documentProcessor?.isFOBusiness;
    const isPO = request.accompanyingDocument.documentProcessor?.isPO;
    const [ focused, setFocused ] = useState({
        icoNum: false,
    });
    const zpracovatel = request.accompanyingDocument.documentProcessor;
    const address = request.accompanyingDocument.documentProcessor.address;

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.accompanyingDocument;
                    if (loadedData) {
                        setRequest(state => ({ ...state, accompanyingDocument: {
                            ...state.accompanyingDocument, 
                            a21InputDoc: loadedData.a21InputDoc ?? '',
                            a22InputDoc: loadedData.a22InputDoc ?? '',
                            a23InputDoc: loadedData.a23InputDoc ?? '',
                            a24InputDoc: loadedData.a24InputDoc ?? '',
                            a25InputDoc: loadedData.a25InputDoc ?? '',
                            a26InputDoc: loadedData.a26InputDoc ?? '',
                            a27InputDoc: loadedData.a27InputDoc ?? '',
                            a28InputDoc: loadedData.a28InputDoc ?? '',
                            a29InputDoc: loadedData.a29InputDoc ?? '',
                            documentProcessor: {...state.accompanyingDocument.documentProcessor,
                                ...(loadedData.documentProcessor || {...PERSON_MODEL, id: loadedData?.documentProcessor?.id ?? '', nationality: loadedData?.documentProcessor?.nationality ?? 'CZE'})}
                        } }));
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };


    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.accompanyingDocument]);

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (zpracovatel.isFOBusiness && (!zpracovatel.ico.trim() || !zpracovatel.names.trim() || !zpracovatel.lastName.trim())) {
                err = true;
            } else if (zpracovatel.isFOBusiness && (!address.city.trim() || !address.zip.trim())) {
                err = true;
            } else if (zpracovatel.isPO && (!zpracovatel.ico.trim() || !zpracovatel.poName.trim())) {
                err = true;
            } else if (zpracovatel.isPO && (!address.city.trim() || !address.zip.trim())) {
                err = true;
            } else if (zpracovatel.isPO && (!zpracovatel.names.trim() || !zpracovatel.lastName.trim() || !zpracovatel.nationality.trim() || !zpracovatel.mandate.trim() || !zpracovatel.mandateFrom.trim())) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            <Container fluid>
                <Row className='row-wrapper'>
                    <h2 className='p-0 main-title'>{`${stepValue}. Zpracovatel dokumentace`}</h2>
                </Row>

                <Row className='row-wrapper'>
                    <form className='d-flex flex-column p-0 form'>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input type='radio' name='id' value='businessman' className='radio' checked={isFOBusiness ?? true}
                                onChange={() => { setRequest(state => ({ ...state, 
                                    accompanyingDocument: { ...state.accompanyingDocument, 
                                        documentProcessor: { ...state.accompanyingDocument?.documentProcessor, ...PERSON_MODEL, isFOBusiness: true, isPO: false } } })); }} />
                                Fyzická osoba podnikající
                        </label>
                        <label className='d-flex align-items-center label'>
                            <input type='radio' name='id' value='more' className='radio' checked={isPO ?? false}
                                onChange={() => { setRequest(state => ({ ...state, 
                                    accompanyingDocument: { ...state.accompanyingDocument, 
                                        documentProcessor: { ...state.accompanyingDocument?.documentProcessor, ...PERSON_MODEL, isFOBusiness: false, isPO: true } } })); }} />
                                Právnická osoba
                        </label>
                    </form>
                </Row>

                {/* ZÁKLADNÍ ÚDAJE */}
                <Row className='row-wrapper'>
                    <h3 className='p-0 id-title'>Identifikační údaje zpracovatele</h3>
                    <form className='p-0'>
                        {
                            isFOBusiness &&
                                DOCUMENT_PROCESSOR_BUSINESS.map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco
                                                info={item.info}
                                                updateProp={'documentProcessor'}
                                                parentProp={'accompanyingDocument'}
                                                focusKey={'icoNum'}
                                                handleFocused={handleFocused}
                                                focused={focused}
                                                request={request}
                                                setRequest={setRequest}
                                            />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                        }
                        {
                            isPO &&
                                DOCUMENT_PROCESSOR_PO.map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco
                                                info={item.info}
                                                updateProp={'documentProcessor'}
                                                parentProp={'accompanyingDocument'}
                                                focusKey={'icoNum'}
                                                handleFocused={handleFocused}
                                                focused={focused}
                                                request={request}
                                                setRequest={setRequest}
                                            />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                        }
                        {!isPO && <label className='d-flex flex-column mb-4 label' style={{ marginTop: '1.5rem' }}>
                                Státní příslušnost (nepovinné)
                            <Row>
                                <Col xs={12} md={6}>
                                    <select type='select' value={request?.accompanyingDocument?.documentProcessor?.nationality ?? 'CZE'}
                                        style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                                        onChange={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, documentProcessor: { ...state.accompanyingDocument.documentProcessor, nationality: e.target.value } } })); }}
                                        className='id-input2'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </label>}
                        {
                            isFOBusiness &&
                                ADDITION_PROCESSOR.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                        }
                    </form>
                </Row>

                {/* TRVALÝ POBYT / MÍSTO PODNIKÁNÍ */}
                <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>{isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                    <form className='p-0'>
                        {
                            DOCUMENT_PROCESSOR_LOCATION.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>

                {/* POVĚŘENÁ OSOBA */}
                {isPO && <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                    <form className='p-0'>
                        {
                            DOCUMENT_PROCESSOR_PERSON_AUTHORIZED.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>}

                {/* KONTAKTNÍ ÚDAJE */}
                {!isPO && <Row className='row-wrapper'>
                    <form className='p-0'>
                        {
                            DOCUMENT_PROCESSOR_CONTACTS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>}

                {/* KONTAKTNÍ ÚDAJE PO */}
                {isPO && <Row className='row-wrapper'>
                    <form className='p-0'>
                        {
                            DOCUMENT_PROCESSOR_CONTACTS_PO.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>}

                <Row>
                    <h3 className='p-0 id-title'>Seznam vstupních podkladů</h3>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.1
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a21InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a21InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.2
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a22InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a22InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.3
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a23InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a23InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.4
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a24InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a24InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.5
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a25InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a25InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.6
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a26InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a26InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.7
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a27InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a27InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0 mb-4'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.8
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a28InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a28InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                    <label className='d-flex flex-column label p-0'>
                        <Row className=''>
                            <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        A.2.9
                                <input
                                    type={'text'}
                                    className='id-input2'
                                    defaultValue={request.accompanyingDocument.a29InputDoc}
                                    onBlur={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, a29InputDoc: e.target.value } })); }}
                                />
                            </Col>
                        </Row>
                    </label>
                </Row>
            </Container>
        </>
    );
};

ZpracovatelComponent18.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    receivedToken: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    foundApplicantIndex: PropTypes.number,
    setFoundApplicantIndex: PropTypes.func,
    stage: PropTypes.number,
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
};

export default ZpracovatelComponent18;