/* eslint-disable no-undef */
const authConfig = {
    clientId: 'oze-web-client',
    authorizationEndpoint: process.env.REACT_APP_AUTHORIZE_ENDPOINT || 'http://localhost:9001/oauth2/authorize',
    tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || 'http://localhost:9001/oauth2/token',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/',
    onRefreshTokenExpire: (event) => {
        console.log('Tokens have expired. Going re-login...');
        event.login();
    },
    logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || 'http://localhost:9001/singleLogout',
    logoutRedirect: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/',
    // Example to redirect back to original path after login has completed
    preLogin: () => {
        console.log('OIDC.preLogin...', window.location.pathname);
        localStorage.setItem('preLoginPath', window.location.pathname);
    },
    postLogin: () => {
        const goToUrl = localStorage.getItem('preLoginPath') || '';
        console.log('OIDC.postLogin...', goToUrl);
        window.location.replace(goToUrl);
    },
    decodeToken: true,
    scope: 'openid',
    autoLogin: false,
};

export default authConfig;
