import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PLNA_MOC_FOURTH, POWER_OF_ATTORNEY_FILE_MODEL } from '../../constants/sharedConstants';
import { Col } from 'react-bootstrap';
import { errorIcon } from '../../assets';
import { FileUpload, UploadedAttachments } from '../index';
import { Link } from 'react-router-dom';
import FormContext from '../../formContexts/FormContext';

const PowerOfAttorneyInputRepre = ({
    form,
    setRequest,
    setZadatelAttachments,
    handleFocused,
    powerOfAttorneyError,
    handleFileInput,
    zadatelAttachments,
    progressElements,
    setProgressElements,
    focused,
    pravnickaOsoba,
    zakladnyDoklad,
}) => {
    const {stagesArr, stepValue} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;

    return (
        <>
            <h3 className='p-0 mb-2 id-title'>{ zakladnyDoklad ? 'Plná moc k zastupování': (pravnickaOsoba ? 'Doklad o oprávnění jednat jménem pravnické osoby' : 'Doklad o' +
                ' oprávnění jednat jménem fyzické osoby podnikající')}</h3>
            <form className='px-0 py-3'>
                <label className='id-checkbox-label'>
                    <input type='checkbox' className='id-checkbox-input' checked={Boolean(form.attorney?.powerOfRepresentativeExists)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                // edge case: if user adds attachment and changes mind
                                setRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfRepresentativeExists: e.target.checked, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
                                setZadatelAttachments((prev) => ({
                                    ...prev,
                                    [PLNA_MOC_FOURTH]: []
                                }));
                            } else if (!e.target.checked) {
                                setRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfRepresentativeExists: e.target.checked, idPowerOfRepresentativeExisting: '' }}}));
                            }
                        }} 
                    />
                                plná moc (popř. jiný doklad) již byla vložena do Portálu stavebníka (např. i pro více řízení)
                </label>
                {form.attorney?.powerOfRepresentativeExists
                                &&
                            <Col md={6}>
                                <label className='label py-3' style={{ color: (!form?.attorney?.idPowerOfRepresentativeExisting?.trim() && (error || focused.idPowerOfRepresentativeExisting)) ? '#C52A3A' : 'black' }}>
                                ID plné moci (popř. jiného dokladu): 
                                    <input 
                                        type='text'
                                        required
                                        className='id-input2'
                                        style={{ border: error && !form?.attorney?.idPowerOfRepresentativeExisting?.trim() && 'solid 1px #C52A3A' }}
                                        defaultValue={form.attorney?.idPowerOfRepresentativeExisting}
                                        onBlur={(e) => {
                                            setRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, idPowerOfRepresentativeExisting: e.target.value}}}));
                                            handleFocused('idPowerOfAttorneyExisting');
                                        }} />
                                    {(error && powerOfAttorneyError) &&
                                 <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 3 }} className='mt-2'>
                                     <p className='d-flex align-items-center p-0 warning-text'>
                                         <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                     Bez vyplnění pole &quot;ID plné moci&quot; není možné pokračovat.
                                     </p>
                                 </Col>}
                                </label>
                            </Col>}
            </form>
            {!form.attorney?.powerOfRepresentativeExists &&
                        <>
                            {zakladnyDoklad &&
                            <p className='p-0 mb-0 sub-title'>Nahrajte plnou moc na základě které můžete zastupovat Žadatele. Můžete využít <Link to="/doc/PLNÁ MOC_DSR (002).docx" target="_blank" download='Plná moc k zastupování'>náš vzor</Link>.</p>}
                            {!zakladnyDoklad &&
                                <p className='p-0 mb-0 sub-title'>{pravnickaOsoba? 'Je nutné doložit příslušný doklad o oprávnění v případě, že oprávnení jednat jménem právnické osoby není možné ověřit z veřejných rejstříků.':'Je nutné doložit příslušný doklad o oprávnění v případě, že oprávnení jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků.'}</p>}
                            <UploadedAttachments
                                category={PLNA_MOC_FOURTH}
                                attachments={zadatelAttachments[PLNA_MOC_FOURTH]}
                                setUploads={setZadatelAttachments}
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}        
                                setAttorneyRequest={setRequest}
                            />
                            <FileUpload handleFileInput={handleFileInput} category={PLNA_MOC_FOURTH} />
                            {(error && powerOfAttorneyError) 
                                 && 
                                 <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                                     <p className='d-flex align-items-center p-0 warning-text'>
                                         <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                     Bez nahrané plné moci není možné pokračovat
                                     </p>
                                 </Col>
                            }
                        </>}
        </>
    );
};

PowerOfAttorneyInputRepre.propTypes = {
    form: PropTypes.shape({
        attorney: PropTypes.shape({
            powerOfRepresentativeExists: PropTypes.bool,
            idPowerOfRepresentativeExisting: PropTypes.string,
        }),
    }),
    setRequest: PropTypes.func,
    setZadatelAttachments: PropTypes.func,
    handleFocused: PropTypes.func,
    powerOfAttorneyError: PropTypes.bool,
    handleFileInput: PropTypes.func,
    zadatelAttachments: PropTypes.object,
    progressElements: PropTypes.array,
    setProgressElements: PropTypes.func,
    focused: PropTypes.shape({
        idPowerOfRepresentativeExisting: PropTypes.bool,
        nationality: PropTypes.bool,
        nationality2: PropTypes.bool,
    }),
    pravnickaOsoba: PropTypes.bool,
    zakladnyDoklad: PropTypes.bool,
};

export default PowerOfAttorneyInputRepre;
