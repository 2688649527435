import React from 'react';
import '../../css/newRequestCard.css';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NewRequestCardHeader = ({ item }) => {
    const [ isLoaded, setIsLoaded ] = React.useState(false);

    const imageLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <Container fluid className='d-flex flex-column align-items-center new-request-card-header'>
            <Row className='d-flex flex-column justify-content-between category' style={{minHeight: !isLoaded ? '200px' : ''}}>
                <img src={item.img} className='new-request-card-image' style={{display: isLoaded ? 'block' : 'none', height: '200px'}} onLoad={imageLoaded}/>
                <p className='d-flex justify-content-center align-items-center p-0 category-text w-100'>{item.category}</p>
                <p className='p-0 new-request-card-header-title'>{item.title}</p>
                { !isLoaded && <div className='lazy-loading'></div> } 
            </Row>
            {/* <GovCard> */}
            <Row className='d-flex flex-column justify-content-between p-4 card-content'>
                <Row className='d-none d-md-block'>
                    <Row>
                        <Col xs={12}>
                            <p className={!isLoaded ? 'card-info--lazy-loading lazy-loading' : 'new-request-card-header-more-info' }>{item?.subtitle}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='list-items-container'>
                                {item.info.map((i, index) => {
                                    return (
                                        <p key={item.title + item.id + '-' + index} className={ isLoaded ? 'card-info' : 'card-info--lazy-loading lazy-loading' }>{i}</p>
                                    );
                                }
                                )}
                            </div>
                        </Col>
                    </Row>
                    {item?.subtitle1
                        &&
                        <Row className='mt-2'>
                            <Col xs={12}>
                                <p className={!isLoaded ? 'card-info--lazy-loading lazy-loading' : 'new-request-card-header-more-info' }>{item?.subtitle1}</p>
                            </Col>
                        </Row>
                    }
                    {item?.info1 && <Row>
                        <Col xs={12}>
                            <div className='list-items-container'>
                                {item.info1.map((i, index) => {
                                    return (
                                        <div key={item.title + item.id + '-' + index} className={ isLoaded ? 'card-info' : 'card-info--lazy-loading lazy-loading'}>{i}</div>
                                    );
                                }
                                )}
                            </div>
                        </Col>
                    </Row>}
                    
                </Row>
                <Row className='mt-md-3'>
                    <Col xs={12} className='d-flex align-items-center justify-content-between gap-3'>
                        {/* <button className='button-more-info'>
                                Více informací
                            </button> */}
                        {
                            isLoaded
                                ?
                                item?.link === '/zadost/form03'
                                    ?
                                    <Link to='https://dmvs.cuzk.gov.cz/portal/uzemi-spravcu-dti' className='button-fill-in-container' target='_blank'>
                                        <button className='button-fill-in' style={{backgroundColor: 'transparent', color: '#2362A2' }}>
                                            Portál DMVS
                                        </button>
                                    </Link> 
                                    :
                                    <Link to={item.link} className='button-fill-in-container'>
                                        <button className='button-fill-in'>
                                                Vyplnit
                                        </button>
                                    </Link>
                                :
                                <Link className='button-fill-in-container'>
                                    <button className='card-info--lazy-loading lazy-loading button-fill-in ' style={{backgroundColor: 'transparent', border: 'transparent', height: '39px' }}>
                                        Portál DMVS
                                    </button>
                                </Link> 
                        }
                            
                    </Col>
                </Row>
            </Row>
            {/* </GovCard> */}
        </Container>
    );
};

NewRequestCardHeader.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        info: PropTypes.array,
        subtitle1: PropTypes.string,
        info1: PropTypes.array,
        category: PropTypes.string,
        link: PropTypes.string,
        id: PropTypes.number,
        img: PropTypes.string,
    }).isRequired
};

export default NewRequestCardHeader;