import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, ZamerSelectionControls } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form01.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, checkLimitAndInsertText, hasZamerError } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { infoCircle, warningUnfilled } from '../../assets';
import { v4 as uuidv4 } from 'uuid';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    descriptionOfIntention: '',
    intentionConnectedToPublicInfrastructure: '',
    intentionImpact: '',
};

const ZamerComponent01 = ({
    progressElements,
    setProgressElements,
    urlPath
}) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
        [1]: {count: 0, limit: 10000},
        [2]: {count: 0, limit: 1000},
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const buildIntention = request?.buildIntention;
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);

    const [ focused, setFocused ] = useState({
        descriptionOfIntention: false,
        intentionConnectedToPublicInfrastructure: false,
        intentionImpact: false,
    });

    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;  
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });
    const [moreInfo, setMoreInfo] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const source = axios.CancelToken.source();

        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                // console.log('this is req: ', request);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { id: formData.id } } : {}) }));
                        
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value, 
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }

                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }
        
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (!buildIntention?.descriptionOfIntention?.trim()
                || !buildIntention?.intentionConnectedToPublicInfrastructure?.trim()
                || !buildIntention?.intentionImpact?.trim())
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const hasError1 = (!buildIntention?.descriptionOfIntention?.trim() && (error || focused.descriptionOfIntention));
    const hasError2 = (!buildIntention?.intentionConnectedToPublicInfrastructure?.trim() && (error || focused.intentionConnectedToPublicInfrastructure));
    const hasError3 = (!buildIntention?.intentionImpact?.trim() && (error || focused.intentionImpact));
    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />
                    
                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo záměru</p>
                        <form className='p-0'>
                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    <p className='section-title p-0 mb-4'>Popis záměru</p>
                    <label className='d-flex flex-column p-0 mb-3 textarea-input' style={{color: hasError1 ? '#C52A3A' : ''}}>
                        Druh, účel a popis požadovaného záměru, jeho základní rozměry a kapacita (např. plošné a výškové uspořádání, půdorysná velikost a výška stavby, její tvar, vzhled), umístění záměru (vzdálenost od hranic pozemků), úpravy nezastavěných ploch, technické provedení záměru apod.:                        
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2 text-area--validation'
                                required
                                style={{border: hasError1 ? 'solid #C52A3A 1px' : ''}}
                                rows="5"
                                defaultValue={buildIntention.descriptionOfIntention ?? ''}
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                onBlur={(e) => {
                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, descriptionOfIntention: e.target.value } }));
                                    handleFocused('descriptionOfIntention');
                                }}
                            />
                            <CharCount 
                                value={buildIntention.descriptionOfIntention} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                        {(count[0].count >= count[0].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[0].limit === 10000 && count[0].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </label>
                    <label className='d-flex flex-column p-0 mb-3 textarea-input' style={{color: hasError2 ? '#C52A3A' : ''}}>
                        Napojení záměru na veřejnou dopravní a technickou infrastrukturu, případné nároky na vybudování nové veřejné dopravní a technické infrastruktury:
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2'
                                required
                                style={{border: hasError2 ? 'solid #C52A3A 1px' : ''}} 
                                rows="5"
                                defaultValue={buildIntention.intentionConnectedToPublicInfrastructure ?? ''}
                                onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}    
                                onBlur={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, intentionConnectedToPublicInfrastructure: e.target.value } }));
                                    handleFocused('intentionConnectedToPublicInfrastructure');
                                }}
                            />
                            <CharCount 
                                value={buildIntention.intentionConnectedToPublicInfrastructure} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                            />
                        </Row>
                        {(count[1].count >= count[1].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[1].limit === 10000 && count[1].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </label>
                    <label className='d-flex flex-column p-0 mb-5 textarea-input' style={{color: hasError3 ? '#C52A3A' : ''}}>
                        Předpokládaný vliv záměru na území a chráněné veřejné zájmy podle zvláštních právních předpisů:
                        <p className='vyjimka-input-text-link p-0 pb-2' onClick={() => setMoreInfo(!moreInfo)}>{moreInfo ? 'zabalit' : 'více informací'}</p>
                        {moreInfo &&
                        <p className='help-text my-2'>
                            Například zákon č. 254/2001 Sb., vodní zákon, zákon č. 114/1992 Sb., o ochraně přírody a krajiny, zákon č. 201/2012 Sb., o ochraně ovzduší, zákon č. 334/1992 Sb., o ochraně zemědělského půdního fondu, zákon č. 20/1987 Sb., o státní památkové péči, zákon č. 289/1995 Sb., lesní zákon, zákon č. 133/1985 Sb., o požární ochraně, zákon č. 13/1997 Sb., o pozemních komunikacích, zákon č. 44/1988 Sb., horní zákon, zákon č. 164/2001 Sb., lázeňský zákon, zákon č. 62/1988 Sb., o geologických pracích, zákon č. 258/2000 Sb., o ochraně veřejného zdraví a o změně některých souvisejících zákonů, zákon č. 406/2000 Sb., o hospodaření energií, zákon č. 148/2023 Sb., o jednotném environmentálním stanovisku
                        </p>}
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2'
                                style={{border: hasError3 ? 'solid #C52A3A 1px' : ''}}
                                rows="5"
                                defaultValue={buildIntention.intentionImpact ?? ''}
                                onChange={(e) => { checkLimitAndInsertText(e.target, 2, count, setCount); }}
                                onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, intentionImpact: e.target.value } }));
                                    handleFocused('intentionImpact');
                                }}
                            />
                            <CharCount 
                                value={buildIntention.intentionImpact} setCount={setCount} propToUpdate={2} count={count[2].count} limit={count[2].limit}
                            />
                        </Row>  
                        {(count[2].count >= count[2].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[2].limit === 10000 && count[2].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}         
                    </label>

                    <PozemkyTable
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'na kterých se má záměr uskutečnit'}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                </Container>}
        </>
    );
};

ZamerComponent01.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent01;