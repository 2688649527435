import React from 'react';
import '../css/homePage.css';
import { Container } from 'react-bootstrap';
import { HomePageHeaderSection, NewestEventsSection, BottomSectionPrimary, HomePageMainSection } from '../components';

const HomePage = () => {

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HomePageHeaderSection />
            {/* <ZamerySection /> */}
            <NewestEventsSection />
            <HomePageMainSection />
            <BottomSectionPrimary />
        </Container>
    );
};

export default HomePage;