import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { ZameryBlockDisplay, ZameryTableDisplay, Filter, RecordNotFound } from '../index.js';
import '../../css/zamery.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import { GovTabs, GovTabsItem } from '@gov-design-system-ce/react';
import { fetchZamery } from '../../apiCalls/componentsApiCalls.js';
import { getFilterBodyBySections, intentionsSort } from '../../helperFunctions/helpers.js';
import { ZAMERY } from '../../constants/sharedConstants.js';

const ZameryMainSection = ({ isActive, setIsActive }) => {
    const [list, setList] = useState([]);
    const { token } = useContext(AuthContext);
    const [ fetching, setFetching ] = useState(true);
    const [postBody, setPostBody] = useState(getFilterBodyBySections(ZAMERY));

    const getData = useCallback(async (signal) => {
        try {
            const response = await fetchZamery(postBody, token, signal);
            if ((200 <= response?.status) && (response?.status < 300)) {
                const sortedList = [...response.data.data].sort(intentionsSort);
                setList(sortedList);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
    }, [postBody]);
    
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getData(signal);

        return () => { 
            controller.abort();
        };
    }, [getData]);

    return (
        <Container className='px-2 py-4 px-md-5 px-xl-0 zamery-main-container'>
            <GovTabs className='remove-border' type="text" wcag-label="Basic information about the gov.cz design system">
                <GovTabsItem label="Vytvořené">
                    <Container fluid>
                        <Row>
                            <Filter
                                isWithFolding={true}
                                setPostBody={setPostBody}
                                postBody={postBody} 
                                isActive={isActive}
                                setIsActive={setIsActive}
                                page={ZAMERY}
                            />
                        </Row>
                        {fetching ? ( // If there is null, show a loading spinner.
                            <Container fluid className='px-2 py-4 px-md-5 px-xl-0 zamery-main-container'>
                                <span>Načítám záměry...</span>
                            </Container>
                        ) : !fetching && (list.length === 0 ? (
                            <RecordNotFound/>
                        ) :
                            isActive === 'block' ?
                                <Container className='zamery-main-container'>
                                    <Row>
                                        {list.map((item, idx) => {
                                            return (
                                                <Col key={item.id || idx} xs={12} md={6} lg={4} className='pe-2 mb-3'>
                                                    {<ZameryBlockDisplay item={item} key={item.id} />}
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Container>
                                :
                                <Container className='overflow-x-scroll overflow-x-md-hidden' style={{ border: 'solid #DDDDDD .5px' }}>
                                    <ZameryTableDisplay items={list} setPostBody={setPostBody} postBody={postBody} />
                                </Container>)
                        }
                    </Container>
                </GovTabsItem>
                {/* <GovTabsItem label={`Rozpracované (${list.length})`}>
                    <Container fluid>
                        <Row>
                            {fetching ? ( // If there is null, show a loading spinner.}
                                <Container fluid className='px-2 py-4 px-md-5 px-xl-0 zamery-main-container'>
                                    <span>Načítám záměry...</span>
                                </Container>
                            ) : !fetching && (list.length === 0 ? (
                                <RecordNotFound/>
                            ) :
                                <Container className='zamery-main-container'>
                                    <Row className='pt-3'>
                                        {list.map((zamery, index) => {
                                            return (
                                                <Col key={zamery.id} xs={12} md={6} xl={4} className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'>
                                                    {(index < 4) && <ZameryBlockDisplayDeveloped item={zamery} key={zamery.id} />}
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Container>)
                            }
                        </Row>
                    </Container>
                </GovTabsItem> */}
            </GovTabs>
        </Container>
    );
};

ZameryMainSection.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default ZameryMainSection;