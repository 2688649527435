import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../formContexts/FormContext';
import { errorIcon, warningYellow } from '../assets';
import useFirstRender from './CustomHooks/useFirstRender';
import usePrevious from './CustomHooks/usePrevious';

const FindAndProcessIco = ({info, parentProp = '', updateProp, focusKey, request, setRequest, handleFocused, focused, thirdPerson}) => {
    const icoRef = useRef(null);
    const { token } = useContext(AuthContext);
    const { stepValue, stagesArr } = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const warning = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.warning ?? false;
    const icoValue = icoRef?.current?.value ?? null;
    const icoPattern = /^[0-9]{8}$/;
    const dynamicParentKey = parentProp || 'form';
    const hasError = (error || focused?.[focusKey]) && request[dynamicParentKey]?.[updateProp]?.ico?.trim() === '';
    const hasWarning = !hasError && (warning || focused?.[focusKey]) && !icoPattern.test(icoValue?.trim()?.replace(/\s+/g, ''));
    const [isReading, setIsReading] = useState(false);
    const firstRender = useFirstRender();
    const previousIcoVal = usePrevious(request[dynamicParentKey]?.[updateProp]?.ico);    
    
    const handleSearchByIco = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (icoValue === null || icoValue?.trim() === '') {
            return;
        }
        setIsReading(true);

        try {
            const source = axios.CancelToken.source();
            const response = await axios.get(
                urlJoin(apiConfig.getAndQueryBaseUrl, 'persons/find-by-ico'),    
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                    params: {
                        ico: !thirdPerson ? icoValue?.trim() : request?.ico?.trim()
                    }
                }); 
            if (200 <= response.status < 300) {
                const obj = response.data;

                setRequest(prev => ({...prev, 
                    ...(!thirdPerson ? {[dynamicParentKey]: {...prev?.[dynamicParentKey],
                        [updateProp]: {
                            ...prev?.[dynamicParentKey]?.[updateProp],
                            phone: obj.phone ?? '',
                            email: obj.email ?? '',
                            idDataBox: obj.idDataBox ?? '',
                            // ico: obj.ico ?? icoValue?.trim() ?? '',
                            ...(prev?.[dynamicParentKey]?.[updateProp]?.isFOBusiness ?
                                {
                                // appendix: obj.appendix ?? '',
                                    names: obj.names ?? '',
                                    lastName: obj.lastName ?? '',
                                    lastNameOrigin: obj.lastNameOrigin ?? '',
                                    dateOfBirth: obj.dateOfBirth ?? '',
                                    nationality: obj.nationality ?? 'CZE', 
                                } : {}
                            ),
                            ...(prev?.[dynamicParentKey]?.[updateProp]?.isPO ?
                                {
                                    poName: obj.poName ?? '',
                                } : {}
                            ),
                            address: {
                                ...prev?.[dynamicParentKey]?.[updateProp]?.address,
                                city: obj?.address?.city ?? '',
                                cityPart: obj?.address?.cityPart ?? '',
                                street: obj?.address?.street ?? '',
                                descNum: obj?.address?.descNum === 'null' ? '' : obj?.address?.descNum ?? '',
                                orientNum: obj?.address?.orientNum === 'null' ? '' : obj?.address?.orientNum ?? '',
                                zip: obj?.address?.zip ?? ''
                            },
                        // shippingSameAsPr: obj.shippingSameAsPr ?? false,
                        // shippingAddress: obj.shippingAddress ?? false,
                        }
                    }} : {
                        phone: obj.phone ?? '',
                        email: obj.email ?? '',
                        idDataBox: obj.idDataBox ?? '',
                        ico: obj.ico ?? prev?.ico?.trim() ?? '',
                        ...(prev?.isFOBusiness ?
                            {
                            // appendix: obj.appendix ?? '',
                                names: obj.names ?? '',
                                lastName: obj.lastName ?? '',
                                lastNameOrigin: obj.lastNameOrigin ?? '',
                                dateOfBirth: obj.dateOfBirth ?? '',
                                nationality: obj.nationality ?? 'CZE', 
                            } : {}
                        ),
                        ...(prev?.isPO ?
                            {
                                poName: obj.poName ?? '',
                            } : {}
                        ),
                        address: {
                            ...prev?.address,
                            city: obj?.address?.city ?? '',
                            cityPart: obj?.address?.cityPart ?? '',
                            street: obj?.address?.street ?? '',
                            descNum: obj?.address?.descNum === 'null' ? '' : obj?.address?.descNum ?? '',
                            orientNum: obj?.address?.orientNum === 'null' ? '' : obj?.address?.orientNum ?? '',
                            zip: obj?.address?.zip ?? ''
                        },
                    // shippingSameAsPr: obj.shippingSameAsPr ?? false,
                    // shippingAddress: obj.shippingAddress ?? false,
                    })
                }
                ));    
            }
        } catch (error) {
            console.log(error);
            // const obj = {
            //     names: 'Efe',
            //     lastName: 'Kiziltas',
            //     email: 'test@test.com',
            //     idDataBox: '123123123',
            //     address: {
            //         city: 'Praha',
            //         zip: '13000',
            //         cityPart: 'zizkov'
            //     },
            //     nationality: 'CZE',
            //     poName: 'poName123'
            // };

            // setRequest(prev => ({...prev, 
            //     ...(!thirdPerson ? {[dynamicParentKey]: {...prev?.[dynamicParentKey],
            //         [updateProp]: {
            //             ...prev?.[dynamicParentKey]?.[updateProp],
            //             phone: obj.phone ?? '',
            //             email: obj.email ?? '',
            //             idDataBox: obj.idDataBox ?? '',
            //             // ico: obj.ico ?? icoValue?.trim() ?? '',
            //             ...(prev?.[dynamicParentKey]?.[updateProp]?.isFOBusiness ?
            //                 {
            //                 // appendix: obj.appendix ?? '',
            //                     names: obj.names ?? '',
            //                     lastName: obj.lastName ?? '',
            //                     lastNameOrigin: obj.lastNameOrigin ?? '',
            //                     dateOfBirth: obj.dateOfBirth ?? '',
            //                     nationality: obj.nationality ?? 'CZE', 
            //                 } : {}
            //             ),
            //             ...(prev?.[dynamicParentKey]?.[updateProp]?.isPO ?
            //                 {
            //                     poName: obj.poName ?? '',
            //                 } : {}
            //             ),
            //             address: {
            //                 ...prev?.[dynamicParentKey]?.[updateProp]?.address,
            //                 city: obj.address.city ?? '',
            //                 cityPart: obj.address.cityPart ?? '',
            //                 street: obj.address.street ?? '',
            //                 descNum: obj.address.descNum ?? '',
            //                 orientNum: obj.address.orientNum ?? '',
            //                 zip: obj.address.zip ?? ''
            //             },
            //         // shippingSameAsPr: obj.shippingSameAsPr ?? false,
            //         // shippingAddress: obj.shippingAddress ?? false,
            //         }
            //     }} : {
            //         phone: obj.phone ?? '',
            //         email: obj.email ?? '',
            //         idDataBox: obj.idDataBox ?? '',
            //         ico: obj.ico ?? prev?.ico?.trim() ?? '',
            //         ...(prev?.isFOBusiness ?
            //             {
            //             // appendix: obj.appendix ?? '',
            //                 names: obj.names ?? '',
            //                 lastName: obj.lastName ?? '',
            //                 lastNameOrigin: obj.lastNameOrigin ?? '',
            //                 dateOfBirth: obj.dateOfBirth ?? '',
            //                 nationality: obj.nationality ?? 'CZE', 
            //             } : {}
            //         ),
            //         ...(prev?.isPO ?
            //             {
            //                 poName: obj.poName ?? '',
            //             } : {}
            //         ),
            //         address: {
            //             ...prev?.address,
            //             city: obj.address.city ?? '',
            //             cityPart: obj.address.cityPart ?? '',
            //             street: obj.address.street ?? '',
            //             descNum: obj.address.descNum ?? '',
            //             orientNum: obj.address.orientNum ?? '',
            //             zip: obj.address.zip ?? ''
            //         },
            //     // shippingSameAsPr: obj.shippingSameAsPr ?? false,
            //     // shippingAddress: obj.shippingAddress ?? false,
            //     })
            // }
            // )); 
        } finally {
            setIsReading(false);
        }
    };

    useEffect(() => {
        if (firstRender || !previousIcoVal) {
            return;
        }
     
        if (typeof request[dynamicParentKey]?.[updateProp]?.ico === 'string' && request[dynamicParentKey]?.[updateProp]?.ico === '') {
            icoRef.current.value = '';
        }

    }, [request[dynamicParentKey]?.[updateProp]?.ico]);

    return (
        <Container>
            <label className='d-flex flex-column label' style={{ marginTop: '1.5rem', color: hasError  ? '#C52A3A' : '' }}>
                IČO
                <Container className='d-flex align-items-center ico-btn-container'>
                    <input
                        ref={icoRef}
                        {...(!thirdPerson
                            ? { defaultValue: request[dynamicParentKey]?.[updateProp]?.ico }
                            : { value: request?.ico })}
                        className="id-input2 with-ico"
                        type="text"
                        name="ico"
                        id="ico"
                        style={{border: hasError ? 'solid #C52A3A 1px' : hasWarning ? 'solid #C78300 1px' : ''}}
                        {...(!thirdPerson
                            ? {
                                onBlur: (e) => {
                                    setRequest((prev) => ({
                                        ...prev,
                                        [dynamicParentKey]: {
                                            ...prev[dynamicParentKey],
                                            [updateProp]: {
                                                ...prev[dynamicParentKey]?.[updateProp],
                                                ico: e.target.value,
                                            },
                                        },
                                    }));
                                    if (handleFocused) handleFocused(focusKey);
                                },
                            }
                            : {
                                onChange: (e) => {
                                    setRequest((prev) => ({
                                        ...prev,
                                        ico: e.target.value,
                                    }));
                                },
                                onBlur: () => {
                                    if (handleFocused) handleFocused(focusKey);
                                }
                            })}
                    />
                    <button className='ico-btn' onClick={handleSearchByIco} disabled={isReading}>{!isReading ? 'Načíst z rejstříku' : 'Načítám...'}</button>
                </Container>
                {info && <span className='input-help-text mt-1'>{info}</span>}
            </label>
            {hasError && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                Bez vyplnění pole “IČO” není možné pokračovat.
                </p>
            </Col>}
            {hasWarning &&
            <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                Chyba formátu &quot;IČO&quot; může obsahovat pouze osm číslic.
            </Container>}
        </Container>
    );
};

FindAndProcessIco.propTypes = {
    info: PropTypes.string,
    updateProp: PropTypes.string,
    parentProp: PropTypes.string,
    focusKey: PropTypes.string,
    setRequest: PropTypes.func,
    handleFocused: PropTypes.func,
    request: PropTypes.object,
    focused: PropTypes.any,
    thirdPerson: PropTypes.bool,
};

export default FindAndProcessIco;