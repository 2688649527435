import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { findChosenIntention, intentionsSort, resetIntentionWithZamerType, spreadObject } from '../helperFunctions/helpers';
import { isRequestOK, fetchZamerDetails, fetchZamery, /* fetchZadosti, fetchZadostDetails */ } from '../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import usePrevious from './CustomHooks/usePrevious';
import { errorIcon, infoCircle } from '../assets';
import { FORM_05_INTENTION, FORM_10_INTENTION, FORM_11_INTENTION, FORM_12_INTENTION, FORM_14_INTENTION, POST_BODY_FOR_FILTER } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import { checkZamerStrictName, validateZamerName } from '../helperFunctions/validationHelpers';
import useDebounce from '../components/CustomHooks/useDebounce';
import { useLocation } from 'react-router-dom';

const ZamerSelectionControls = ({
    title = 'Název záměru',
    textExistujici = 'existující záměr',
    textNovy = 'nový záměr',
    request,
    setRequest,
    state,
    setState,
    buildIntentionModel,
    isIntentionCheckboxDisabled = false,
    setParcelAndConstructionData,
    setNewBuilding,
}) => {
    const { token } = useContext(AuthContext);
    const { stepValue, stagesArr, intention, id, setUploadError, fetch } = useContext(FormContext);
    const { inputValue, zamerType } = state;
    const prevZamerType = usePrevious(state.zamerType);
    const [existsIntention, setExistsIntention] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [zameryList, setZameryList] = useState([]);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const isEmpty = !request.buildIntention?.title?.value?.trim();
    const isLimitExceeded = request.buildIntention?.title?.value?.trim()?.length > 100;
    const isValidName = zameryList?.some(item => item === inputValue);
    const [focused, setFocused] = useState(false);
    const hasError = ((error && (isEmpty || isLimitExceeded || isValidName)) || (focused && (isEmpty || isLimitExceeded || isValidName)));
    const limit = 100;
    const debouncedIntention = useDebounce(inputValue);
    const [intentionId, setIntentionId] = useState(null);
    const [persons, setPersons] = useState([]);
    const location = useLocation();
    const isCopied = location?.state?.isCopied ?? false;

    const [existingZamerIsSet, setExistingZamerIsSet] = useState(false);

    const getIntentionDetail = async (chosenIntention, signal) => {
        try {
            const response = await fetchZamerDetails(chosenIntention.versionId, token, signal, setUploadError);
            if (!signal.aborted && isRequestOK(response.status)) {
                const DATA = response.data?.data;
                const buildInt = DATA?.buildIntention ?? null;
                if (buildInt && persons) {

                    await setRequest(state => ({
                        ...state, buildIntention: {
                            ...(spreadObject(buildInt) || {}),
                            // THE VALUES BELOW SHOULD NOT BE SPECIFIED WHEN COPYING DATA
                            newBuilding: false,
                            changeOfFinishedBuilding: false,
                            buildingTemporary: false,
                            setOfBuildings: false,
                            techOrMarketFacility: false,
                            siteFacility: false,
                            waterworks: false,
                        }
                    }));

                    persons.forEach(async el => {
                        if (el.personRelation === 'APPLICANT') {
                            const includeRepresentative = (response?.data?.data?.buildIntention.applicant?.repreNames && response?.data?.data?.buildIntention.applicant?.repreLastName && response?.data?.data?.buildIntention.applicant?.repreDateOfBirth);
                            await setRequest(state => ({
                                ...state, form: {
                                    ...state?.form, applicant: {
                                        ...el,
                                        nationality: response?.data?.data?.buildIntention.applicant?.nationality === '203' ? 'CZE' : response?.data?.data?.buildIntention.applicant?.nationality,
                                        includeRepresentative: response?.data?.data?.buildIntention.applicant?.includeRepresentative || includeRepresentative,
                                        repreNationality: response?.data?.data?.buildIntention.applicant?.repreNationality === '203' ? 'CZE' : response?.data?.data?.buildIntention.applicant?.repreNationality
                                    },
                                    applicantAttorney: false,
                                    applicantMe:true,
                                    applicantMore: false,
                                }
                            }));
                        }
                        else if (el.personRelation === 'ATTORNEY') {
                            await setRequest(state => ({
                                ...state, form: {
                                    ...state?.form, attorney: {
                                        ...el,
                                        nationality: response?.data?.data?.buildIntention.attorney?.nationality === '203' ? 'CZE' : response?.data?.data?.buildIntention.attorney?.nationality,
                                        repreNationality: response?.data?.data?.buildIntention.attorney?.repreNationality === '203' ? 'CZE' : response?.data?.data?.buildIntention.attorney?.repreNationality
                                    },
                                    applicantAttorney: true,
                                    applicantMe:false,
                                    applicantMore: false,
                                }
                            }));
                        }
                        else if (el.personRelation === 'APPLICANTS') {
                            await setRequest(state => ({ ...state, form:
                                    { ...state?.form,
                                        applicants: [...(state?.form?.applicants || []), { ...el }],
                                        applicantAttorney: false,
                                        applicantMe:true,
                                        applicantMore: false,} }));
                        }
                    });
                }

                setIsLoading(false);
            }
        } catch (e) {
            // TODO error handling;
            if (!signal.aborted) {
                setIsLoading(false);
            }
        }
    };

    const handleAddedIntention = async (input) => {
        const chosenIntention = findChosenIntention(
            input, //str
            zameryList //ArrayOf(Object)
        );
        // console.log('CHOSEN INTENTION', chosenIntention);

        if (!chosenIntention) {
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;
        const response = await fetchZamery(POST_BODY_FOR_FILTER, token, signal);
        // const response = await fetchZadosti(POST_BODY_FOR_FILTER, token, signal);
        // console.log('RESPONSE', response.data.data);

        if (isRequestOK(response.status)) {
            const chosenZadost = response?.data?.data?.find(el => el.projectId === chosenIntention.projectId);
            // console.log('CHOZEN ZADOST', chosenZadost);

            if (!chosenZadost) return;

            const response2 = await fetchZamerDetails(chosenZadost?.versionId, token, signal, setUploadError);
            // const response2 = await fetchZadostDetails(response.data.data[0].pid, token, signal, setUploadError);
            // console.log('RESPONSE 2', response2);

            if (isRequestOK(response2.status)) {
                setPersons([
                    ...response2.data.data.buildIntention.applicants,
                    response2.data.data.buildIntention.attorney,
                    response2.data.data.buildIntention.applicant,
                ]);
                let { applicantMe, applicantAttorney, applicantMore } = response2.data.data.buildIntention;
                if (response2.data.data.buildIntention.applicant && !response2.data.data.buildIntention.attorney && response2.data.data.buildIntention.applicants.length === 0) {
                    applicantMe = true;
                    applicantAttorney = false;
                    applicantMore = false;
                } else if (response2.data.data.buildIntention.applicant && response2.data.data.buildIntention.attorney && response2.data.data.buildIntention.applicants.length === 0) {
                    applicantAttorney = true;
                    applicantMe = false;
                    applicantMore = false;
                } else if (response2.data.data.buildIntention.applicants.length > 0) {
                    applicantMore = true;
                    applicantMe = false;
                    applicantAttorney = false;
                }

                setRequest(state => ({
                    ...state, buildIntention: {
                        ...response2?.data?.data?.buildIntention,
                        // THE VALUES BELOW SHOULD NOT BE SPECIFIED WHEN COPYING DATA
                        newBuilding: false,
                        changeOfFinishedBuilding: false,
                        buildingTemporary: false,
                        setOfBuildings: false,
                        techOrMarketFacility: false,
                        siteFacility: false,
                        waterworks: false,
                        approvedConstructions: intention === FORM_11_INTENTION.title || intention === FORM_12_INTENTION.title || intention === FORM_14_INTENTION.title ? response2?.data?.data?.buildIntention.buildConstructions : [],
                    }, form: {
                        ...state?.form, applicantMe, applicantMore, applicantAttorney
                    }
                }));
            }
        }
        setIsLoading(true);
        setIntentionId(chosenIntention);
    };

    const fetchZameryList = async (signal) => {
        setIsLoading(true);
        try {
            const response = await fetchZamery(POST_BODY_FOR_FILTER, token, signal);

            if (!signal.aborted && isRequestOK(response.status)) {
                setExistsIntention(response.data.data.length > 0);
                setZameryList([...response.data.data].sort(intentionsSort));
                setIsLoading(false);
            }
        } catch (e) {
            // TODO error handling;
            if (!signal.aborted) {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (isCopied) {
            return;
        }
        const controller = new AbortController();
        const signal = controller.signal;

        if (intentionId) getIntentionDetail(intentionId, signal);

        return () => {
            controller.abort();
        };
    }, [intentionId, fetch, debouncedIntention]);

    useEffect(() => {
        if (isCopied) {
            return;
        }
        const controller = new AbortController();
        const signal = controller.signal;

        fetchZameryList(signal);

        return () => {
            setFocused(false);
            controller.abort();
        };
    }, []);

    const handleIntentionChange = (e) => {
        validateZamerName(e.target);
        setState(prevState => ({ ...prevState, inputValue: e.target.value }));
    };

    useEffect(() => {
        if (isCopied) {
            return;
        }
        if (existingZamerIsSet) {
            handleAddedIntention(debouncedIntention);
        }

        // handleAddedIntention(debouncedIntention);

    }, [debouncedIntention]);

    useEffect(() => {
        console.log('PERSONS', persons);
    }, [persons]);

    useEffect(() => {
        console.log('PERSONS', request);
    }, [request]);

    return (
        <>
            <Row className='mb-4'>
                <Container className='d-flex flex-column p-0'>
                    <Row>
                        <Col xs={12} md={6}>
                            <label className={!existsIntention ? 'd-flex align-items-center mb-2 label disabled' : 'd-flex align-items-center mb-2 label'} style={{ cursor: existsIntention ? 'pointer' : '', width: 'fit-content' }}>
                                <input
                                    type="radio"
                                    name="zamer"
                                    value="existing"
                                    {...(!existsIntention ? { disabled: true } : { disabled: isIntentionCheckboxDisabled })}
                                    className="radio"
                                    checked={zamerType === 'existing'}
                                    onChange={(e) => {
                                        resetIntentionWithZamerType(
                                            e.target.value,
                                            setState,
                                            setRequest,
                                            buildIntentionModel,
                                            request.buildIntention.title?.value ?? '',
                                            null,
                                            null,
                                            null,
                                            null,
                                        );
                                        setExistingZamerIsSet(true);
                                    }}
                                />
                                {textExistujici}
                            </label>
                            <label className='d-flex align-items-center label' style={{ cursor: 'pointer', width: 'fit-content' }}>
                                <input
                                    type='radio'
                                    name='zamer'
                                    value='new'
                                    className='radio'
                                    disabled={isIntentionCheckboxDisabled}
                                    checked={zamerType === 'new'}
                                    onChange={(e) => {
                                        resetIntentionWithZamerType(
                                            e.target.value,
                                            setState,
                                            setRequest,
                                            buildIntentionModel,
                                            prevZamerType,
                                            request.buildIntention?.id ?? id,
                                            token,
                                            setParcelAndConstructionData ?? null,
                                            setNewBuilding ?? null,
                                        );
                                        setExistingZamerIsSet(false);
                                    }}
                                />
                                {textNovy}
                            </label>
                            {!existsIntention && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-1 kontrola-info'>
                                <img src={infoCircle} alt="infoCircle" className='me-2' />Ve vašem účtu jsme nenalezli žádný záměr, prosím vytvořte nový.
                            </Col>}
                        </Col>
                        {intention === FORM_05_INTENTION.title && (
                            <Col xs={12} md={6} className='d-flex flex-column justify-content-center gap-1 mt-3 mt-md-0'>
                                <p className='input-help-text'>Uveďte název záměru uvedený ve vydaném povolení.</p>
                                <p className='input-help-text'>Nenajdete-li záměr, vytvořte prosím nový.</p>
                            </Col>
                        )}
                    </Row>
                </Container>
            </Row>

            <Container className='p-0'>
                {zamerType === 'existing' &&
                    <Row className='row-wrapper'>
                        <p className='mb-3 p-0 title' style={{ color: error && inputValue?.trim() === '' ? '#C52A3A' : 'black' }}>Vyhledat záměr</p>
                        <Row className='d-flex align-items-center'>
                            <Col xs={12} md={6} className='d-flex flex-column mb-3 mb-md-0'>
                                <input
                                    type='text'
                                    list='zamery'
                                    disabled={isIntentionCheckboxDisabled}
                                    value={inputValue ?? ''}
                                    style={{ border: error && inputValue?.trim() === '' && 'solid 1px #C52A3A' }}
                                    onChange={handleIntentionChange}
                                    onBlur={(e) => !checkZamerStrictName(e.target) ? resetIntentionWithZamerType('existing', setState, setRequest, buildIntentionModel, request.buildIntention.title?.value ?? '', null, null, null, null) : ''}
                                    className={`id-input2 ${isIntentionCheckboxDisabled ? '' : 'bg-white'} ${isLoading ? 'with-loading-and-search' : 'with-search'}`}
                                    placeholder='Dle ID či názvu'
                                />
                                <datalist id='zamery'>
                                    {zameryList.map((zamer, idx) => {
                                        return <option key={zamer.id ?? idx}>{(zamer.projectId || zamer.projectName) ? `${zamer.projectId ?? ''} ${(zamer.projectId && zamer.projectName) ? '-' : ''} ${zamer.projectName ?? ''}` : ''}</option>;
                                    })}
                                </datalist>
                            </Col>
                            <Col xs={12} md={6} className='input-help-text d-flex align-items-center ps-0 ps-md-3'>Vyhledávač nabídne pouze záměry, ke kterým máte přístup</Col>
                        </Row>
                        {error && inputValue?.trim() === '' && (
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Záměr” není možné pokračovat.</p>
                            </Col>
                        )}

                        {hasError && inputValue?.trim() !== '' && !isValidName && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                            <p className='d-flex align-items-center p-0 warning-text'>
                                <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                Vyhledejte a vyberte existující záměr.
                            </p>
                        </Col>}
                    </Row>}

                {zamerType === 'new' &&
                    <Row className='row-wrapper'>
                        <p className='title p-0 mb-3' style={{ color: hasError ? '#C52A3A' : 'black' }}>{title}</p>
                        <label className='mb-2 px-0'>
                            <Row className=''>
                                <Col xs={12} md={6} className='d-flex flex-column'>
                                    <input
                                        type='text'
                                        required
                                        defaultValue={request.buildIntention.title?.value ?? ''}
                                        style={{ border: hasError && 'solid 1px #C52A3A' }}
                                        onBlur={(e) => {
                                            setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, title: { ...state.buildIntention.title, value: e.target.value } } }));
                                            if (!e.target.value?.trim()?.length || e.target.value?.trim()?.length > 100) {
                                                setFocused(true);
                                            }
                                        }}
                                        className='id-input2 bg-white p-2'
                                        onChange={(e) => {
                                            if (focused && e.target.value?.trim()?.length && e.target.value?.trim()?.length <= 100) {
                                                setFocused(false);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </label>
                        {hasError && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                            <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                {isEmpty && 'Bez vyplnění pole “Záměr” není možné pokračovat.'}
                                {isLimitExceeded && `Název záměru může mít nejvýše ${String(limit)} znaků. Prosím zkraťte text.`}
                            </p>
                        </Col>}
                    </Row>}
            </Container>

            {intention === FORM_10_INTENTION.title && <Row className='row-wrapper'>
                <p className='d-flex align-items-start p-0 mb-2 existing-zamer-info'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    Tento formulář návrhu neslouží pro stanovení ochranného pásma k ochraně okolí před negativními účinky stavby, které se stanovují z moci úřední</p>
                <p className='d-flex align-items-start p-0 existing-zamer-info'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    V případě stanovení ochranného pásma, které je součástí záměru, pro který se žádá o povolení stavby a které se netýká blíže neurčeného okruhu osob nebo se netýká ochranného pásma vodního díla, se podává návrh na stanovení ochranného pásma jako součást žádosti o povolení stavby na formuláři, který je obsažen v příloze č. 3 vyhlášky č. 149/2024 Sb.</p>
            </Row>}

        </>
    );
};

ZamerSelectionControls.propTypes = {
    title: PropTypes.string,
    textExistujici: PropTypes.string,
    textNovy: PropTypes.string,
    state: PropTypes.object,
    setState: PropTypes.func,
    setRequest: PropTypes.func,
    setParcelAndConstructionData: PropTypes.func,
    setNewBuilding: PropTypes.func,
    buildIntentionModel: PropTypes.object,
    request: PropTypes.object,
    isIntentionCheckboxDisabled: PropTypes.bool,
};

export default ZamerSelectionControls;
