import React from 'react';
import PropTypes from 'prop-types';

const CircularProgress = ({ progress, totalSteps }) => {
    const radius = 36; 
    const circumference = 2 * Math.PI * radius;
    const progressOffset = circumference - (progress / totalSteps) * circumference;

    return (
        <svg width="80" height="80" viewBox="0 0 80 80">
            <circle
                cx="40"
                cy="40"
                r={radius}
                fill="none"
                stroke="#EAEAEA"
                strokeWidth="8"
            />
            <circle
                cx="40"
                cy="40"
                r={radius}
                fill="none"
                stroke="#6FBD2C"
                strokeWidth="8"
                strokeDasharray={circumference}
                strokeDashoffset={progressOffset}
                transform="rotate(-90 40 40)"
                style={{ transition: 'stroke-dashoffset 0.35s' }}
            />
        </svg>
    );
};

CircularProgress.propTypes = {
    progress: PropTypes.number,
    totalSteps: PropTypes.number,
};

export default CircularProgress;
