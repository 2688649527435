import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { uploadLoader } from '../assets';

const ProgressIndicator = ({
    averageProgress,
    type,
    uploadSize,
    totalSize,
    remainingSize,
}) => {
    return (
        <>
            {type === 'header' ? (averageProgress > 0 && (
                <Container>
                    <Col className='d-flex' style={{marginBottom: '1rem'}}>
                        <img src={uploadLoader} className='loader-image'/>
                        <p style={{fontSize: '1rem', fontWeight: '700', lineHeight: '1.5rem', letterSpacing: '0.015rem'}}>Nahrávám soubor</p>
                    </Col>
                    <Col>
                        <p style={{marginLeft: '2.25rem', marginBottom: '1rem', fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem'}}>{`${averageProgress}% hotovo`}</p>
                    </Col>
                </Container>
            )) : 
                type === 'footer' ? (averageProgress > 0 && (
                    <Col className='d-flex align-items-center justify-content-center'>
                        <img src={uploadLoader} className='loader-image small'/>
                        <Container className='d-flex flex-column upload-indicator-footer'>
                            <Row>
                                {(uploadSize && totalSize) && <p className='upload-indicator-footer primary'>{`Nahrávám ${uploadSize} z ${totalSize}`}<span className='upload-indicator-footer span'>{` (${averageProgress}% hotovo)`}</span></p>}
                            </Row>
                            <Row>
                                {remainingSize && <p className='upload-indicator-footer secondary'>{`Možno nahrát ještě ${remainingSize}`}</p>}
                            </Row>
                        </Container>
                    </Col>
                )) 
                    : ''}        
        </>
    );
};

ProgressIndicator.propTypes = {
    averageProgress: PropTypes.any,
    remainingSize: PropTypes.any,
    uploadSize: PropTypes.any,
    totalSize: PropTypes.any,
    type: PropTypes.any,
};

export default ProgressIndicator;