import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { arrowDownBlue } from '../../assets';
import { COUNTRIES } from '../../constants/sharedConstants';

const StatniPrislusnost = ({setRequest, request, propToUpdate, requestPropToUpdate, optional = false }) => {
    return (
        <>
            <label className='d-flex flex-column label pt-4 ps-0'>
                {optional ? 'Státní příslušnost (nepovinné)' : 'Státní příslušnost'}
                <Row>
                    <Col xs={12} md={6}>
                        <select 
                            type='select'
                            value={(propToUpdate && requestPropToUpdate) ? request[requestPropToUpdate][propToUpdate]?.nationality : request?.nationality}
                            style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                            onChange={(e) => { 
                                if (propToUpdate && requestPropToUpdate) {
                                    setRequest(state => ({ ...state, [requestPropToUpdate]: { ...state[requestPropToUpdate], [propToUpdate]: { ...state[requestPropToUpdate][propToUpdate], nationality: e.target.value } } })); 
                                } else {
                                    setRequest(state => ({ ...state, nationality: e.target.value  })); 
                                }
                            }}
                            className='id-input2 w-100'
                        >   
                            <option value={''}>Vyberte</option>
                            {COUNTRIES.map((country) => (
                                <option key={country.value} value={country.value}>{country.label}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </label>
        </>
    );
};

StatniPrislusnost.propTypes = {
    setRequest: PropTypes.func,
    request: PropTypes.object,
    requestPropToUpdate: PropTypes.string,
    propToUpdate: PropTypes.string,
    optional: PropTypes.bool,
};

export default StatniPrislusnost;