import React from 'react';
import '../css/footer.css';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = ({isScrollable}) => {
    return (
        <Container className={isScrollable ? 'p-3 pt-5 pb-5 p-md-5 d-flex flex-column align-items-center justify-content-center footer' : 'disable-scroll p-3 pt-5 pb-5 p-md-5 flex-column align-items-center justify-content-center footer'} fluid>
            <Row className='d-flex justify-content-between pb-5 info' >
                <Col md={4}>
                    <p className='mb-4 fw-semibold footer-title'>Informace</p>
                    <p className='mb-4 footer-text'>
                        <a href='/accessibility' className='footer-link'>PROHLÁŠENÍ O PŘÍSTUPNOSTI</a>
                    </p>
                    <p className='mb-4 footer-text'>
                        <a href='/personalData' className='mb-4 footer-link'>INFORMACE O ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</a>
                    </p>
                    <p className='mb-4 footer-text'>
                        <a href='/cookies' className='footer-link'>COOKIES</a>
                    </p>
                </Col>
                <Col md={4} className='mt-3 mt-md-0'>
                    <p className='mb-4 fw-semibold footer-title'>Provozovatel</p>
                    <p className='mb-2 footer-text'>ČR – Ministerstvo pro místní rozvoj</p>
                    <p className='mb-4 footer-text'>Staroměstské náměstí 6, 110 00 Praha 1</p>
                    <p className='mb-4 fw-semibold footer-title'>Centrální podací místo stavební správy</p>
                    <p className='footer-text'>ID datové schránky: kbt8xbr</p>
                    {/* <p className='footer-text'>
                        Tel.: <a href="tel:+420224861111" className='footer-link'>+420 224 861 111</a> (ústředna)<br />
                        ID datové schránky: 26iaava
                    </p> */}
                </Col>
                <Col md={4} className='mt-3 mt-md-0'>
                    <p className='mb-4 fw-semibold footer-title'>Podpora</p>
                    <p className='mb-4 footer-text'>
                        <a href="tel:+420256256313" className='footer-link'>+420 256 256 313</a>
                    </p>
                    <p className='mb-4 footer-text'>
                        <a href="mailto:digitalizace@mmr.gov.cz" className='footer-link'>DIGITALIZACE@MMR.GOV.CZ</a>
                    </p>
                    <p className='mb-4 footer-text'>
                        <a href="./doc/uzivatelska_dokumentace_v1_8.pdf" className='footer-link'
                            download='Uživatelská dokumentace'>Dokumentace ke stažení</a>
                    </p>
                    <p className='footer-text'>
                        <a href="/newsOnPortal" className='footer-link'
                        >Novinky na Portálu</a>
                    </p>
                </Col>
            </Row>
            <Row className='info2'>
                <Col xs={12}>
                    <p className='mt-5 trademark'>© Ministerstvo pro místní rozvoj ČR 2024</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;

Footer.propTypes = {
    isScrollable: PropTypes.bool
};
