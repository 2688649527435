import React from 'react';
import '../../css/reusable.css';
import '../../css/footerInformation.css';
import { Container, Row, Col, Table } from 'react-bootstrap';

const Cookies = () => {
    return (
        <Container className='welcome-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5' style={{height: '100%'}} fluid>
            <Row className='d-flex flex-column justify-content-center flex-md-row base-width welcome-section-content'>
                <Row className='row-wrapper'>
                    <Col md={8}>
                        <p className='p-0 mb-3 main_title'>Cookies</p>
                        <p className='p-0 help_text'>Pro správnou funkci stránek je někdy nezbytné umístit malé datové soubory, označované jako cookies, na vaše zařízení.</p>
                    </Col>
                    
                </Row>

                <Row>
                    <Col md={8}>
                        <p className='p-0 sub_title margin_bottom-20'>Co jsou cookies?</p>
                        <p className='p-0 text margin_bottom-20'>Cookies jsou malé textové soubory, které internetové stránky ukládají na váš počítač nebo mobilní zařízení v okamžiku, kdy tyto stránky začnete využívat. Stránky si tak na určitou dobu zapamatují vaše preference a úkony, které jste na nich provedli (např. přihlašovací údaje), takže tyto údaje pak nemusíte zadávat znovu a přeskakovat z jedné stránky na druhou.</p>
                        
                        <Table bordered>
                            <thead>
                                <tr className='align-middle'>
                                    <th>NÁZEV</th>
                                    <th>DOPORUČENÍ</th>
                                    <th>EXPIRACE</th>
                                    <th>KDO MÁ PŘÍSTUP K INFORMACÍM (NAŠE NEBO TŘETÍ STRANA)</th>
                                    <th>POPIS</th>
                                    <th>KATEGORIE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ROCP</td>
                                    <td>Nedoporučujeme blokovat</td>
                                    <td>Local Storage</td>
                                    <td>gov.cz<br/>Microsoft Azure</td>
                                    <td>Drží ověření pro komunikaci aplikace běžící v prohlížeči se serverem.</td>
                                    <td>Nezbytné funkční</td>
                                </tr>
                                <tr>
                                    <td>PKCE</td>
                                    <td>Nedoporučujeme blokovat</td>
                                    <td>Session Storage</td>
                                    <td>gov.cz<br/>Microsoft Azure</td>
                                    <td>Drží ověření pro komunikaci aplikace běžící v prohlížeči se serverem.</td>
                                    <td>Nezbytné funkční</td>
                                </tr>
                            </tbody>
                        </Table>

                        <p className='p-0 text mb-2'>Local Storage je mazána za určitých podmínek automaticky prohlížečem, nebo uživatelskou akcí. Seesion storage je mazána automaticky prohlížečem při konci sezení.</p>
                        <p className='p-0 text mb-2'>Pro účely přihlášení využíváme službu “Identita občana”, která pro účely ověření vaší identity též využívá cookies. Detailní popis najdete na <a href="https://info.identitaobcana.cz/cookies/">https://info.identitaobcana.cz/cookies/</a>.</p>
                    </Col>
                </Row>
            </Row>
            
        </Container>
        
    );
};

export default Cookies;