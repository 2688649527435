import {  
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED,
    ATTORNEY_PERSON_AUTHORIZED,
    REPRE_PERSON_AUTHORIZED,
    REPRE_ATTORNEY_PERSON_AUTHORIZED,
    LOCATION, 
    LOCATION_BUSINESS,
    DELIVERY_LOCATION, 
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 2,
        name: 'Žadatel',
        moreInfo: 'Osobní údaje a o žadateli případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 3,
        name: 'Dokumentace',
        moreInfo: 'Stavební dokumentace, ve výchozím stavu se přebírá ze Záměru',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 4,
        name: 'Rozsah',
        moreInfo: 'Rozsah žádosti a případná rozšíření',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 5,
        name: 'Přilohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 6,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,    
    },
    {
        stage: 7,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,    
    },
];

// eslint-disable-next-line no-unused-vars
const getItemsBasedOnCheckbox = (isCheckbox1Checked = null, isCheckbox2Checked = null, setStagesArr, prevCheckboxState) => {
    const {first} = prevCheckboxState || {};
    const prevFirst = first ?? false;

    if (isCheckbox1Checked && !prevFirst) {
        const newItem = {
            stage: 5,
            name: 'Výjimka',
            error: false,
            warning: false,
            seen: false,
        };
        setStagesArr(prev => [...prev.slice(0, 4), {...newItem, ...([...prev].find(stg => stg.name === 'Výjimka') || {})}, ...prev.slice(4).map(stg => ({...stg, stage: stg.stage + Number(isCheckbox1Checked && !prevFirst)}))]);
    } else if (!isCheckbox1Checked && prevFirst) {
        setStagesArr(prev => [...prev.slice(0, 4), ...prev.slice(5).map(stg => ({...stg, stage: stg.stage - Number(!isCheckbox1Checked && prevFirst)}))]);
    }
};

// DOCUMENT UPLOAD CONSTANTS

const PLNA_MOC_FIRST = 'power_of_attorney_advocate';
const PLNA_MOC_SECOND = 'power_of_attorney_more';
const WARRANT = 'warrant';
const FULFILLING_PRODUCT = 'fulfilling_function_of_construction';
const OTHER_DOCUMENTS = 'other_documents';

const BUILDING_ADDRESS = [
    {
        id: 1,
        label: 'Obec (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-city',
        required: false,
        state: 'city',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-street',
        required: false,
        state: 'street',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 6,
        label: 'PSČ (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-zipCode',
        required: false,
        state: 'zip',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
];


const COORDINATED_OPINION_OR_COMMITTED_VIEW = 'isCoordOpinion';
const UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA = 'isUnifEnvOpinWithoutEia';
const UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA = 'isUnifEnvOpinWithEia';
const OPINION_OR_COMMITTED_VIEW = 'isOpinion';
const WATER_PROTECTION = 'isWaterProtection';
const LIFE_ENVIRONMENTAL_PROTECTION = 'isLifeEnvironmentProtection';
const MINERAL_BEARING_BEDS_PROTECTION = 'isMineralDepositProtection';
const PUBLIC_HEALTH_PROTECTION = 'isPublicHealthProtection';
const SPA_AND_SPRINGS_PROTECTION = 'isSpaAndSpringProtection';
const VETERINARY_CARE = 'isVeterinaryCare';
const MONUMENT_CARE = 'isHeritageProtection';
const LAND_TRANSPORT = 'isLandTransportationProtection';
const RAIL_TRANSPORT = 'isRailTransportationProtection';
const AIR_TRANSPORT = 'isAirTransportationProtection';
const WATER_TRANSPORT = 'isWaterTransportationProtection';
const ENERGY_INDUSTRY = 'isEnergyProtection';
const NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION = 'isNuclearSafetyAndRadiationProtection';
const ELECTRONIC_COMMUNICATIONS = 'isElectronicCommunicationsProtection';
const STATE_DEFENSE = 'isStateDefense';
const STATE_SECURITY = 'isStateSecurity';
const CIVILIAN_PROTECTION = 'isPopulationProtection';
const FIRE_PROTECTION = 'isFireProtection';
const OTHER_PROTECTION = 'otherProtection';
const IS_OTHER_PROTECTION = 'isOtherProtection';
const BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT = 'isConstructionPermission';
const PERMIT_MODIFICATION = 'isPermitModification';
const LAND_USE_CHANGE_PERMIT = 'isLandUsePermission';
const LAND_DIVISION_OR_CONSOLIDATION_PERMIT = 'isLandDivisionPermission';
const PROTECTIVE_ZONE_DETERMINATION = 'isProtectiveZoneEstablishment';
const FRAMEWORK_PERMIT = 'isFrameworkPermission';
const INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION = 'isPreCompletionIntentionChangePermission';
const OCCUPANCY_PERMIT = 'isOccupancyPermit';
const EARLY_OCCUPANCY_PERMIT = 'isEarlyOccupancyPermission';
const TRIAL_OPERATION_PERMIT = 'isTrialOperationPermission';
const CHANGE_IN_USE_PERMIT = 'isUsageChangePermission';
const DEMOLITION_PERMIT = 'isDemolitionPermission';
const ADDITIONAL_PERMIT = 'isAdditionalPermission';
const EXCEPTION = 'isException';

const INITIAL_OBJ = {
    [COORDINATED_OPINION_OR_COMMITTED_VIEW]: true,
    [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: false,
    [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: false,
    [OPINION_OR_COMMITTED_VIEW]: false,
    [WATER_PROTECTION]: false,
    [LIFE_ENVIRONMENTAL_PROTECTION]: false,
    [MINERAL_BEARING_BEDS_PROTECTION]: false,
    [PUBLIC_HEALTH_PROTECTION]: false,
    [SPA_AND_SPRINGS_PROTECTION]: false,
    [VETERINARY_CARE]: false,
    [MONUMENT_CARE]: false,
    [LAND_TRANSPORT]: false,
    [RAIL_TRANSPORT]: false,
    [AIR_TRANSPORT]: false,
    [WATER_TRANSPORT]: false,
    [ENERGY_INDUSTRY]: false,
    [NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION]: false,
    [ELECTRONIC_COMMUNICATIONS]: false,
    [STATE_DEFENSE]: false,
    [STATE_SECURITY]: false,
    [CIVILIAN_PROTECTION]: false,
    [FIRE_PROTECTION]: false,
    [BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT]: false,
    [PERMIT_MODIFICATION]: false,
    [LAND_USE_CHANGE_PERMIT]: false,
    [LAND_DIVISION_OR_CONSOLIDATION_PERMIT]: false,
    [PROTECTIVE_ZONE_DETERMINATION]: false,
    [FRAMEWORK_PERMIT]: false,
    [INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION]: false,
    [OCCUPANCY_PERMIT]: false,
    [EARLY_OCCUPANCY_PERMIT]: false,
    [TRIAL_OPERATION_PERMIT]: false,
    [CHANGE_IN_USE_PERMIT]: false,
    [DEMOLITION_PERMIT]: false,
    [ADDITIONAL_PERMIT]: false,
    [EXCEPTION]: null,
    [IS_OTHER_PROTECTION]: false,
    [OTHER_PROTECTION]: '',
};

export {
    getItemsBasedOnCheckbox,
    BASIC_INFO,
    BASIC_INFO_BUSINESS,
    BASIC_INFO_BUSINESS_APPENDIX,
    BASIC_INFO_PO,
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED,
    ATTORNEY_PERSON_AUTHORIZED,
    REPRE_PERSON_AUTHORIZED,
    REPRE_ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
    BUILDING_ADDRESS,
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    WARRANT,
    WATER_PROTECTION,
    EXCEPTION,
    OTHER_PROTECTION,
    COORDINATED_OPINION_OR_COMMITTED_VIEW,
    MINERAL_BEARING_BEDS_PROTECTION,
    PUBLIC_HEALTH_PROTECTION,
    SPA_AND_SPRINGS_PROTECTION,
    VETERINARY_CARE,
    MONUMENT_CARE,
    LAND_TRANSPORT,
    RAIL_TRANSPORT,
    AIR_TRANSPORT,
    WATER_TRANSPORT,
    ENERGY_INDUSTRY,
    NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION,
    ELECTRONIC_COMMUNICATIONS,
    STATE_DEFENSE,
    STATE_SECURITY,
    CIVILIAN_PROTECTION,
    FIRE_PROTECTION,
    UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA,
    UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA,
    OPINION_OR_COMMITTED_VIEW,
    FULFILLING_PRODUCT,
    BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT,
    PERMIT_MODIFICATION,
    LAND_USE_CHANGE_PERMIT,
    LAND_DIVISION_OR_CONSOLIDATION_PERMIT,
    PROTECTIVE_ZONE_DETERMINATION,
    FRAMEWORK_PERMIT,
    INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION,
    OCCUPANCY_PERMIT,
    EARLY_OCCUPANCY_PERMIT,
    TRIAL_OPERATION_PERMIT,
    CHANGE_IN_USE_PERMIT,
    DEMOLITION_PERMIT,
    OTHER_DOCUMENTS,
    INITIAL_OBJ,
    LIFE_ENVIRONMENTAL_PROTECTION,
    ADDITIONAL_PERMIT,
    IS_OTHER_PROTECTION,
    items,
};
