import React from 'react';
import '../css/homePage.css';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { Accessibility } from '../components';

const AccesibilityStatement = () => {

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <Accessibility/>
        </Container>
    );
};

export default AccesibilityStatement;