import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/dokumentace.css';
import { AddProjektantInfoAndGenerateLink, FileUpload, UploadedAttachments, DokumentaceRadioControls } from '../index.js';
import { DOCUMENT_FIFTH, DOCUMENT_FIRST, DOCUMENT_FOURTH, DOCUMENT_SECOND, DOCUMENT_THIRD_1, DOCUMENT_THIRD_2, DOCUMENT_THIRD_3, DOCUMENT_THIRD_4, DOCUMENT_THIRD_5} from '../../constants/sharedConstants.js';
import { shouldNotSave, spreadObject, setAccomanyingDoc, filterPruvodniListDocuments, filterPruvodniListToDownload, handleFiles, handleSpreadUploadedDocuments, getOngoingUploadsForDokumentace } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import FormContext from '../../formContexts/FormContext.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { infoCircle } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const Dokumentace17 = ({progressElements, setProgressElements, urlPath, documents, setDocuments}) => {
    const {intention, stepValue, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [moreInfo, setMoreInfo] = useState(false);
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'documentations': [],
                shareForm: {
                    firstName: '',
                    lastName: '',
                    paperType: 'ID',
                    paperNumber: ''
                },
                authorizedPersonSharedLink: '',
            },
            'buildIntention': {
                'documentUploadHere': null,
                'documentPaperForm': null,
                'documentPreviousVersion' : null,
            },
        }
    );
    const [accompanyingDocumentStatus, setAccompanyingDocumentStatus] = useState(undefined);
    const onGoingUploads = getOngoingUploadsForDokumentace(progressElements);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const { setUploadedBytes, setUploadError, uploadError } = useContext(FormContext);    
    const allDocuments = Object.values(documents).flat();
    const documentsToUpdate = [...allDocuments].filter(fileObj => 
        (!fileObj.blobFile && !fileObj.abortController && !fileObj.progress && !fileObj.message));
    const existingFilesToUpdate = documentsToUpdate.filter(el => el.existing);
    const newFilesToUpdate = documentsToUpdate.filter(el => !el.existing);

    const saveChanges = async (manualReq = null) => {
        const source = axios.CancelToken.source();
        await formSave(manualReq ?? request, token, urlPath, source);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedIntention = response.data?.buildIntention;

                    if (loadedIntention) {
                        setRequest(prev => ({...prev, buildIntention:
                            {...spreadObject(loadedIntention),
                                documentUploadHere: !loadedIntention?.eszId ?
                                    (loadedIntention.documentUploadHere ?? true)
                                    : (loadedIntention?.eszId &&
                                        (loadedIntention.documentPreviousVersion === null || loadedIntention.documentPreviousVersion || loadedIntention.documentPaperForm))
                                        ? false
                                        : Boolean(loadedIntention.documentUploadHere),
                                documentPaperForm: loadedIntention.documentPaperForm ?? false,
                                ...(loadedIntention?.eszId && { documentPreviousVersion: (loadedIntention.documentPreviousVersion ?? true) })
                            }}));
                    }

                    const loadedData = response.data.buildApplication;

                    if (loadedData) {
                        setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, 
                            authorizedPersonSharedLink: loadedData.authorizedPersonSharedLink ?? '', 
                            shareForm: {...prev.buildApplication.shareForm, ...loadedData.shareForm}
                        }}));
                    }
                    const documents = loadedData?.documentations;
                    handleSpreadUploadedDocuments([...(documents || []), ...onGoingUploads], loadedIntention, setDocuments, setUploadedBytes);
                    setAccomanyingDoc(response.data?.accompanyingDocument, setAccompanyingDocumentStatus);
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);
  
    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        if (!(request?.buildIntention?.id)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.documentations]);

    useEffect(() => {
        if (newFilesToUpdate.length >= 50000) {
            setUploadError(prev => [...prev, {
                where: null,
                why: 'total_amount',
                uid: null,
            }]);   
            return;
        } else if (newFilesToUpdate.length < 50000 && uploadError.find(el => el.why === 'total_amount')) {
            setUploadError(prev => [...prev].filter(el => el.why !== 'total_amount')); 
        }

        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, documentations: [...documentsToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {flagged, existing, ...rest} = file;
            return {...rest};
        }) } }));
    }, [existingFilesToUpdate.length, newFilesToUpdate.length]);

    const handleFileInput = (files, category) => {
        if (uploadError.find(el => el.why === 'total_amount')) {
            return;
        }
        handleFiles(files, category, token, setDocuments, 'documents');
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='d-flex row align-items-center dokumentace-container' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 pb-4 title primary'>{`${stepValue}. Dokumentace`}</p>
                <span className='dokumentace-text fst-italic p-0'>Dokumentace podle vyhlášky o dokumentaci staveb</span>
            </Row>
            <Row className='row-wrapper'>
                <DokumentaceRadioControls
                    documents={documents} 
                    setDocuments={setDocuments} 
                    setUploadedBytes={setUploadedBytes} 
                    saveChanges={saveChanges} 
                    setRequest={setRequest} 
                    request={request} 
                />
                <p className='p-0 dokumentace-help_text'><img src={infoCircle} alt="infoCircle" className='me-2'/>Datový limit dokumentace je 5 GB.</p>
            </Row>
            <Container className='pb-5'>
                <Row className='pb-3'>
                    <p className='p-0 title secondary'>A — Průvodní list</p>
                </Row>
                <Row>
                    <span className='dokumentace-text fst-italic fst-italic--grey p-0'>{request.buildIntention.documentPaperForm ? 
                        'Průvodní list musí zpracovat projektant nebo autorizovaná osoba. Níže vytvoříte odkaz, na kterém bude moci "Průvodní list" pro vaši žádost vytvořit.' : 
                        'Průvodní list dokumentace musí vytvořit zpracovatel dokumentace. Níže vytvoříte odkaz, na kterém bude moci zpracovatel dokumentace Průvodní list pro vaši žádost vytvořit.'}</span>
                    {request.buildIntention.documentPaperForm && <span className='dokumentace-text bold m-0 p-0 pt-3'>V případě listinného podání dokumentace se průvodní list se žádostí neodesílá, je třeba jej stáhnout a přiložit k listinné dokumentaci. </span>}
                </Row>
                <AddProjektantInfoAndGenerateLink              
                    urlPath={urlPath}
                    accompanyingDocumentStatus={accompanyingDocumentStatus}
                    documentToDownload={filterPruvodniListToDownload(documents[DOCUMENT_FIRST])}
                    request={request}
                    setRequest={setRequest}
                />
                <UploadedAttachments
                    category={DOCUMENT_FIRST}
                    attachments={filterPruvodniListDocuments(documents[DOCUMENT_FIRST])}
                    setUploads={setDocuments}
                    progressElements={progressElements}
                    setProgressElements={setProgressElements}
                />    
            </Container>
            {!request.buildIntention.documentPaperForm&& <>
                <Container className='pb-5'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>B — Souhrnná technická zpráva</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic p-0'>
                        Celkový popis území a stavby, Urbanistické a základní architektonické řešení, Základní technické a technologické řešení záměru,
                        Stavebně technické řešení a technologické řešení, Přístupnost stavby, Zásady bezpečnosti při užívání stavby, Základní technický popis stavby,
                        Základní popis technických a technologických zařízení, Zásady požární bezpečnosti
                            {!moreInfo && <span className='dokumentace-text--more' onClick={() => setMoreInfo(!moreInfo)}>... zobrazit celé</span>}
                            {moreInfo && <>, Úspora energie a tepelná ochrana, Hygienické požadavky na stavbu, požadavky na pracovní a komunální prostředí,
                            Zásady ochrany stavby před negativními účinky vnějšího prostředí, Připojení na technickou infrastrukturu,
                            Dopravní řešení, Řešení vegetace a souvisejících terénních úprav, Popis vlivů stavby na životní prostředí a jeho ochrana, 
                            Celkové vodohospodářské řešení, Ochrana obyvatelstva, Zásady organizace výstavby.
                            </>}
                        </span>
                    </Row>
                    <UploadedAttachments
                        category={DOCUMENT_SECOND}
                        attachments={documents[DOCUMENT_SECOND]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                    />                    
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_SECOND} progressElements={progressElements}/>
                </Container>
                <Container className='pb-5'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>C — Situační výkresy</p>
                    </Row>
                    <Row className='pb-4'>
                        <span className='dokumentace-text fst-italic p-0'>Situační výkres širších vztahů, Katastrální situační výkres, Koordinační situační výkres, Speciální situační výkresy, Dělení a scelení pozemků</span>
                    </Row>
                    <Row className='py-4'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary fw-normal'>C1 - Situační výkres širších vztahů</p>
                        </Row>
                        <UploadedAttachments
                            category={DOCUMENT_THIRD_1}
                            attachments={documents[DOCUMENT_THIRD_1]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />                    
                        <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_THIRD_1} progressElements={progressElements}/>
                    </Row>
                    <Row className='py-4'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary fw-normal'>C2 - Katastrální situační výkres</p>
                        </Row>
                        <UploadedAttachments
                            category={DOCUMENT_THIRD_2}
                            attachments={documents[DOCUMENT_THIRD_2]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />                    
                        <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_THIRD_2} progressElements={progressElements}/>
                    </Row>
                    <Row className='py-4'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary fw-normal'>C3 - Koordinační situační výkres</p>
                        </Row>
                        <UploadedAttachments
                            category={DOCUMENT_THIRD_3}
                            attachments={documents[DOCUMENT_THIRD_3]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />                    
                        <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_THIRD_3} progressElements={progressElements}/>
                    </Row>
                    <Row className='py-4'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary fw-normal'>C4 - Speciální výkresy</p>
                        </Row>
                        <UploadedAttachments
                            category={DOCUMENT_THIRD_4}
                            attachments={documents[DOCUMENT_THIRD_4]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />                    
                        <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_THIRD_4} progressElements={progressElements}/>
                    </Row>
                    <Row className='py-4'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary fw-normal'>C5 - Dělení nebo scelení pozemků</p>
                        </Row>
                        <UploadedAttachments
                            category={DOCUMENT_THIRD_5}
                            attachments={documents[DOCUMENT_THIRD_5]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />                    
                        <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_THIRD_5} progressElements={progressElements}/>
                    </Row>
                </Container>
                <Container className='pb-5'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>D — Dokumentace objektů</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic fst-italic--grey p-0'>Dokumentace se zpracovává po objektech a souborech technických nebo technologických zařízení. Dokumentace každého objektu se zpracovává přiměřeně podle požadavků stanovených v dokumentaci pro povolení záměru příslušného objektu.</span>
                    </Row>
                    <UploadedAttachments
                        category={DOCUMENT_FOURTH}
                        attachments={documents[DOCUMENT_FOURTH]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                    />                    
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_FOURTH} progressElements={progressElements}/>
                </Container>
                <Container className='mb-1'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>E — Dokladová část</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic fst-italic--grey p-0' style={{lineHeight: '1.641rem'}}>
                        Závazná stanoviska, stanoviska, rozhodnutí, vyjádření dotčených orgánů
                            <br />Doklad podle jiného právního předpisu
                            <br /> Vyjádření vlastníků veřejné dopravní a technické infrastruktury
                        </span>
                    </Row>
                    <UploadedAttachments
                        category={DOCUMENT_FIFTH}
                        attachments={documents[DOCUMENT_FIFTH]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                    />                    
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_FIFTH} progressElements={progressElements}/>
                </Container>
            </>}
        </Container>
    );
};

Dokumentace17.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
};

export default Dokumentace17;
