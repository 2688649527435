import React, { useContext, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import PropTypes from 'prop-types';
import '../../css/dokumentace.css';
import { filterFiles } from '../../helperFunctions/helpers';
import FormContext from '../../formContexts/FormContext';
import { DOCUMENT_FIFTH } from '../../constants/sharedConstants';

const FileUpload = ({ 
    handleFileInput,
    category,
    setMissingFields,
    setIsExceeded,
    setIsEmpty,
    setIsWrongFormat,
    progressElements
}) => {
    const [hovered, setIsHovered] = useState(false);
    const fileInputRef = useRef(null);
    const { uploadedBytes } = useContext(FormContext);
 
    const handleDropOrInput = (files) => {
        const mappedFileList = filterFiles(files, category, uploadedBytes, progressElements ?? null);

        if (mappedFileList.length > 0) {
            handleFileInput(mappedFileList, category);
        }
    };

    const onTargetClick = () => {
        fileInputRef?.current.click();
    };
    
    const onFileInputChange = (e) => {
        const { files } = e.target;
        handleDropOrInput(files);
    };
    
    const handleOnDrop = (files) => {
        if (setMissingFields) {
            setMissingFields([]);
        }

        if (setIsExceeded) {
            setIsExceeded(false);
        }
        
        if (setIsEmpty) {
            setIsEmpty(false);
        }

        if (setIsWrongFormat) {
            setIsWrongFormat(false);
        }

        setIsHovered(false);
        handleDropOrInput(files);
    };
    // reset value in case same file is reuploaded. onChange doesn't catch it.
    const handleClick = (e) => {
        e.target.value = null;
    };

    return (
        <Row className='mt-3' >
            <FileDrop className={`dokumentace-upload-btn-container ${hovered ? 'hovered' : ''}`}
                onDrop={handleOnDrop}
                onDragOver={() => {setIsHovered(true);}}
                onDragLeave={() => {setIsHovered(false);}}
                onTargetClick={onTargetClick}
            >
                <Container className='d-flex flex-column align-items-center justify-content-center' onClick={(e) => e.stopPropagation()}>
                    <p className='dokumentace-upload-text gov-text--m pb-2'>Přetáhněte soubor nebo</p>
                    <label className='dokumentace-upload-btn' onClick={() => {
                        if (setMissingFields) {
                            setMissingFields([]); 
                        }
                        if (setIsExceeded) {
                            setIsExceeded(false);
                        }
                        if (setIsEmpty) {
                            setIsEmpty(false);
                        }
                        if (setIsWrongFormat) {
                            setIsWrongFormat(false);
                        }
                    }}>
                        Nahrát ze zařízení
                        <input                         
                            type='file' 
                            accept={category.startsWith('csv_import') ? '.csv' : category.startsWith('power_of_attorney') ? '.pdf, .dwg, .ifc, .png, .jpg, .jpeg, .json, .bim, .cad, .shp' : category === DOCUMENT_FIFTH ? '.pdf, .xml' : '.pdf' }
                            multiple={!category.startsWith('csv_import') && !category.startsWith('power_of_attorney')}
                            className='p-0 d-none hidden' 
                            name={category}
                            onChange={onFileInputChange}
                            onClick={handleClick}
                            ref={fileInputRef}
                        />  
                    </label>
                </Container>
            </FileDrop>
        </Row>
    );
};

FileUpload.propTypes = {
    handleFileInput: PropTypes.func,
    setMissingFields: PropTypes.func,
    category: PropTypes.string,
    setIsExceeded: PropTypes.func, 
    setIsEmpty: PropTypes.func, 
    setIsWrongFormat: PropTypes.func, 
    progressElements: PropTypes.arrayOf(Object), 
};

export default FileUpload;