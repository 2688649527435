import React from 'react';
import '../css/landingPage.css';
import { Container, Row, Col } from 'react-bootstrap';

const BottomSectionPrimary = () => {
    const mmrRedirect = () => window.location.href = 'https://chcidatovku.gov.cz/datova-schranka';

    return (
        <Container fluid id='scroll' className='pt-4 pb-4 p-md-5 landing-page-learn-more d-flex justify-content-center align-items-start'>
            <Row className='p-3 px-md-0 py-md-5 base-width d-flex align-items-center'>
                <Col xs={12} md={6} className='d-flex flex-column align-items-center align-items-md-start' style={{height: '100px'}}>
                    <p className='p-learn-more-first-bottom'>
                        Nemáš datovku fyzické osoby?
                    </p>
                    <p className='p-learn-more-second mb-4'>
                        Připravuješ se o fantastické výhody!
                    </p>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center align-items-md-end' style={{height: '60px'}}>
                    <button className='button-learn-more' onClick={mmrRedirect}>Zřídit datovou schránku</button>
                </Col>
            </Row>
        </Container>
    );
};

export default BottomSectionPrimary;