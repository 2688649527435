import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FoundConstructionInfo = ({state}) => {
    return (
        <>
            <Col className='construction-info-container my-2' lg={6}>
                <Row className='construction-info-block'>
                    <Col xs={5} className='modal-input-style'>
                        Katastrální území
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {`${state.construction.cadastralTerritory ?? ''} ${state.selectedConstruction?.cadastralTerritoryCode ?? ''}`}
                    </Col>
                </Row>
                <Row className='construction-info-block'>
                    <Col xs={5} className='modal-input-style'>
                        Parcelní číslo
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {state.construction.parcelNumber ?? ''}
                    </Col>
                </Row>
                <Row className='construction-info-block'>
                    <Col xs={5} className='modal-input-style'>
                        Číslo popisné
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {state.construction.constructionNumber ?? ''}
                    </Col>
                </Row>
                <Row className='construction-info-block'>
                    <Col xs={5} className='modal-input-style'>
                        Číslo evidenční
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {state.construction.registrationNumber ?? ''}
                    </Col>
                </Row>
                <Row className='construction-info-block'>
                    <Col xs={5} className='modal-input-style'>
                        Způsob využití
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {state.construction.constructionPurpose ?? ''}
                    </Col>
                </Row>
                <Row className='construction-info-block-padding-top'>
                    <Col xs={5} className='modal-input-style'>
                        Typ parcely
                    </Col>
                    <Col className='d-flex align-items-end construction-info-text' xs={7}>
                        {state?.construction?.parcelType ?? ''}
                    </Col>
                </Row>
            </Col>
        </>
    );
};

FoundConstructionInfo.propTypes = {
    state: PropTypes.object,
};

export default FoundConstructionInfo;