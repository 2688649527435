import React, {useState, useEffect, useContext} from 'react';
import '../../css/exception.css';
import { shouldNotSave, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const VyjimkaComponent02 = ({urlPath}) => {
    const {intention, stepValue, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            applicationExceptionProvisions: '',
            applicationExceptionDescription: '',
            applicationExceptionReason: '',
        }
    });
    const prevRequest = usePrevious();
    const firstRender = useFirstRender();
    const [moreInfo, setMoreInfo] = useState(false);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
        [1]: {count: 0, limit: 1000},
        [2]: {count: 0, limit: 1000},
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data.buildApplicationForms[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form, 
                        applicationExceptionProvisions: loadedData.applicationExceptionProvisions,
                        applicationExceptionDescription: loadedData.applicationExceptionDescription,
                        applicationExceptionReason: loadedData.applicationExceptionReason,
                    } }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    return (
        <Container className='d-flex flex-column' fluid>
            <Row className='d-flex flex-column flex-xl-row row-wrapper'>
                <h2 className='p-0 mb-2 main-title'>{`${stepValue}. Výjimka`}</h2>
                <p className='p-0 subtitle'>Specifikace požadované výjimky z požadavků na výstavbu a její odůvodnění</p>
            </Row>
            <Row className='d-flex flex-column flex-xl-row mb-5'>
                <p className='p-0 vyjimka-input-text mb-2'>Ustanovení prováděcího právního předpisu, ze kterého je povolení výjimky požadováno</p>
                <Row className='position-relative textarea-with-count-container'>
                    <textarea
                        className='textarea-with-count' 
                        name="postContent" 
                        onBlur={(e) => setRequest(state => ({...state, form: {...state.form, applicationExceptionProvisions: e.target.value}}))} 
                        defaultValue={request.form.applicationExceptionProvisions} 
                        rows={10}
                        cols={80}
                        onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}
                    />
                    <CharCount 
                        value={request.form.applicationExceptionProvisions} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                    />
                </Row>
            </Row>
            <Row className='d-flex flex-column flex-xl-row mb-5'>
                <p className='p-0 vyjimka-input-text mb-2'>Popis požadované výjimky</p>
                <Row className='position-relative textarea-with-count-container'>
                    <textarea
                        className='textarea-with-count' 
                        name="postContent"
                        onBlur={(e) => setRequest(state => ({...state, form: {...state.form, applicationExceptionDescription: e.target.value}}))} 
                        defaultValue={request.form.applicationExceptionDescription} 
                        rows={10}
                        cols={80}
                        onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}
                    />
                    <CharCount 
                        value={request.form.applicationExceptionDescription} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                    />
                </Row>
            </Row>
            <Row className='mb-3'>
                <Col md={6}>
                    <p className='vyjimka-input-text p-0'>
                        Odůvodnění požadované výjimky
                    </p>
                </Col>
                <Col md={6} className='d-flex justify-content-md-end'>
                    <p className='vyjimka-input-text-link p-0 pb-2' onClick={() => setMoreInfo(!moreInfo)}>
                        {moreInfo ? 'zabalit' : 'více informací'}
                    </p>
                </Col>
            </Row>
            {moreInfo && 
            <span className='p-0 mb-2 vyjimka-input-help-text'>
                Z odůvodnění musí být zřejmé, že řešení dle požadované výjimky neohrozí bezpečnost, zdraví ani život osob nebo zvířat, sousední pozemky nebo stavby, a že řešením podle povolené výjimky bude dosaženo účelu sledovaného požadavky na výstavbu
            </span>}
            <Row className='position-relative textarea-with-count-container'>
                <textarea
                    className='textarea-with-count' 
                    name="postContent" 
                    onBlur={(e) => setRequest(state => ({...state, form: {...state.form, applicationExceptionReason: e.target.value}}))} 
                    defaultValue={request.form.applicationExceptionReason}
                    rows={10} 
                    cols={80}
                    onChange={(e) => { checkLimitAndInsertText(e.target, 2, count, setCount); }}
                />
                <CharCount 
                    value={request.form.applicationExceptionReason} setCount={setCount} propToUpdate={2} count={count[2].count} limit={count[2].limit}
                />
            </Row>
        </Container>
    );
};

VyjimkaComponent02.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default VyjimkaComponent02;