import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { shouldNotSave, spreadObject } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender';
import { AUTHORITY_MODEL } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent01 = ({urlPath}) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            buildingAuthority: false,
            isAboutConditionLandUse: false,
            isAboutPermitAndApproval: false,
            isAboutAspectsInsurancePermission: false,
            isAboutAuthority: false,
            affectedOrg: false,
            orgAboutPermitAndApproval: false,
            orgCriteriaAndConditions: false,
        },
        buildIntention: {
            recipientAuthority: AUTHORITY_MODEL
        }
    });
    const prevRequest= usePrevious(request);
    const firstRender = useFirstRender();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.buildApplicationForms?.[0];

                    if (!loadedData) {
                        setRequest(state => ({ ...state, form: {...state.form, buildingAuthority: true, affectedOrg: false} }));
                        return;
                    }

                    if (loadedData?.buildingAuthority === null && loadedData?.affectedOrg === null) {
                        setRequest(state => ({ ...state, form: {...state.form, buildingAuthority: true, affectedOrg: false} }));
                    } else {
                        const intention = response.data?.buildIntention;
                        const recipientAuthority = intention?.recipientAuthority;
                        setRequest(state => ({ ...state, form: {...state.form,
                            buildingAuthority: loadedData?.buildingAuthority,
                            isAboutConditionLandUse: loadedData?.isAboutConditionLandUse,
                            isAboutPermitAndApproval: loadedData?.isAboutPermitAndApproval,
                            isAboutAspectsInsurancePermission: loadedData?.isAboutAspectsInsurancePermission,
                            isAboutAuthority: loadedData?.isAboutAuthority,
                            affectedOrg: loadedData?.affectedOrg,
                            orgAboutPermitAndApproval: loadedData?.orgAboutPermitAndApproval,
                            orgCriteriaAndConditions: loadedData?.orgCriteriaAndConditions,
                        },
                        buildIntention: {...state.buildIntention, ...spreadObject(intention), recipientAuthority: recipientAuthority } }));
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form, request.buildIntention.recipientAuthority]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request.form.buildingAuthority && !request.form.affectedOrg)
            {
                err = true;
            }
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='pb-4'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>
            <Row className='pb-3'>
                <Row className='py-4'>
                    <p className='p-0 title secondary'>Žádám</p>
                </Row>
                <Row className='py-2'>
                    <Col className='d-flex row'>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="radio"
                                name='zadost'
                                value={request.form.buildingAuthority}
                                checked={request.form.buildingAuthority ?? false}
                                onChange={(e) => 
                                    setRequest(state => ({ ...state, form: { 
                                        ...state.form, 
                                        buildingAuthority: e.target.checked, 
                                        affectedOrg: false,
                                        orgAboutPermitAndApproval: false,
                                        orgCriteriaAndConditions: false,
                                    }, buildIntention: {
                                        ...state.buildIntention,
                                        recipientAuthority: AUTHORITY_MODEL
                                    },
                                    }))
                                }
                            />
                                stavební úřad
                        </label>
                        <Row className='pb-4'>
                            <Col md={6} className='pb-2'>
                                <label className='ls-1p25 p-0 d-flex align-items-start'>
                                    <input
                                        className='me-3'
                                        type="radio" 
                                        name='zadost'
                                        value={request.form.affectedOrg }
                                        checked={request.form.affectedOrg ?? false}
                                        onChange={(e) => 
                                            setRequest(state => ({ ...state, form: { 
                                                ...state.form, 
                                                buildingAuthority: false, 
                                                affectedOrg: e.target.checked,
                                                isAboutConditionLandUse: false, 
                                                isAboutPermitAndApproval: false, 
                                                isAboutAspectsInsurancePermission: false, 
                                                isAboutAuthority: false,
                                            } , buildIntention: {
                                                ...state.buildIntention,
                                                recipientAuthority: AUTHORITY_MODEL
                                            },
                                            }))
                                        }
                                    />
                                        dotčený orgán
                                </label>
                            </Col>
                            {error && !request.form.affectedOrg && !request.form.buildingAuthority && <Col xs={{ span: 12, order: 2 }} className='mt-1'>
                                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vybrání pole není možné pokračovat.</p>
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </Row>
            {
                (request.form.buildingAuthority
                ||
                request.form.affectedOrg)
                &&
                <Row className='py-4'>
                    <p className='p-0 title third'>O předběžnou informaci</p>
                </Row>
            }
            <Row className='my-2 d-flex flex-column'>
                {
                    request.form.buildingAuthority
                    &&
                    <>
                        <Col>
                            <label className={'ls-1p25 p-0 mb-2 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.isAboutConditionLandUse ?? false}
                                    onChange={(e) => { 
                                        setRequest(state => ({ ...state, form: { ...state.form, isAboutConditionLandUse: e.target.checked } }));
                                    }}
                                />
                                    o podmínkách využívání území a změn jeho využití, zejména na základě územně plánovacích podkladů a územně plánovací dokumentace    
                            </label>
                        </Col>
                        <Col>
                            <label className={'ls-1p25 p-0 mb-2 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.isAboutPermitAndApproval ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({ ...state, form: { ...state.form, isAboutPermitAndApproval: e.target.checked } }));
                                    }}
                                />
                                    o nezbytnosti povolení záměru a jeho kolaudace
                            </label>
                        </Col>
                        <Col>
                            <label className={'ls-1p25 p-0 mb-2 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.isAboutAspectsInsurancePermission ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({ ...state, form: { ...state.form, isAboutAspectsInsurancePermission: e.target.checked } }));
                                    }}
                                />
                                    o tom, podle jakých hledisek bude posuzovat žádost o vydání rozhodnutí o povolení záměru a za jakých předpokladů lze žádosti vyhovět
                            </label>
                        </Col>
                        <Col>
                            <label className={'ls-1p25 p-0 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.isAboutAuthority ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({ ...state, form: { ...state.form, isAboutAuthority: e.target.checked } }));
                                    }}
                                />
                                    o dotčených orgánech ve vztahu k záměru
                            </label>
                        </Col>
                    </>
                }
                {
                    request.form.affectedOrg
                    &&
                    <>
                        <Col>
                            <label className={'ls-1p25 p-0 mb-2 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.orgAboutPermitAndApproval ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({ ...state, form: { ...state.form, orgAboutPermitAndApproval: e.target.checked } }));
                                    }}
                                />
                                    o nezbytnosti vyjádření nebo závazného stanoviska dotčeného orgánu
                            </label>
                        </Col>
                        <Col>
                            <label className={'ls-1p25 p-0 mb-2 d-flex align-items-start'}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={request.form.orgCriteriaAndConditions ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({ ...state, form: { ...state.form, orgCriteriaAndConditions: e.target.checked } }));
                                    }}
                                />
                                    o tom, podle jakých hledisek bude posuzovat žádost o vydání vyjádření nebo závazného stanoviska a za jakých předpokladů lze žádosti vyhovět
                            </label>
                        </Col>
                    </>
                }
            </Row>
        </Container>
    );
};

RozsahComponent01.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent01;