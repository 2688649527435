import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NewRequestCardHeader, NovaZadostFilter } from '../index';
import { 
    arrowUpBlue, 
    arrowDownBlue,
    img01DO,
    img01SU,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img19,
} from '../../assets/index';
import '../../css/novaZadost.css';

const NEW_REQUEST_HEADER = {
    mostUsed: [
        {
            title: 'Žádost o předběžnou informaci stavebního úřadu',
            subtitle: 'Tento formulář žádosti umožňuje požádat stavební úřad o předběžnou informaci:',
            info: ['o podmínkách využívání území a změn jeho využití', 'o nezbytnosti povolení záměru a jeho kolaudace', 'o tom, podle jakých hledisek bude stavební úřad žádost o vydání rozhodnutí o povolení záměru posuzovat a za jakých předpokladů lze žádosti vyhovět', 'o dotčených orgánech ve vztahu k záměru'],
            category: '01',
            link: '/zadost/form01',
            img: img01SU,
        },
        {
            title: 'Žádost o předběžnou informaci dotčeného orgánu',
            subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán o předběžnou informaci:',
            info: ['o nezbytnosti vyjádření nebo závazného stanoviska dotčeného orgánu', 'o hlediscích, které budou posuzovány v žádosti o vydání vyjádření nebo závazného stanoviska a za jakých předpokladů lze žádosti vyhovět'],
            category: 'VZOR',
            link: '/zadost/form01',
            img: img01DO,
        },
        {
            title: 'Žádost o vyjádření nebo závazné stanovisko dotčeného orgánu',
            subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán:',
            info: ['o koordinované vyjádření nebo koordinované závazné stanovisko, včetně jednotného environmentálního stanoviska, jehož součástí není stanovisko k posouzení vlivu záměru na životní prostředí (EIA)', 'o jednotné environmentální stanovisko, jehož součástí není stanovisko EIA', 'o jednotné environmentální stanovisko, včetně stanoviska EIA', 'o vyjádření nebo závazné stanovisko z hlediska např. ochrany vod, životního prostředí, ochrany veřejného zdraví, dopravy, památkové péče, požární ochrany atd'],
            category: 'VZOR',
            link: '/zadost/form02',
            img: img02,
        },
        {
            title: 'Žádost o povolení stavby nebo zařízení',
            subtitle: 'Tento formulář žádosti umožňuje požádat o povolení stavby nebo zařízení ve standardním řízení o povolení stavby nebo ve zrychleném řízení o povolení stavby. Součástí této žádosti může být i žádost',
            info: ['o výjimku z požadavků na výstavbu anebo žádost o změnu nebo', 'o zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření.'],
            subtitle1: 'Pokud bude současně se stavbou prováděn i záměr nestavební povahy, umožňuje tento formulář požádat zároveň o:',
            info1: ['změnu využití území', 'dělení nebo scelení pozemků', 'stanovení ochranného pásma, pokud se to netýká blíže neurčeného okruhu osob nebo se nejedná o stavbu vodního díla'],
            category: '03',
            link: '/zadost/form04',
            img: img04,
        },
        // {
        //     title: 'Žádost o povolení dělení nebo scelení pozemků',
        //     subtitle: 'Tento formulář žádosti se využije v případě, kdy má být velký pozemek rozdělován na menší pozemky, nebo v případě, kdy má dojít ke scelování více pozemků do jednoho pozemku. Žádost musí být podána všemi vlastníky dotčených pozemků, které jsou předmětem povolení, nebo alespoň jedním z vlastníků s doložením souhlasu ostatních vlastníků dotčených pozemků. V případě veřejně prospěšné stavby nebo veřejně prospěšného opatření může žádost podat i stavebník.',
        //     info: [],
        //     category: '06',
        //     link: '/zadost/form07',
        //     img: img07,
        // },
        {
            title: 'Doplnění žádosti či odstranění vad žádosti',
            subtitle: 'Tento formulář slouží k doplnění již podané žádosti nebo k odstranění vad podané žádosti (na základě výzvy stavebního úřadu či dotčeného orgánu).',
            info: [],
            category: 'VZOR',
            link: '/zadost/form17',
            img: img17,
        },
        {
            // TODO change category & image when it will be available
            title: 'Oznámení o obnově stavby nebo terénní úpravy',
            subtitle: 'Tento formulář slouží k oznámení obnovy stavby nebo terénních úprav na základě mimořádné události (např. povodeň, tornádo, sesuv půdy atp.).',
            info: [],
            category: '19',
            link: '/zadost/form19',
            img: img19,
        },
    ],
    preparation: [
        {
            title: 'Žádost o předběžnou informaci stavebního úřadu',
            subtitle: 'Tento formulář žádosti umožňuje požádat stavební úřad o předběžnou informaci:',
            info: ['o podmínkách využívání území a změn jeho využití', 'o nezbytnosti povolení záměru a jeho kolaudace', 'o tom, podle jakých hledisek bude stavební úřad žádost o vydání rozhodnutí o povolení záměru posuzovat a za jakých předpokladů lze žádosti vyhovět', 'o dotčených orgánech ve vztahu k záměru'],
            category: '01',
            link: '/zadost/form01',
            img: img01SU,
        },
        {
            title: 'Žádost o předběžnou informaci dotčeného orgánu',
            subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán o předběžnou informaci:',
            info: ['o nezbytnosti vyjádření nebo závazného stanoviska dotčeného orgánu', 'o hlediscích, které budou posuzovány v žádosti o vydání vyjádření nebo závazného stanoviska a za jakých předpokladů lze žádosti vyhovět'],
            category: 'VZOR',
            link: '/zadost/form01',
            img: img01DO,
        },
        {
            title: 'Žádost o vyjádření nebo závazné stanovisko dotčeného orgánu',
            subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán:',
            info: ['o koordinované vyjádření nebo koordinované závazné stanovisko, včetně jednotného environmentálního stanoviska, jehož součástí není stanovisko k posouzení vlivu záměru na životní prostředí (EIA)', 'o jednotné environmentální stanovisko, jehož součástí není stanovisko EIA', 'o jednotné environmentální stanovisko, včetně stanoviska EIA', 'o vyjádření nebo závazné stanovisko z hlediska např. ochrany vod, životního prostředí, ochrany veřejného zdraví, dopravy, památkové péče, požární ochrany atd'],
            category: 'VZOR',
            link: '/zadost/form02',
            img: img02,
        },
        {
            title: 'Žádost o vyjádření vlastníka veřejné dopravní nebo technické infrastruktury',
            subtitle: 'Vyjádření vlastníka veřejné dopravní nebo technické infrastruktury z hlediska možnosti a způsobu napojených záměrů nebo k podmínkám dotčených ochranných a bezpečnostních pásem lze získat prostřednictvím portálů jednotlivých vlastníků veřejné dopravní nebo technické infrastruktury. Seznam správců veřejné dopravní a technické infrastruktury pro Váš záměr můžete získat na Portálu DMVS.',
            info: [],
            category: '02',
            link: '/zadost/form03',
            img: img03,
        },
        {
            title: 'Návrh na stanovení ochranného pásma',
            subtitle: 'Tento formulář slouží k podání návrhu na stanovení ochranného pásma stavby před negativními účinky okolí. Je určen zejména pro ty případy, kdy ochranné pásmo musí být stanoveno opatřením obecné povahy, tzn. že je nezbytné stanovit ochranné pásmo, jež se týká blíže neurčeného okruhu osob, nebo se jedná o ochranné pásmo vodního díla.',
            info: [],
            category: '09',
            link: '/zadost/form10',
            img: img10,
        },
    ],
    permissions: [
        {
            title: 'Žádost o povolení stavby nebo zařízení',
            subtitle: 'Tento formulář žádosti umožňuje požádat o povolení stavby nebo zařízení ve standardním řízení o povolení stavby nebo ve zrychleném řízení o povolení stavby. Součástí této žádosti může být i žádost',
            info: ['o výjimku z požadavků na výstavbu anebo žádost o změnu nebo', 'o zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření.'],
            subtitle1: 'Pokud bude současně se stavbou prováděn i záměr nestavební povahy, umožňuje tento formulář požádat zároveň o:',
            info1: ['změnu využití území', 'dělení nebo scelení pozemků', 'stanovení ochranného pásma, pokud se to netýká blíže neurčeného okruhu osob nebo se nejedná o stavbu vodního díla'],
            category: '03',
            link: '/zadost/form04',
            img: img04,
        },
        {
            title: 'Žádost o změnu povolení',
            subtitle: 'Tento formulář žádosti lze využít v situaci, kdy je třeba změnit:',
            info: ['dobu platnosti povolení', 'lhůtu pro dokončení záměru', 'podmínky stanovené v původním rozhodnutí'],
            subtitle1: 'Tento formulář žádosti však nelze použít v případech, kdy je nutné provést úpravu v ověřené dokumentaci k povolenému záměru.',
            category: '04',
            link: '/zadost/form05',
            img: img05,
        },
        {
            title: 'Žádost o vydání rámcového povolení',
            subtitle: 'Formulář žádosti o rámcové povolení je možné využít:',
            info: ['pro stavby jaderného zařízení a stavby související, které se nacházejí uvnitř i vně areálu jaderného zařízení', 'v případě záměrů, u kterých je následně jejich povolení v působnosti jiného stavebního úřadu, to znamená Ministerstva obrany, Ministerstva vnitra a Ministerstva spravedlnosti'],
            category: '07',
            link: '/zadost/form08',
            img: img08,
        },
        {
            title: 'Žádost o dodatečné povolení stavby, zařízení a terénních úprav',
            subtitle: 'Formulář této žádosti umožňuje požádat o dodatečné povolení stavby, zařízení a terénních úprav v případech, kdy dodatečné povolení stavební zákon umožňuje.',
            info: [],
            category: '15',
            link: '/zadost/form16',
            img: img16,
        },
    ],
    changesAndCancellations: [
        {
            title: 'Žádost o změnu záměru před dokončením',
            subtitle: 'Tento formulář žádosti slouží k žádosti o změnu záměru již povoleného před jeho dokončením. Taková změna předpokládá zásah do ověřené dokumentace k povolenému záměru. Součástí žádosti o změnu záměru před dokončením může být i žádost o výjimku z požadavků na výstavbu. Součástí záměru může být',
            info: ['změna využití území', 'dělení nebo scelení pozemků a', 'stanovení ochranného pásma, pokud se to netýká blíže neurčeného okruhu osob nebo se nejedná o stavbu vodního díla'],
            category: '08',
            link: '/zadost/form09',
            img: img09,
        },
        {
            title: 'Žádost o povolení změny v užívání stavby',
            subtitle: 'Tento formulář žádosti umožňuje požádat o povolení změny v užívání stavby, přičemž požadovaná změna v užívání stavby může spočívat ve změně:',
            info: ['způsobu užívání stavby', 'provozního zařízení stavby', 'způsobu výroby nebo podstatném rozšíření výroby', 'činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí', 'doby trvání dočasné stavby', 'dočasné stavby na stavbu trvalou'],
            category: '13',
            link: '/zadost/form14',
            img: img14,
        },
        {
            title: 'Žádost o povolení odstranění stavby, zařízení a terénních úprav',
            subtitle: 'Prostřednictvím tohoto formuláře žádosti lze požádat o povolení odstranění stavby, zařízení a terénních úprav. Ve formuláři je nezbytné uvést i návrh úpravy pozemku po jejím odstranění.',
            info: [],
            category: '14',
            link: '/zadost/form15',
            img: img15,
        },
    ],
    additionalConstructions: [
        {
            title: 'Žádost o vydání kolaudačního rozhodnutí',
            subtitle: 'Tento formulář žádosti umožňuje požádat o vydání kolaudačního rozhodnutí, a to jak pro jednu stavbu, tak i pro soubor staveb. V žádosti lze zároveň uvést také skutečnost, že byla stavba provedena s nepodstatnými odchylkami od ověřené projektové dokumentace.',
            info: [],
            category: '10',
            link: '/zadost/form11',
            img: img11,
        },
        {
            title: 'Žádost o povolení předčasného užívání stavby',
            subtitle: 'Prostřednictvím tohoto formuláře žádosti lze požádat o povolení předčasného užívání stavby nebo její části. V žádosti se zároveň uvede návrh podmínek předčasného užívání stavby prováděné svépomocí. V případě stavby prováděné dodavatelsky, se přikládá dohoda se stavebním podnikatelem, která obsahuje sjednané podmínky předčasného užívání stavby.',
            info: [],
            category: '11',
            link: '/zadost/form12',
            img: img12,
        },
        {
            title: 'Žádost o povolení zkušebního provozu',
            subtitle: 'Na tomto formuláři žádosti lze požádat o povolení zkušebního provozu stavby. V žádosti je nezbytné zkušební provoz odůvodnit, to znamená řádně vysvětlit k ověření jakých vlastností provedené stavby nebo funkčnosti technologického zařízení má zkušební provoz sloužit.',
            info: [],
            category: '12',
            link: '/zadost/form13',
            img: img13,
        },
    ],
    spaceChanges: [
        {
            title: 'Žádost o povolení změny využití území',
            subtitle: 'Tento formulář žádosti umožňuje požádat o změnu využití území spočívající v realizaci:',
            info: ['terénní úpravy', 'stanovení prostoru pro dobývání ložisek nerostů, pro která se nestanoví dobývací prostor podle horního zákona', 'odstavné, manipulační, prodejní, skladové nebo výstavní plochy', 'pohřebiště', 'změny druhu pozemku nebo způsobu využití pozemku, pokud podmínky nejsou stanoveny schválenými pozemkovými úpravami', 'úpravy pozemku, která má vliv na schopnost vsakování vody'],
            subtitle1: 'O změnu využití území je možné požádat v řízení o povolení změny využití území anebo ve zrychleném řízení o povolení změny využití území.',
            category: '05',
            link: '/zadost/form06',
            img: img06,
        },
        {
            title: 'Žádost o povolení dělení nebo scelení pozemků',
            subtitle: 'Tento formulář žádosti se využije v případě, kdy má být velký pozemek rozdělován na menší pozemky, nebo v případě, kdy má dojít ke scelování více pozemků do jednoho pozemku. Žádost musí být podána všemi vlastníky dotčených pozemků, které jsou předmětem povolení, nebo alespoň jedním z vlastníků s doložením souhlasu ostatních vlastníků dotčených pozemků. V případě veřejně prospěšné stavby nebo veřejně prospěšného opatření může žádost podat i stavebník.',
            info: [],
            category: '06',
            link: '/zadost/form07',
            img: img07,
        },
    ],
};

const NovaZadostMainSection = () => {
    const [inputValue, setInputValue] = useState('');
    const [nejcastejsiIsOpen, setNejcastejsiIsOpen] = useState(true);
    const [pripravaIsOpen, setPripravaIsOpen] = useState(false);
    const [povoleniIsOpen, setPovoleniIsOpen] = useState(false);
    const [zruseniIsOpen, setZruseniIsOpen] = useState(false);
    const [dokonceniIsOpen, setDokonceniIsOpen] = useState(false);
    const [zmenyIsOpen, setZmenyIsOpen] = useState(false);

    //match array of strings to array of substrings
    const matchData = (subString, data) => {
        if (subString === '') { 
            return true;
        } else {
            return [data.title, ...data.info].some(str => 
                subString.split(' ').some(sub => 
                    str.toLowerCase().includes(sub.toLowerCase())
                )
            );
        }
    };

    return (
        <Container className='base-width px-2 py-4 px-md-5 px-xl-0' fluid>
            <Row className='mb-2'>
                <NovaZadostFilter inputValue={inputValue} setInputValue={setInputValue} />
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setNejcastejsiIsOpen(!nejcastejsiIsOpen)}>
                    <p className='nova_zadost-title'>Nejčastější</p>
                    <img src={nejcastejsiIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(nejcastejsiIsOpen || inputValue !== '') && <Col className='d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.mostUsed.filter(function (mostUsed) {
                        if (matchData(inputValue, mostUsed) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setPripravaIsOpen(!pripravaIsOpen)}>
                    <p className='nova_zadost-title'>Příprava stavby</p>
                    <img src={pripravaIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(pripravaIsOpen || inputValue !== '') && <Col className='pt-3 d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.preparation.filter(function (preparation) {
                        if (matchData(inputValue, preparation) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setPovoleniIsOpen(!povoleniIsOpen)}>
                    <p className='nova_zadost-title'>Povolení stavby</p>
                    <img src={povoleniIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(povoleniIsOpen  || inputValue !== '') && <Col className='pt-3 d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.permissions.filter(function (permissions) {
                        if (matchData(inputValue, permissions) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setZruseniIsOpen(!zruseniIsOpen)}>
                    <p className='nova_zadost-title'>Změny a zrušení stavby a záměru</p>
                    <img src={zruseniIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(zruseniIsOpen  || inputValue !== '') && <Col className='pt-3 d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.changesAndCancellations.filter(function (changesAndCancellations) {
                        if (matchData(inputValue, changesAndCancellations) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setDokonceniIsOpen(!dokonceniIsOpen)}>
                    <p className='nova_zadost-title'>Dokončení stavby</p>
                    <img src={dokonceniIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(dokonceniIsOpen  || inputValue !== '') && <Col className='pt-3 d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.additionalConstructions.filter(function (additionalConstructions) {
                        if (matchData(inputValue, additionalConstructions) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
            <Row className='d-flex flex-column border-bottom'>
                <Col className='d-flex justify-content-between align-items-center py-3 nova_zadost-switcher' onClick={() => setZmenyIsOpen(!zmenyIsOpen)}>
                    <p className='nova_zadost-title'>Změny pozemků a území staveb</p>
                    <img src={zmenyIsOpen === true ? arrowUpBlue : arrowDownBlue} alt='arrowDown' className='me-1' />
                </Col>
                {(zmenyIsOpen  || inputValue !== '') && <Col className='pt-3 d-flex flex-wrap'>
                    {NEW_REQUEST_HEADER.spaceChanges.filter(function (spaceChanges) {
                        if (matchData(inputValue, spaceChanges) === true) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={item.title + index}
                                className='d-flex justify-content-center align-items-center px-3 py-1'>
                                {(<NewRequestCardHeader item={item} />)}
                            </Col>
                        );
                    })}
                </Col>}
            </Row>
        </Container>
    );
};

export default NovaZadostMainSection;