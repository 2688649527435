const BASIC_INFO_PROJEKTANT =  [
    {
        id: 1,
        label: 'Titul před jménem (nepovinné)',
        type: 'text',
        refer: 'basicInfo-title-before',
        required: false,
        state: 'titleBefore',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 2,
        label: 'Titul za jménem (nepovinné)',
        type: 'text',
        refer: 'basicInfo-title-after',
        required: false,
        state: 'titleAfter',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 3,
        label: 'Jméno',
        type: 'text',
        refer: 'basicInfo-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'firstName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 4,
        label: 'Příjmení',
        type: 'text',
        refer: 'basicInfo-surname',
        required: true,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 5,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'basicInfo-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 6,
        label: 'Cislo v evidenci autorizovanych osob',
        type: 'text',
        refer: 'basicInfo-ics',
        required: true,
        state: 'ais',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
    {
        id: 7,
        label: 'Obor (nepovinné)',
        type: 'text',
        refer: 'basicInfo-field',
        required: false,
        state: 'field',
        object: 'accompanyingDocument',
        propertyToUpdate: 'mainDesigner'
    },
];

const BASIC_INFO_ENGINEER =  [
    {
        id: 1,
        label: 'Titul před jménem (nepovinné)',
        type: 'text',
        refer: 'basicInfo-title-before',
        required: false,
        state: 'titleBefore',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 2,
        label: 'Titul za jménem (nepovinné)',
        type: 'text',
        refer: 'basicInfo-title-after',
        required: false,
        state: 'titleAfter',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 3,
        label: 'Jméno (nepovinné)',
        type: 'text',
        refer: 'basicInfo-name',
        info: 'Uveďte všechna jména',
        required: false,
        state: 'firstName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 4,
        label: 'Příjmení (nepovinné)',
        type: 'text',
        refer: 'basicInfo-surname',
        required: false,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 5,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'basicInfo-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 6,
        label: 'Cislo v evidenci autorizovanych osob (nepovinné)',
        type: 'text',
        refer: 'basicInfo-ics',
        required: false,
        state: 'ais',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
    {
        id: 7,
        label: 'Obor (nepovinné)',
        type: 'text',
        refer: 'basicInfo-field',
        required: false,
        state: 'field',
        object: 'accompanyingDocument',
        propertyToUpdate: 'engineer'
    },
];

export {
    BASIC_INFO_PROJEKTANT,
    BASIC_INFO_ENGINEER,
};