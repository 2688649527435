import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PARCEL_TYPE_LAND } from '../constants/sharedConstants';

const FoundParcelInfo = ({state, helper}) => {
    const canShowType = ((!(state?.parcel?.parcelType?.trim() === PARCEL_TYPE_LAND) || (helper !== undefined && helper !== 'noNum')) && helper !== 'novaStavba');
    
    return (
        <Col className={`${helper !== undefined ? 'mt-4': ''}`} style={{ backgroundColor: '#FFF', padding: '1.5rem', border: 'solid #DDDDDD 1px', borderRadius: '3px' }} lg={6}>
            <>
                <Row style={{ borderBottom: 'solid #DDD 1px', padding: '.25rem' }}>
                    <Col xs={5} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                    Druh pozemku dle katastru
                    </Col>
                    <Col className='d-flex align-items-end' xs={7} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#3B3B3B', paddingLeft: '1rem' }}>
                        {state?.parcel?.type}
                    </Col>
                </Row>
                <Row style={{ borderBottom: canShowType ? 'solid #DDD 1px' : 'none', padding: '.25rem' }}>
                    <Col xs={5} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                    Výměra (m²)
                    </Col>
                    <Col xs={7} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#3B3B3B', paddingLeft: '1rem' }}>
                        {state?.parcel?.area}
                    </Col>
                </Row>
                {canShowType && <Row style={{ padding: '.25rem' }}>
                    <Col xs={5} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                    Typ parcely
                    </Col>
                    <Col xs={7} style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#3B3B3B', paddingLeft: '1rem' }}>
                        {state?.parcel?.parcelType}
                    </Col>
                </Row>}
            </>
        </Col>
    );
};

FoundParcelInfo.propTypes = {
    state: PropTypes.object,
    helper: PropTypes.string,
};

export default FoundParcelInfo;