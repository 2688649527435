import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form14MainSection, FormFinisher, ProcessError } from '../components';
import { useGlobalState } from '../constants/states';
import { getTotalValueFromUploads, isNotUploading, trackUploads, getDocumentsToTrack, logError } from '../helperFunctions/helpers';
import { items } from '../constants/form14';
import { FORM_14_INTENTION, DOCUMENT_FOURTH } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';

const Form14 = () => {
    const [state, setState] = useGlobalState();
    const [progressElements, setProgressElements] = useState([]);
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [ fetch, setFetch ] = useState(false);
    const [documents, setDocuments] = useState({
        [DOCUMENT_FOURTH]: [],
    });
    const dokumentaceProgressElements = [...(progressElements || [])].filter(el => el.abortController);
    const currentlyUploading = dokumentaceProgressElements.filter(el => (!el.success && !el.message) && (el.progress > 0 && el.progress < 100));
    const averageProgress = currentlyUploading.length > 0 ? Math.round(getTotalValueFromUploads(currentlyUploading) / currentlyUploading.length) : 0;
    const documentsToTrack = getDocumentsToTrack(documents);
    
    useEffect(() => {
        if (documentsToTrack.length === 0) {
            return;
        }
            
        trackUploads(setProgressElements, documentsToTrack);
    }, [documentsToTrack.length]);

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(progressElements)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [progressElements]);

    const { stage } = state;

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        return () => {
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={id} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_14_INTENTION.title} id={id} linkRef={'form14'}
                    setStage={setStage} setIsFinished={setIsFinished} /> :
                    <>
                        <FormContext.Provider
                            value={{
                                intention: FORM_14_INTENTION.title,
                                stepValue: stage,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                validationRef: validationFunctionRef,
                                setStagesArr,
                                stagesArr,
                                saveFormRef,
                                id,
                                setId,
                                fetch,
                                setFetch
                            }}
                        >
                            <FormHeader averageProgress={averageProgress} currentlyUploading={currentlyUploading} isMobileView={isMobileView} header={FORM_14_INTENTION} />
                            <Form14MainSection documents={documents} setDocuments={setDocuments} isMobileView={isMobileView} stage={stage} progressElements={progressElements} setProgressElements={setProgressElements} />
                            <FormFooter currentlyUploading={currentlyUploading} averageProgress={averageProgress} setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'14'} setIsFinished={setIsFinished} />
                        </FormContext.Provider>
                    </>}
            </Container>
        </ErrorBoundary>
    );
};

export default Form14;