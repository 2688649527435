import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useRef, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form18MainSection, FormFinisherProj, ProcessError } from '../components';
import { useGlobalState } from '../constants/states';
import { isNotUploading, logError } from '../helperFunctions/helpers';
import { items } from '../constants/form18';
import { FORM_18_INTENTION } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';

const Form18 = () => {
    const [state, setState] = useGlobalState();
    const [progressElements, setProgressElements] = useState([]);
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [token, setToken] = useState('');
    const [stagesArr, setStagesArr] = useState(items);
    const buttonRef = useRef([]);

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(progressElements)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [progressElements]);

    const { stage } = state;

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        return () => {
            setStage(1);
        };
    }, []);

    const handleToken = (tkn) => {
        if (!tkn) {
            return;
        }
        setToken(tkn);
    };

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={id} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                {isFinished ? <FormFinisherProj isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_18_INTENTION.title} id={id} linkRef={'form18'}
                    setStage={setStage} setIsFinished={setIsFinished} /> :
                    <>
                        <FormContext.Provider
                            value={{
                                intention: FORM_18_INTENTION.title,
                                stepValue: stage,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                validationRef: validationFunctionRef,
                                setStagesArr,
                                stagesArr,
                                saveFormRef,
                                id,
                                setId,
                            }}
                        >
                            <FormHeader isMobileView={isMobileView} header={FORM_18_INTENTION} />
                            <Form18MainSection buttonRef={buttonRef} isMobileView={isMobileView} handleToken={handleToken} stage={stage} progressElements={progressElements} setProgressElements={setProgressElements} />
                            <FormFooter buttonRef={buttonRef} receivedToken={token} setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'18'} setIsFinished={setIsFinished} />
                        </FormContext.Provider>
                    </>}
            </Container>
        </ErrorBoundary>
    );
};

export default Form18;