import React, { useEffect, useState } from 'react';
import { Container, Row, Col, /* Spinner */ } from 'react-bootstrap';
import { finisherIcon, file, addFileWhite } from '../../assets';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/formFinisher.css';
import { GovButton } from '@gov-design-system-ce/react';

const FormFinisher = ({ header, id, isSuccessOrFailSubmission, linkRef, setStage, setIsFinished }) => {
    const [dateAndtime, setDateAndTime] = useState({
        dateFinal: '',
        timeFinal: ''
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSuccessOrFailSubmission) {
            return;
        }

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
        // set time to current time in forma hh:mm
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const formattedTime = `${hours}:${minutes}`;

        setDateAndTime(prev => ({ ...prev, timeFinal: formattedTime, dateFinal: formattedDate }));
    }, [isSuccessOrFailSubmission]);

    const handleNavigate = () => {
        console.log('TRIGGER BACK');
        navigate(`/zadost/${linkRef}/${id}`, { state: { from: location } });
        if (linkRef === 'form18') {
            setStage(1);
        } else {
            setStage(2);
        }
        setIsFinished(false);
    };  

    return (
        <Container className='d-flex flex-column justify-content-center align-items-center text-center m-5' fluid>
            {isSuccessOrFailSubmission &&
                <Row className='m-4'>
                    <Col><img src={finisherIcon} style={{ width: '80px', height: '80px' }} /></Col>
                </Row>}

            {/* {isSuccessOrFailSubmission === null && <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>} */}
            {isSuccessOrFailSubmission === null && <Row className='row-wrapper'>
                <Col className='loader'>
                </Col>
            </Row>}

            <Row className='text-center'>
                <Col><p style={{ 'fontSize': '23px' }}>{header}</p></Col>
            </Row>

            {isSuccessOrFailSubmission && <>
                <Row className='my-4'>
                    <Col><p style={{ 'fontSize': '32px' }}>Úspěšně podána</p></Col>
                </Row>
                <Row className='row-wrapper'>
                    <Col><p>{dateAndtime.dateFinal} v {dateAndtime.timeFinal} s PID {id}</p></Col>
                </Row>
                <Row className='row-wrapper'>
                    <Col>
                        <p className='confirmation_text'>Potvrzení podání a možnost stažení duplikátu žádosti najdete na stránce “Žádosti”.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GovButton
                            variant="primary"
                            type="solid" 
                            size="m"
                            onGov-click={() => navigate('/zadosti')}
                        >
                            Moje žádosti
                        </GovButton>
                    </Col>
                </Row>
            </>}
            {isSuccessOrFailSubmission === null ?
                <Row>
                    <Col>
                        <p className='row-wrapper submissionInProgress' style={{marginTop: '40px'}}>Probíhá podání</p>
                        <p className='help_text'>Probíhá podání žádosti, nezavírejte prohlížeč. </p>
                    </Col>
                </Row>
                : isSuccessOrFailSubmission ?
                    <Row className='m-5'>
                        <Col className='mb-5 text-center'>
                            <button className='button-more-info m-2 mx-4b d-none' style={{ height: '48px', width: '276px' }}><img src={file} />&nbsp;Stáhnout žádost</button>
                            <button className='button-fill-in d-none' style={{ height: '48px', width: '276px' }}><img src={addFileWhite} />&nbsp;Stáhnout žádost</button>
                        </Col>
                    </Row>
                    :
                    <Row className='m-5'>
                        <Col className='fst-italic text-center'>

                            <p>
                                Žádost nelze odeslat, přihlášený uživatel nebyl nalezen mezi účastníky řízení <br />
                                Opravte údaje a odešlete žádost znovu.
                            </p>
                        </Col>
                        <Row className='m-5'>
                            <Col className='mb-5 text-center'>
                                <button type='button' className='button-fill-in mr-5' style={{ height: '48px', width: '276px', marginRight: '100px', fontSize: '15px' }} onClick={handleNavigate}>Vrátit se zpět na formulář</button>
                            </Col>
                        </Row>
                    </Row>
            }
        </Container>
    );
};

FormFinisher.propTypes = {
    header: PropTypes.string,
    id: PropTypes.string,
    isSuccessOrFailSubmission: PropTypes?.bool,
    linkRef: PropTypes.string,
    setStage: PropTypes.func.isRequired,
    setIsFinished: PropTypes.func.isRequired,
};

export default FormFinisher;