import React, { useState } from 'react';
import { HeaderSection, ZameryMainSection, /* BottomSectionSecondary */ } from '../components';
import { Container } from 'react-bootstrap';

const Zamery = () => {
    const [isActive, setIsActive] = useState('block');

    return (
        <Container className='d-flex flex-column align-items-center' fluid >
            <HeaderSection headerText={'Záměr je jednoznačná identifikace stavby nebo úpravy stavby, zařízení či pozemků.'} page={'zamery'} mainHeader={'Moje záměry'} />
            <ZameryMainSection isActive={isActive} setIsActive={setIsActive} />
            {/* <BottomSectionSecondary /> */}
        </Container>);
};

export default Zamery;