import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, AffectedConstructionsAndParcels, ZamerSelectionControls } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form09.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, hasZamerError, setUploadedDocumentsSizeFromZamer } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    waterworks: false,
    startDate: '',
    endDate: '',
    selfHelp: true,
    propertyDeveloper: false,
    useNeighborFacility: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    changeOfPurpose: false,
    splitOrMergeParcels: false,
    protectZoneDetermination: false,
    propertyDeveloperDetails: {
        isFOBusiness: true,
        isPO: false,
        id: null,
        names: null,
        lastName: null,
        lastNameOrigin: null,
        nationality: null,
    },
    useNeighborFacilityReason: '',
};

const ZamerComponent09 = ({
    progressElements,
    setProgressElements,
    urlPath,
    setCheckbox
}) => {
    const {intention, stepValue, validationRef, setStagesArr, saveFormRef, id, setUploadedBytes} = useContext(FormContext); 
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const buildIntention = request?.buildIntention;
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);

    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
        buildConstructions: [],
        buildParcels: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { id: formData.id } } : {}) }));

                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value, 
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }

                    const buildAppForm = response.data?.buildApplicationForms[0];
                    if (buildAppForm) {
                        setCheckbox({
                            first: buildAppForm?.applicationExceptionRequirementBuild ?? false,
                        });
                    }
                    const documentations = response.data?.buildApplication?.documentations ?? null;
                    if (documentations && !progressElements?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }
        
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));
    
    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            }

            if (buildIntention.useNeighborFacility && !request?.buildIntention?.useNeighborFacilityReason.trim())
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo záměru (nepovinné)</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 mt-1 section-title'>Součástí záměru je</p>
                        <form className='d-flex flex-column p-0 form'>
                            <Row className='d-flex mb-2'>
                                <Col md={6} sm={12}>
                                    <label className='id-checkbox-label'>
                                        <input
                                            type="checkbox"
                                            className='id-checkbox-input'
                                            checked={Boolean(request.buildIntention.changeOfPurpose)}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfPurpose: e.target.checked } })); }} />
                                        Změna využití území
                                    </label>
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='input-help-text ps-0 ps-md-3'>Viz § 213 stavebního zákona (např. terénní úprava, změna druhu pozemku nebo způsobu využití pozemku, úprava pozemku, která má vliv na schopnost vsakování vody). </p>
                                </Col>                      
                            </Row>
                            <Row className='mb-2'>
                                <Col md={6} sm={12}>
                                    <label className='id-checkbox-label p-0'>
                                        <input
                                            type="checkbox"
                                            className='id-checkbox-input'
                                            checked={Boolean(request.buildIntention.splitOrMergeParcels)}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, splitOrMergeParcels: e.target.checked } })); }} />
                                            Dělení nebo scelení pozemků
                                    </label>
                                </Col>
                            </Row>
                            <Row className='d-flex mb-2'>
                                <Col md={6} sm={12}>
                                    <label className='id-checkbox-label'>
                                        <input
                                            type="checkbox"
                                            className='id-checkbox-input'
                                            checked={Boolean(request.buildIntention.protectZoneDetermination)}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, protectZoneDetermination: e.target.checked } })); }} />
                                        Stanovení ochranného pásma
                                    </label>
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='input-help-text ps-0 ps-md-3'>Návrh na stanovení ochranného pásma, které se týká blíže neurčeného okruhu osob, nebo ochranného pásma vodního díla je nutné podat samostatně na formuláři, který je obsažen v příloze č. 9 vyhlášky č. 149/2024 Sb..</p>
                                </Col>                
                            </Row>
                        </form>
                    </Row>

                    <PozemkyTable
                        subtitle={'na kterých se má záměr uskutečnit'}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'na kterých se má záměr uskutečnit'}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                        setDataToAdd={setParcelAndConstructionData}
                    />

                    <Row className='row-wrapper mt-4'>
                        <form className='d-flex flex-column p-0 form'>
                            <label className='id-checkbox-label'>
                                <input
                                    type="checkbox"
                                    className='id-checkbox-input'
                                    checked={Boolean(buildIntention.useNeighborFacility)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, useNeighborFacility: e.target.checked } })); }} />
                                K provedení stavby má být použit sousední pozemek nebo stavba
                            </label>
                        </form>
                    </Row>

                    {buildIntention.useNeighborFacility && <Row className='row-wrapper'>
                        <AffectedConstructionsAndParcels
                            setDataToAdd={setParcelAndConstructionData}
                            isAffected={true}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            urlPath={urlPath}

                            request={request}
                            setRequest={setRequest}
                            affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                            affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}  
                        />
                    </Row>
                    }
                </Container>}
        </>
    );
};

ZamerComponent09.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent09;