import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/rizeni.css';

const ZadostiDetailPodani = ({ item }) => {

    const convertEuropeanDate = (dateString) => {
        let i = 0;
        let tempStr = '';
        let arr = [];
        while(dateString && i < dateString?.length)
        {
            if(dateString[i] === '-')
            {
                arr.push(tempStr);
                tempStr = '';
            }
            else
            {
                tempStr += dateString[i];
            }
            i++;
        }
        arr.push(tempStr);
        if(dateString) return(`${arr[2]}.${arr[1]}.${arr[0]}`);
    };

    const formatStatus = (status) => {
        if(status)
        {
            if(status === 'nevyrizen')
            {
                return 'Nevyřízeno';
            }
            else return status;
        }
    };

    return (
        <Container fluid className='podani-container borderrounded p-4' style={{marginBottom: '40px'}}>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Stav</Col>
                <Col className='podani-row podani-row-info'>{formatStatus(item?.status) ?? <b>Data nejsou k dispozici</b>}</Col>
            </Row>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Podáno</Col>
                <Col className='podani-row podani-row-info'>{convertEuropeanDate(item?.submissionDate) ?? <b>Data nejsou k dispozici</b>}</Col>
            </Row>
            {/* <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Způsob podání</Col>
                <Col className='podani-row podani-row-info'>{item?.mode}</Col>
            </Row> */}
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>PID</Col>
                <Col className='podani-row podani-row-info'>{!item?.documentPid?.trim() ? <b>Data nejsou k dispozici</b> : item?.documentPid}</Col>
            </Row>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Řízení číslo jednací</Col>
                <Col className='podani-row podani-row-info'>{!item?.procedureNumber ? <b>Data nejsou k dispozici</b> : item?.procedureNumber}</Col>
            </Row>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Číslo záměru</Col>
                <Col className='podani-row podani-row-info'>{!item?.icsId?.trim() ? <b>Data nejsou k dispozici</b> : item?.icsId}</Col>
            </Row>
            <Row className='py-1'>
                <Col className='podani-row' xs={4}>Záměr</Col>
                <Col className='podani-row podani-row-info'>{!item?.intentionName?.trim() ? <b>Data nejsou k dispozici</b> : item?.intentionName}</Col>
            </Row>
        </Container>
    );
};

ZadostiDetailPodani.propTypes = {
    item: PropTypes.shape({
        status: PropTypes.string,
        submissionDate: PropTypes.string,
        mode: PropTypes.string,
        documentPid: PropTypes.string,
        procedureNumber: PropTypes.string,
        projectId: PropTypes.string,
        intentionName: PropTypes.string,
        intentionNumber: PropTypes.string,
        icsId: PropTypes.string
    })
};

export default ZadostiDetailPodani;
