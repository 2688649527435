import React from 'react';
import '../css/rizeniDetail.css';
import { Container } from 'react-bootstrap';
import RizeniApiCall from '../components/RizeniDetail/RizeniApiCall';

const RizeniDetail = () => {
    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <RizeniApiCall/>
        </Container>
    );
};

export default RizeniDetail;