import React from 'react';
import { Container } from 'react-bootstrap';

import ZadostiApiCall from '../components/ZadostiDetail/ZadostiApiCall';

const ZadostiDetail = () => {

    return (
        <Container className='d-flex flex-column align-items-center zadosti-detail-page' fluid>
            <ZadostiApiCall/>
        </Container>
    );
};

export default ZadostiDetail;