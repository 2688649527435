import React, { useContext, useEffect, useMemo, useState } from 'react';
import { counter, saved } from '../../constants/zadostState';
import '../../css/reusable.css';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { arrowRight, iconAutoSave, iconDownload } from '../../assets';
import { useGlobalState } from '../../constants/states';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { createPdf } from '../../apiCalls/componentsApiCalls.js';
import { ProgressIndicator } from '../index';
import { canShowRemainingUploadCapacity, convertBytes, getTotalValueFromUploads } from '../../helperFunctions/helpers.js';
import { TOTAL_UPLOAD_LIMIT } from '../../constants/sharedConstants.js';

const FormFooter = ({ setIsFinished, formNumber, setIsSuccessOrFailSubmission, receivedToken, currentlyUploading, averageProgress, buttonRef }) => {
    const { token } = useContext(AuthContext);
    const { validationRef, saveFormRef, stagesArr, setStagesArr, id, uploadedBytes } = useContext(FormContext);
    const [state, setState] = useGlobalState();
    const [, forceUpdate] = useState(false);
    const [lastSaveTime, setLastSaveTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const cooldownPeriod = 3000;
    const saving = saved.value;
    const totalSizeValue = getTotalValueFromUploads(currentlyUploading, 'size') || 0;
    const uploadSize = convertBytes((totalSizeValue / currentlyUploading?.length) * averageProgress / 100, 2) || 0;
    const totalSize = convertBytes(totalSizeValue, 2) || 0;
    const remainingSize = useMemo(() => convertBytes(TOTAL_UPLOAD_LIMIT - uploadedBytes - getTotalValueFromUploads(currentlyUploading, 'size')), [currentlyUploading, uploadedBytes]);
    
    useEffect(() => {
        const unsubscribe = saved.subscribe(() => {
            forceUpdate(update => !update);
        });

        return () => unsubscribe();
    }, []);

    const sendForm = async () => {
        try {
            const source = axios.CancelToken.source();
            let configKey = receivedToken ? 'validateAndPersistBaseUrl' : 'integrationsUrl';
            let param = receivedToken ? `build-intentions/submit-accompanying-document/${id}` : `send/${id}`;

            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            };
        
            if (receivedToken) {
                config.headers.shared = receivedToken;
            }

            const response = await axios.post(
                urlJoin(apiConfig[configKey], param),
                null,
                config);
            if ((200 <= response?.status) && (response?.status < 300)) {
                setIsSuccessOrFailSubmission(true);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
            setIsSuccessOrFailSubmission(false);
        }
    };


    const nextStage = () => {
        if (receivedToken && state.stage !== 4 && state.stage !== 3 && state.stage !== 2) {
            if (state.stage <= stagesArr.length - 1) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else if (state.stage === stagesArr.length) {
                if (validationRef?.current?.validate()) {
                    sendForm();
                    setIsSuccessOrFailSubmission(null);
                    setIsFinished(true);
                } else {
                    window.scrollTo({
                        top: 500,
                        behavior: 'smooth',
                    });
                }
            }

            return;
        }

        if ((state.stage === 1 && !receivedToken) || (receivedToken && (state.stage === 4 || state.stage === 3 || state.stage === 2))) {
            if (validationRef?.current?.validate()) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: 480,
                    behavior: 'smooth',
                });
            }
            return;
        }
           
        if (state.stage <= stagesArr.length - 1) {
            validationRef?.current?.validate();
            setState(prevState => {
                return ({ ...prevState, stage: prevState.stage + 1 });
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (state.stage === stagesArr.length) {
            const finalValidation = validationRef?.current?.validate();
            const isInputError = [...stagesArr].some(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.error === true));
            const isEveryThingChecked = [...stagesArr]
                .filter(stg => Number(stg?.stage) !== stagesArr.length)
                .every(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.seen === true));

            if (!isInputError && isEveryThingChecked && finalValidation) {
                sendForm();
                setIsSuccessOrFailSubmission(null);
                setIsFinished(true);
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        return {...stg, warning: false};
                    }
                    return stg;
                }));
            } else {
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        // eslint-disable-next-line no-undef
                        return {...stg, warning: true};
                    }
                    return stg;
                }));
            }
        }
    };

    const prevStage = () => {
        if (state.stage > 1) {
            if (!receivedToken) {
                validationRef?.current?.validate();
            }
            setState(prevState => ({ ...prevState, stage: prevState.stage - 1 }));
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const handleSaveClick = () => {
        const currentTime = Date.now();
        if (currentTime - lastSaveTime > cooldownPeriod) {
            saveFormRef?.current?.saveForm();
            setLastSaveTime(currentTime);
            setIsButtonDisabled(true); 

            setTimeout(() => {
                setIsButtonDisabled(false);
            }, cooldownPeriod);
        } else {
            console.log('Please, wait before saving again.');
        }
    };

    return (
        <Container fluid className='p-3 p-md-5 d-flex justify-content-center' style={{ backgroundColor: 'white', padding: '24px 0' }}>
            <Row className='base-width d-flex align-items-center justify-content-between'>
                <Col xs={12} xl={4} className='my-3 my-xl-0 d-flex'>
                    {state.stage > 1
                        &&
                        <button className='d-flex justify-content-between align-items-center button-outlined' onClick={prevStage} style={{ marginRight: '1rem', minWidth: '110px', fontSize: '0.813rem', fontWeight: '700', lineHeight: '1.5rem', letterSpacing: '0.02rem', height: '3rem', border: 'solid #2362A2 1px', paddingRight: '1.75em', borderRadius: '3px', backgroundColor: 'transparent', color: '#2362A2' }}>
                            <img src={arrowRight} style={{ rotate: '180deg', height: '1.5rem', marginLeft: '1rem' }} />
                            Zpět
                        </button>}
                    <button className='w-100 d-flex align-items-center justify-content-center button-solid' onClick={nextStage} style={{ fontSize: '0.813rem', fontWeight: '700', lineHeight: '1.5rem', letterSpacing: '0.02rem', height: '3rem', border: 'none', padding: '8px 12px', borderRadius: '3px', backgroundColor: '#2362A2', color: '#FFF' }}
                        ref={el => { if (buttonRef) buttonRef.current['finisher'] = el; }} // pass ref as an array in case more manipulation of other buttons/content needed
                    >
                        {
                            (stagesArr.length > 0 && stagesArr.map((item, index, array) =>
                                (state.stage === item.stage && index === array.length - 1) && receivedToken ? 'Vložit' :
                                    (state.stage === item.stage && index === array.length - 1) ? 'Podat' :
                                        (state.stage + 1 === item.stage) ? item.name :
                                            ''
                            ))
                        }
                        <img src={arrowRight} style={{ height: '1.5rem', marginLeft: '1rem', filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(42deg) brightness(104%) contrast(103%)' }} />
                    </button>
                </Col>
                {currentlyUploading?.length > 0 && 
                    <ProgressIndicator
                        type={'footer'}
                        averageProgress={averageProgress}
                        uploadSize={uploadSize}
                        totalSize={totalSize}
                        remainingSize={remainingSize}
                    />}
                {(!currentlyUploading || currentlyUploading?.length === 0)  && (!saving
                    ?
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <span>Ukládám formulář...</span>
                    </Col>
                    :
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <img src={iconAutoSave} style={{ height: '1.25rem', marginRight: '1rem' }} />
                        <Container className='d-flex flex-column justify-content-center'>
                            <p>
                                <span style={{ fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem', color: '#6FBD2C' }}>Automaticky uloženo </span>
                                <span style={{ fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem', color: 'black' }}>před {counter} sekundami. &nbsp;&nbsp;</span>
                                <span style={{ fontSize: '0.938rem',
                                    fontWeight: '500',
                                    lineHeight: '1.464rem',
                                    letterSpacing: '0.018rem',
                                    color: isButtonDisabled ? '#cccccc' : '#2362A2',
                                    textDecoration: 'underline',
                                    cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                                }}
                                onClick={handleSaveClick}
                                >
                                                Uložit nyní
                                </span>
                            </p>
                            {(canShowRemainingUploadCapacity(formNumber) && remainingSize) && <p className='upload-indicator-footer secondary'>{`Možno nahrát ještě ${remainingSize}`}</p>}
                        </Container>
                    </Col>)}
                <Col xs={12} md={4} xl={{ span: 3 }} className='d-flex align-items-center justify-content-center justify-content-md-end my-2 my-md-0' style={{ cursor: 'pointer' }}>
                    <img src={iconDownload} style={{ height: '1.25rem', marginRight: '1rem' }} />
                    <p style={{ fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem', color: '#2362A2', textDecoration: 'underline' }} onClick={() => createPdf(formNumber, id, receivedToken, token)}>
                        Stáhnout náhled PDF k tisku
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

FormFooter.propTypes = {
    setIsFinished: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    formNumber: PropTypes.string,
    receivedToken: PropTypes.string,
    setIsSuccessOrFailSubmission: PropTypes.func,
    averageProgress: PropTypes.number,
    currentlyUploading: PropTypes.arrayOf(Object),
    buttonRef: PropTypes.objectOf(Array),
};

export default FormFooter;

