import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/zadatelComponent.css';
import { iconChange, trashbin } from '../../assets';
import { handleDelete, handleUpdate } from '../../helperFunctions/modalHelpers';
import { PLNA_MOC_SECOND } from '../../constants/sharedConstants';

const ZadatelCard = ({ item, applicantArr, setCurrentApplicant, setZadatelAttachments, setShow, setApplicantArr }) => {
    return (
        <Container className='d-flex flex-column justify-content-between p-3 zadatel-card-container'>
            <Container>
                <Row className='border-bottom py-1'>
                    <Col className='zadatel-card-row' xs={5} sm={4}>
                        <p>Jméno/Název</p>
                    </Col>
                    <Col className='ps-2 zadatel-row-info'>
                        <p className='text-truncate text-wrap' title={item.isPO ? `${item.poName}` : `${item.names} ${item.lastName}`}>{item.isPO ? item.poName : `${item.names} ${item.lastName}`}</p>
                    </Col>
                </Row>
                <Row className='border-bottom py-1'>
                    <Col className='zadatel-card-row' xs={5} sm={4}>
                        <p>Adresa</p>
                    </Col>
                    <Col className='ps-2 zadatel-row-info'>
                        <p className='text-truncate text-wrap' title={`${item.address?.street} ${item.address?.descNum}${item.address?.orientNum ? '/' : ''}${item.address?.orientNum}, ${item.address?.city}`}>{`${item.address?.street} ${item.address?.descNum}${item.address?.orientNum ? '/' : ''}${item.address?.orientNum}, ${item.address?.city}`}</p>
                    </Col>
                </Row>
                <Row className='py-1 mb-3'>
                    <Col className='zadatel-card-row' xs={5} sm={4}>
                        <p className='text-truncate text-wrap'>IČO</p>
                    </Col>
                    <Col className='ps-2 zadatel-row-info'>
                        <p className='text-truncate text-wrap' title={item.ico}>{item.ico}</p>
                    </Col>
                </Row>
            </Container>
            <Row className='d-flex justify-content-between'>
                <button type='button' className='zadatel-button' onClick={() => handleUpdate(item.id, applicantArr, setCurrentApplicant, setShow)}><img src={iconChange} alt='iconChange' className='pe-2' />Upravit</button>
                <button type='button' className='zadatel-button zadatel-button--delete' onClick={() => handleDelete(item.id, applicantArr, setZadatelAttachments, PLNA_MOC_SECOND, setApplicantArr)}><img src={trashbin} alt='trashbin' className='pe-2' />Smazat</button>
            </Row>
        </Container>
    );
};

ZadatelCard.propTypes = {
    item: PropTypes.shape({
        isPO: PropTypes.bool,
        poName: PropTypes.string,
        names: PropTypes.string,
        lastName: PropTypes.string,
        id: PropTypes.string,
        ico: PropTypes.string,
        address: PropTypes.shape({
            street: PropTypes.string,
            descNum: PropTypes.string,
            orientNum: PropTypes.string,
            city: PropTypes.string,
        })
    }),
    applicantArr: PropTypes.arrayOf(Object),
    setCurrentApplicant: PropTypes.func,
    setZadatelAttachments: PropTypes.func,
    setShow: PropTypes.func,
    setApplicantArr: PropTypes.func,
};

export default ZadatelCard;