import React, { useState, useEffect, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/ZadostPovoleniStavbyOsnova.css';
import { iconChange, trashbin, arrowRight } from '../../assets';
import { PridatPovolenModal } from '../../components/index';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { shouldNotSave, } from '../../helperFunctions/helpers';
import useFirstRender from '../CustomHooks/useFirstRender';
import usePrevious from '../CustomHooks/usePrevious';
import { useLocation, useNavigate } from 'react-router-dom';

const ZadostPovoleniStavbyOsnova4 = ({urlPath}) => {
    const {intention, stepValue, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [editedPermission, setEditedPermission] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            permissions: [],
        }
    });
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);

    const addPermission = (newPermission) => {
        if (newPermission.id !== undefined) {
            setRequest(state => ({
                ...state, form: {
                    ...state.form, 
                    permissions: state.form.permissions.map((item, index) => index === newPermission.id ? newPermission : item)
                }})
            );
        } else {
            setRequest(state => ({
                ...state, form: {
                    ...state.form,
                    permissions: [ ...(state.form.permissions === null ? [] : state.form.permissions), newPermission]
                }})
            );
        }
    };

    const deletePermission = (indexToDelete) => {
        setRequest(state => ({
            ...state, form: {
                ...state.form, 
                permissions: state.form.permissions.filter((item, index) => index !== indexToDelete)
            }})
        );
    };

    const cancelEditPermission = () => {
        setEditedPermission(null);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data?.buildApplicationForms?.[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form, permissions: loadedData.permissions === null ? [] : [...loadedData.permissions]} }));
                }
            }
            else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form.permissions]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));


    return (
        <Container fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 step-title'>{`${stepValue}. Žádost o změnu nebo zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření`}</p>                    
            </Row>
            <Row className=''>
                        
                {request.form.permissions?.length > 0 && request.form.permissions.map((permissionItem, index) => {
                    return (    
                        <Col lg={6} xs={12} className='p-2' key={index}>
                            <Row className='border card-body-list p-4' key={index} style={{minHeight: '250px'}}>
                                <Row className='py-1 border border-top-0 border-start-0 border-end-0 align-text-bottom'>
                                    <Col>Číslo jednací</Col>
                                    <Col>{permissionItem.number}</Col>
                                </Row>
                                <Row className='py-1 border border-top-0 border-start-0 border-end-0 align-text-bottom'>
                                    <Col>Název povolení</Col>
                                    <Col>{permissionItem.name}</Col>
                                </Row>   
                                <Row className='py-1 border border-top-0 border-start-0 border-end-0 align-text-bottom'>
                                    <Col>Orgán</Col>
                                    <Col>{permissionItem.legalAuthority}</Col>
                                </Row>   
                                <Row className='py-1 align-text-bottom'>
                                    <Col>Ze dne</Col>
                                    <Col>{permissionItem.date}</Col>
                                </Row>
                                <Row className='py-1 align-text-bottom'>
                                    <Col className=''>
                                        <button type='button' className='p-1 w-100 btn btn-lg oze-button outline'
                                            onClick={() => { setEditedPermission({...permissionItem, 'id': index}); setModalShow(true); }}>
                                            <img src={iconChange} alt='file' className='pe-2' />
                                                    Upravit
                                        </button>
                                    </Col>&nbsp;
                                    <Col className=''>
                                        <button type='button' className='p-1 w-100 btn btn-lg oze-button outline-delete'
                                            onClick={() => deletePermission(index)}>                                                 
                                            <img src={trashbin} alt='file' className='pe-2' />
                                                    Smazat
                                        </button>
                                    </Col>
                                </Row>
                            </Row> 
                        </Col>
                    );})}
                       
                <Col lg={6} xs={12} className='p-2'>
                    <Row className='d-flex text-center align-items-center justify-content-center permission'>
                        <button className='d-flex justify-content-center align-items-center add-permission-button'
                            onClick={() => { setEditedPermission(null); setModalShow(true); }}>
                                        Přidat povolení 
                            <img src={arrowRight} className='add-permission-arrow'/>
                        </button>
                    </Row>
                </Col>
            </Row>  
            <PridatPovolenModal show={modalShow} setShow={setModalShow} editedPermission={editedPermission} savePermission={addPermission} cancelEditPermission={cancelEditPermission} />
        </Container>
    );
};

ZadostPovoleniStavbyOsnova4.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default ZadostPovoleniStavbyOsnova4;
