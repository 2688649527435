import {  
    BASIC_INFO, 
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION, 
    LOCATION_BUSINESS,
    DELIVERY_LOCATION, 
    CONTACTS, 
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 2,
        name: 'Žadatel',
        moreInfo: 'Osobní údaje o žadateli a případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 3,
        name: 'Povolení',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 4,
        name: 'Stavba',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 5,
        name: 'Přilohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 6,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,     
    },
    {
        stage: 7,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,  
    }
];
// eslint-disable-next-line no-unused-vars
const getItemsBasedOnCheckbox = (isCheckbox1Checked = null, isCheckbox2Checked = null, setStagesArr, prevCheckboxState) => {
    const {first} = prevCheckboxState || {};
    const prevFirst = first ?? false;

    if (isCheckbox1Checked && !prevFirst) {
        const newItem = { 
            stage: 5, 
            name: 'Dokumentace', 
            moreInfo: 'Stavební dokumentace, ve výchozím stavu se přebírá ze Záměru',
            error: false,
            warning: false,
            seen: false,
        };

        setStagesArr(prev => [...prev.slice(0, 4), {...newItem, ...([...prev].find(stg => stg.name === 'Dokumentace') || {})}, ...prev.slice(4).map(stg => ({...stg, stage: stg.stage + Number(isCheckbox1Checked && !prevFirst)}))]);
    } else if (!isCheckbox1Checked && prevFirst) {
        setStagesArr(prev => [...prev.slice(0, 4), ...prev.slice(5).map(stg => ({...stg, stage: stg.stage - Number(!isCheckbox1Checked && prevFirst)}))]);
    }
};

// DOCUMENT UPLOAD CONSTANTS

const PLNA_MOC_FIRST = 'power_of_attorney_advocate';
const PLNA_MOC_SECOND = 'power_of_attorney_more';
const WARRANT = 'warrant';
const PARTICIPANTS_LIST = 'participants_list';
const COMPLIANCE_PROOF = 'compliance_proof';
const OTHER_DOCUMENTS = 'other_documents';
const TESTS_EVALUATION = 'tests_evaluation';
const BUILDING_CERTIFICATE = 'building_certificate';
const CONTRACTOR_AGREEMENT = 'contractor_agreement';

export {
    getItemsBasedOnCheckbox,
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    WARRANT,
    PARTICIPANTS_LIST,
    COMPLIANCE_PROOF,
    OTHER_DOCUMENTS,
    TESTS_EVALUATION,
    BUILDING_CERTIFICATE,
    CONTRACTOR_AGREEMENT,
    items,
};
