import React, { useRef } from 'react';
import '../css/button.css';
import PropTypes from 'prop-types';
import { GovButton } from '@gov-design-system-ce/react';

const Button = ({ onClickFunction, buttonLabel, variant, size }) => {
    const buttonRef = useRef();

    return (
        <GovButton className='button' ref={buttonRef} type='solid'
            variant={variant || 'primary'} size={size || 'm'} onGov-click={onClickFunction}>
            {buttonLabel}
        </GovButton>
    );
};

Button.propTypes = {
    onClickFunction: PropTypes.func,
    buttonLabel: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
};

export default Button;
