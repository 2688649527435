import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FORM_02_INTENTION, FORM_04_INTENTION, FORM_08_INTENTION, FORM_10_INTENTION, FORM_15_INTENTION, FORM_16_INTENTION, FORM_19_INTENTION } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';

const VodniDilo = ({
    buildIntention,
    focused,
    request,
    setRequest,
    handleFocused,
}) => {
    const { intention, stepValue, stagesArr } = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const isForm02 = intention === FORM_02_INTENTION.title;
    const isForm08 = intention === FORM_08_INTENTION.title;
    const isForm10 = intention === FORM_10_INTENTION.title;
    const isForm15 = intention === FORM_15_INTENTION.title;
    const isForm16 = intention === FORM_16_INTENTION.title;
    const isForm04 = intention === FORM_04_INTENTION.title;
    const isForm19 = intention === FORM_19_INTENTION.title;

    return (
        <Row className='border p-4 row-wrapper'>
            <Row className='d-flex pb-3'>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <label className='select-input' style={{color: (!buildIntention?.waterworksPurpose && (error || focused.waterworksPurpose)) ? '#C52A3A': ''}}>
                        Účel stavby vodního díla
                        <select
                            type='select'
                            value={buildIntention.waterworksPurpose}
                            required
                            {...(focused.waterworksPurpose && { focused: 'true' })}
                            style={{ border: error && !buildIntention?.waterworksPurpose?.trim() && 'solid 1px #C52A3A' }}
                            onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworksPurpose: e.target.value } })); }}
                            onBlur={() => handleFocused('waterworksPurpose')}
                            className='mt-1 dropdown-input id-input2' >
                            <option value='' disabled>vyberte</option>
                            <option value={'01 umožnění odběru povrchové vody pro vodárenské účely'}>01 umožnění odběru povrchové vody pro vodárenské účely</option>
                            <option value={'02 umožnění odběru povrchové vody pro průmyslové účely'}>02 umožnění odběru povrchové vody pro průmyslové účely</option>
                            <option value={'03 umožnění odběru povrchové vody pro závlahy'}>03 umožnění odběru povrchové vody pro závlahy</option>
                            <option value={'04 umožnění odběru povrchové vody pro ostatní účely'}>04 umožnění odběru povrchové vody pro ostatní účely</option>
                            <option value={'05 podzemní voda'}>05 podzemní voda</option>
                            <option value={'06 vodní energie, využití energetického potenciálu'}>06 vodní energie, využití energetického potenciálu</option>
                            <option value={'07 vodní doprava, plavební účely'}>07 vodní doprava, plavební účely</option>
                            <option value={'08 ochrana před povodněmi a ostatními škodlivými účinky vod'}>08 ochrana před povodněmi a ostatními škodlivými účinky vod</option>
                            <option value={'09 odvádění odpadních a jiných vod koryty vodních toků'}>09 odvádění odpadních a jiných vod koryty vodních toků</option>
                            <option value={'10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů'}>10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů</option>
                            <option value={'11 rekreace u vody a jiné účely'}>11 rekreace u vody a jiné účely</option>
                            <option value={'12 nadlepšování průtoků'}>12 nadlepšování průtoků</option>
                            <option value={'13 stabilizace toku'}>13 stabilizace toku</option>
                            <option value={'14 usazovací účinek'}>14 usazovací účinek</option>
                            <option value={'99 jiné'}>99 jiné</option>
                        </select>
                    </label>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>
                        {isForm08 ? 
                            'Je-li předmětem žádosti o povolení stavba vodního díla. Účel stavby vodního díla podle číselníku Č11 Účel užití vodního díla uvedeného v příloze č. 4 vyhlášky č. 414/2013 Sb., o rozsahu a způsobu vedení evidence rozhodnutí, opatření obecné povahy, závazných stanovisek, souhlasů a ohlášení, k nimž byl dán souhlas podle vodního zákona, a částí rozhodnutí podle zákona o integrované prevenci (o vodoprávní evidenci).'
                            : 'Účel stavby vodního díla podle číselníku Č11 Účel užití vodního díla uvedeného v příloze č. 4 vyhlášky č. 414/2013 Sb., o rozsahu a způsobu vedení evidence rozhodnutí, opatření obecné povahy, závazných stanovisek, souhlasů a ohlášení, k nimž byl dán souhlas podle vodního zákona, a částí rozhodnutí podle zákona o integrované prevenci (o vodoprávní evidenci).'
                        }                                   
                    </p>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <label className='select-input'>
                        Název vodního toku
                        <input
                            type='text'
                            defaultValue={buildIntention.watercourseName ?? ''}
                            required
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseName: e.target.value } }));
                            }}
                            className='mt-1 id-input2 waterworks bg-white p-2'
                        />
                    </label>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>
                        {
                            (isForm15 || isForm08) ? 'V případě, že se záměr týká vodního toku.'
                                : isForm19 ? (<>V případě, že se záměr týká vodního toku. <br/>Lze dohledat na <a href="https://www.voda.gov.cz/" target="_blank" rel="noreferrer">ISVS Voda</a>, záložka Datové sady :chevron-right-regular: Osy vodních linií</>) 
                                    : ''                                       
                        }      
                    </p>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <label className='select-input'>
                        ID vodního toku
                        <input
                            type='text'
                            defaultValue={buildIntention.watercourseId ?? ''}
                            required
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseId: e.target.value } }));
                            }}
                            className='mt-1 id-input2 waterworks bg-white p-2'
                        />
                    </label>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>
                        {(isForm15 || isForm08) && <>V případě, že se záměr týká vodního toku. <br/></>}
                        Číselný identifikátor vodního toku podle údajů v evidenci vodních toků (§ 2 vyhlášky č. 252/2013 Sb., o rozsahu údajů v evidencích stavu povrchových a podzemních vod a o způsobu zpracování, ukládání a předávání těchto údajů do informačních systémů veřejné správy).
                    </p>

                    {isForm19 && <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>Lze dohledat na <a href="https://www.voda.gov.cz/" target="_blank" rel="noreferrer">ISVS Voda</a>, záložka Datové sady :chevron-right-regular: Osy vodních linií, při kliknutí na vybraný tok v mapě se ve vyskakovacím okně zobrazí detailní informace o vodním toku, vč. IDVT (ID vodního toku)</p>}
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <label className='select-input p-0'>
                        Název hydrogeologického rajónu
                        <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaName}
                            onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaName: e.target.value}}));}}
                            className='mt-1 form-zamer-input waterworks bg-white p-2'
                        />
                    </label>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>
                        {(isForm04 || isForm02 || isForm19) ? 'V případě, že se záměr týká podzemních vod'
                            : 'Pouze v případě, že se záměr týká podzemních vod.'                                       
                        }
                    </p>

                    {isForm19 && <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>Lze dohledat na <a href="https://www.voda.gov.cz/" target="_blank" rel="noreferrer">ISVS Voda</a>, záložka Datové sady :chevron-right-regular: Útvary podzemních vod :chevron-right-regular: po označení místa v mapě se ve vyskakovacím okně zobrazí detailní  informace o hydrogeologickém rajonu, vč. jeho názvu a ID</p>}
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    <label className='select-input'>
                        ID hydrogeologického rajónu
                        <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaID}
                            onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaID: e.target.value}}));}}
                            className='mt-1 form-zamer-input waterworks bg-white p-2'
                        />
                    </label>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column'>
                    {(isForm02 || isForm08 || isForm04 || isForm19) ?
                        <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>
                        V případě, že se záměr týká podzemních vod. Číselný identifikátor hydrogeologického rajónu podle údajů v evidenci hydrogeologických rajónů (§ 4 vyhlášky č. 252/2013 Sb.).
                        </p> 
                        : (isForm10 || isForm15 || isForm16) ? 
                            <>
                                <p className='mt-2 mt-md-4 building-change ps-md-3'>
                                Číselný identifikátor hydrogeologického rajónu podle údajů v evidenci hydrogeologických rajónů (§ 4 vyhlášky č. 252/2013 Sb.).
                                </p>
                                <p className='mt-2 building-change ps-md-3'>
                                Pouze v případě, že se záměr týká podzemních vod.
                                </p>
                            </>
                            : ''}
                    {isForm19 && <p className='mt-2 mt-md-4 building-change ps-0 ps-md-3'>Lze dohledat na <a href="https://www.voda.gov.cz/" target="_blank" rel="noreferrer">ISVS Voda</a> &#62; záložka Datové sady &#62; Útvary podzemních vod &#62; po označení místa v mapě se ve vyskakovacím okně zobrazí detailní informace o hydrogeologickém rajonu, vč. jeho názvu a ID</p>}
                </Col>
            </Row>
        </Row>
    );
};

VodniDilo.propTypes = {
    buildIntention: PropTypes.object,
    focused: PropTypes.object,
    request: PropTypes.object,
    setRequest: PropTypes.func,
    handleFocused: PropTypes.func,
};

export default VodniDilo;