import {  
    BASIC_INFO, 
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION, 
    LOCATION_BUSINESS,
    DELIVERY_LOCATION, 
    CONTACTS, 
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 2,
        name: 'Žadatel',
        moreInfo: 'Osobní údaje o žadateli a případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 3,
        name: 'Povolení',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 4,
        name: 'Stavba',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 5,
        name: 'Přilohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 6,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 7,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,  
    },
];

// DOCUMENT UPLOAD CONSTANTS

const PLNA_MOC_FIRST = 'power_of_attorney_advocate';
const PLNA_MOC_SECOND = 'power_of_attorney_more';

export {
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    items,
};
