import { APPLICANT_MODEL, APPLICANT_TYPE_FO, APPLICANT_TYPE_FOP, APPLICANT_TYPE_PO, PLNA_MOC_FIFTH, PLNA_MOC_SECOND } from '../constants/sharedConstants.js';
import { v4 as uuidv4 } from 'uuid';

const handleClose = (setShow) => setShow(false);
const handleShow = (setShow) => setShow(true);

const handleSaveApplicant = (
    currentApplicant, 
    applicantArr, 
    setCurrentApplicant, 
    setShow, 
    setApplicantArr,
    titleRef, // obj | undefined
) => {
    const foundApplicantIndex = [...applicantArr].findIndex(el => el.id === currentApplicant.id);
    if (foundApplicantIndex === -1) {
        setApplicantArr([...applicantArr, currentApplicant]);
    } else if (foundApplicantIndex > -1 ) {
        setApplicantArr([...applicantArr.slice(0, foundApplicantIndex), currentApplicant, ...applicantArr.slice(foundApplicantIndex + 1)]);
    }

    if (titleRef) {
        setCurrentApplicant({...APPLICANT_MODEL, id: uuidv4()});
        titleRef?.current?.scrollIntoView({ block:'nearest' });
        return;
    } 

    setCurrentApplicant(APPLICANT_MODEL);
    setShow(false);
};

const handleDelete = (id, applicantArr, setAttachmentState, category, setApplicantArr) => {
    const foundApplicant = applicantArr.find(el => el.id === id);
    setAttachmentState((prev) => ({
        ...prev,
        [category]: [...prev[category].filter(el => el.uid !== foundApplicant.powerOfAttorneyFile.uid)]
    }));

    const updatedApplicantArr = [...applicantArr].filter(item => item.id !== id);   
    setApplicantArr(updatedApplicantArr);
};

const handleUpdate = (id, applicantArr, setCurrentApplicant, setShow) => {
    setShow(true);
    const foundApplicant = applicantArr.find(el => el.id === id);
    setCurrentApplicant(foundApplicant);
};

const cancelAddPerson = (
    currentApplicant, 
    setAttachmentState,
    setShow,
    setCurrentApplicant,
    applicantArr,
) => {
    const foundApplicant = Boolean([...applicantArr].find(app => app.id === currentApplicant.id));

    if (!foundApplicant) {
        if (Object.hasOwn(currentApplicant, 'powerOfAttorneyFile') && Object.values(currentApplicant.powerOfAttorneyFile).some(el => el)) {   
            setAttachmentState((prev) => ({
                ...prev,
                [PLNA_MOC_SECOND]: [...(prev[PLNA_MOC_SECOND] || []).filter(el => el.uid !== currentApplicant?.powerOfAttorneyFile?.uid)],
            }));
        }
        if (Object.hasOwn(currentApplicant, 'powerOfAttorneyRepresentative') && Object.values(currentApplicant.powerOfAttorneyRepresentative).some(el => el)) {
            setAttachmentState((prev) => ({
                ...prev,
                [PLNA_MOC_FIFTH]: [...(prev[PLNA_MOC_FIFTH] || []).filter(el => el.uid !== currentApplicant?.powerOfAttorneyRepresentative?.uid)],
            }));
        }
    }

    setShow(false);
    setCurrentApplicant(APPLICANT_MODEL);
};

const resetApplicant = (applicantType, currentApplicant, setCurrentApplicant, setZadatelAttachments) => {
    setCurrentApplicant({
        ...APPLICANT_MODEL,
        isFO: applicantType === APPLICANT_TYPE_FO,
        isFOBusiness: applicantType === APPLICANT_TYPE_FOP,
        isPO: applicantType === APPLICANT_TYPE_PO,
        id: currentApplicant.id ?? null,
    });
    setZadatelAttachments((prev) => ({
        ...prev,
        [PLNA_MOC_SECOND]: [...(prev[PLNA_MOC_SECOND] || []).filter(el => el.uid !== currentApplicant?.powerOfAttorneyFile?.uid)],
        [PLNA_MOC_FIFTH]: [...(prev[PLNA_MOC_FIFTH] || []).filter(el => el.uid !== currentApplicant?.powerOfAttorneyRepresentative?.uid)],
    }));
};

export {
    handleClose,
    handleShow,
    handleSaveApplicant,
    handleDelete,
    handleUpdate,
    cancelAddPerson,
    resetApplicant,
};