import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import { 
    FORM_01_INTENTION, 
    FORM_02_INTENTION, 
    FORM_03_INTENTION, 
    FORM_04_INTENTION, 
    FORM_06_INTENTION, 
    FORM_07_INTENTION,
    FORM_09_INTENTION,
    FORM_13_INTENTION,
    FORM_16_INTENTION,
    FORM_17_INTENTION,
    FORM_18_INTENTION,
    FORM_19_INTENTION,
    PARCEL_TYPE_LAND,
    PARCEL_TYPE_BUILD,
    POZEMEK_MODEL,
} from '../../../constants/sharedConstants';
import FormContext from '../../../formContexts/FormContext';
import { handleGetCadastralAreas, handleGetParcel, handleGetParcelCity } from '../../../apiCalls/componentsApiCalls';
import { FoundParcelInfo } from '../../index';
import { errorIcon } from '../../../assets/index.js';
import useDebounce from '../../CustomHooks/useDebounce';

const AddPozemekModal = ({
    itemToUpdate,
    setItemToUpdate,
    showPozemekModal,
    setShowPozemekModal,
    handleAddItem,
    handleUpdateItem,
    isAffected,
    noFieldsToUpdate,
}) => {
    const [{isCityLoading, isCadastralsLoading, isParcelLoading}, setLoadings] = useState({
        isCityLoading: false,
        isCadastralsLoading: false,
        isParcelLoading: false
    });
    const [state, setState] = useState({
        uid: null,
        cityName: '',
        cadastralAreaName: '',
        cityList: [],
        selectedCity: null,
        cadastralAreaList: [],
        selectedCadastralArea: null,
        parcelType: PARCEL_TYPE_LAND,
        parcelNum: '',
        parcelNumOpt: '',
        parcel: null,
    });
    const [checkedState, setCheckedState] = useState({
        changeOfUse: false,
        divisionOfFusion: false,
        protectionZone: false,
        constructionSite: false,
        division: true,
        fusion: false,
        terrainAdjustments: false,
    });
    const [parcelState, setParcelState] = useState({
        actualParcelUsage: '',
        newParcelUsage: ''
    });
    const { token } = useContext(AuthContext);
    const { intention } = useContext(FormContext);
    const isDisabled = !state.parcel || !state.cityName;
    const [parcelNotFound, setParcelNotFound] = useState(false);
    const [parcelIsReadOnly, setParcelIsReadOnly] = useState(false);
    const debouncedCityName = useDebounce(state.cityName);
    const debouncedCadastralAreaName = useDebounce(state.cadastralAreaName);
    const debouncedParcelNum = useDebounce(state.parcelNum);
    const debouncedParcelNumOpt = useDebounce(state.parcelNumOpt);

    const handleChange = (event) => {
        setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    };

    const resetParcelSettings = () => {
        setCheckedState({
            changeOfUse: false,
            divisionOfFusion: false,
            protectionZone: false,
            constructionSite: false,
            division: true,
            fusion: false,
            terrainAdjustments: false,
        });
        setParcelState({
            actualParcelUsage: '',
            newParcelUsage: ''
        });
    };

    const resetPozemek = (fully = true) => {
        setItemToUpdate(null);
        if (fully) {
            setState({
                ...state,
                cityName: '',
                cadastralAreaName: '',
                cityList: [],
                selectedCity: null,
                cadastralAreaList: [],
                selectedCadastralArea: null,
                parcelType: PARCEL_TYPE_LAND,
                parcelNum: '',
                parcelNumOpt: '',
                parcel: null,
            });
        } else {
            setState({
                ...state,
                parcelType: PARCEL_TYPE_LAND,
                parcelNum: '',
                parcelNumOpt: '',
                parcel: null,
            });
        }
        resetParcelSettings();
    };

    const prepareRequest = () => {
        let req = {...POZEMEK_MODEL,
            uid: state.uid,
            constructionId: null,
            cityName: parcelIsReadOnly ? state.cityName : state.selectedCadastralArea?.cityName + ' (' + (state.selectedCadastralArea?.districtCode !== 9999 ? 'Okres ' : '') + state.selectedCadastralArea?.districtName + '); ' + state.selectedCadastralArea?.cityCode,
            cadastralTerritoryCode: state.selectedCadastralArea?.code,
            cadastralTerritory: state.cadastralAreaName,
            parcelType: state.parcelType,
            parcelNumber: state.parcel?.parcelNumber,
            landParcel: state.parcelType === PARCEL_TYPE_LAND,
            buildingParcel: state.parcelType === PARCEL_TYPE_BUILD,
            area: state.parcel?.area,
            landTypeCode: state.parcel?.landTypeCode,
            type: state.parcel?.type,
            ...checkedState,
        };
        if (intention === FORM_06_INTENTION.title) {
            req = {...req,
                ...parcelState,
                changeOfUse: true};
        }
        return req;
    };

    const handleCancel = () => {
        resetPozemek();
        setShowPozemekModal(false);
    };
  
    const handleUpdatePozemek = () => {
        handleUpdateItem(prepareRequest());
        resetPozemek();

        setShowPozemekModal(false);
    };

    const handleAddPozemek = () => {
        handleAddItem(prepareRequest());
        resetPozemek();

        setShowPozemekModal(false);
    };

    const handleAddAnother = () => {
        handleAddItem(prepareRequest());
        resetPozemek(false);

        setShowPozemekModal(true);
    };

    const spreadPozemek = () => {
        let parNum = itemToUpdate.parcelNumber.split('/');
        setState(prev => ({
            ...prev, 
            uid: itemToUpdate?.uid ?? null,
            cityCode: itemToUpdate.cityCode ?? null,
            cityName: itemToUpdate.cityName ?? '',
            selectedCadastralArea: {
                code: itemToUpdate.cadastralTerritoryCode ?? null,
                name: itemToUpdate.cadastralTerritory ?? '',
            },
            cadastralAreaName: itemToUpdate.cadastralTerritory ?? 'ERROR',
            parcelNum: itemToUpdate.parcelType === PARCEL_TYPE_BUILD ? parNum[0].substr(3) : parNum[0],
            parcelNumOpt: parNum[1] ?? '',
            parcelType: itemToUpdate.parcelType,
            parcel: {
                parcelType: itemToUpdate.parcelType,
                parcelNumber: itemToUpdate.parcelNumber,
                area: itemToUpdate.area, 
                landTypeCode: itemToUpdate.landTypeCode, 
                type: itemToUpdate.type, 
            },
        }));
        setCheckedState({
            changeOfUse: itemToUpdate.changeOfUse,
            divisionOfFusion: itemToUpdate.divisionOfFusion,
            protectionZone: itemToUpdate.protectionZone,
            constructionSite: itemToUpdate.constructionSite,
            division: itemToUpdate.division,
            fusion: itemToUpdate.fusion,
            terrainAdjustments: itemToUpdate.terrainAdjustments,
        });
        if (intention === FORM_06_INTENTION.title) {
            setParcelState({
                actualParcelUsage: itemToUpdate.actualParcelUsage,
                newParcelUsage: itemToUpdate.newParcelUsage,
            });
        }
    };

    useEffect(() => {
        if (!itemToUpdate) {
            return;
        }

        setParcelIsReadOnly(itemToUpdate !== null);
        spreadPozemek();
    }, []);

    const findCadastrals = async (cadastralAreaName, signal) => {
        if (parcelIsReadOnly)
            return;

        // if (cadastralAreaName.length < 3) {
        //     setState(prev => ({ ...prev, cadastralAreaList: [], selectedCadastralArea: null, }));
        //     resetPozemek(false);
        //     return;
        // }
    
        const pattern = /\(.*\)/;
        const withOkres = pattern.test(cadastralAreaName);
        const areaName = withOkres ? cadastralAreaName.split('(')[0].trim() : cadastralAreaName;
        
        const areaObj = state.cadastralAreaList?.find(el => el.name?.trim().toLowerCase() === areaName.toLowerCase()) ?? null;
        setState(prev => ({ ...prev, selectedCadastralArea: areaObj }));
        if (areaObj === null && !itemToUpdate)
            setState(prev => ({ ...prev, parcel: null }));

        if (!withOkres) {
            const updatedObj = { name: cadastralAreaName };
            setLoadings((prevState) => ({...prevState, isCadastralsLoading: true}));
            await handleGetCadastralAreas(updatedObj, token, setState, setLoadings, signal);
        }
    };

    const setCadastral = (selectedCadastralAreaIndex) => {
        const areaObj = state.cadastralAreaList[selectedCadastralAreaIndex] ?? null;

        if (areaObj) {
            setState(prev => ({ ...prev, selectedCadastralArea: areaObj }));
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (parcelIsReadOnly)
            return;

        if (!debouncedCityName || debouncedCityName.trim() === '') {
            setLoadings((prevState) => ({
                ...prevState,
                isCityLoading: false,
                isCadastralsLoading: false,
            }));
            return;
        }

        if (debouncedCityName?.length === 1 && state.cadastralAreaList.length > 0) {
            setState(prev => ({ ...prev, cadastralAreaList: [], selectedCadastralArea: null, }));
            // resetPozemek(false);
        }
        // if (debouncedCityName.length < 3) {
        //     setState(prev => ({ ...prev, cityList: [], selectedCity: null,  }));
        //     return;
        // }
        const isCadastralAreas = debouncedCityName.includes(';');

        if (!isCadastralAreas) {
            setLoadings((prevState) => ({...prevState, isCityLoading: true}));
            handleGetParcelCity(debouncedCityName, token, setState, setLoadings, signal);
        } else {
            const code = debouncedCityName.split(';').pop();
            const cityObject = state.cityList.find(el => Number(el.code) === Number(code));
            const builtRequest = {
                city: { ...cityObject }
            };
            setLoadings((prevState) => ({...prevState, isCadastralsLoading: true}));
            handleGetCadastralAreas(builtRequest, token, setState, setLoadings, signal);
        }

        return () => {
            controller.abort();
        };
    }, [debouncedCityName]);

    const findParcel = async (signal) => {
        if (parcelIsReadOnly)
            return;

        // setState(prev => ({ ...prev, parcel: null, }));
        // resetParcelSettings();
        
        if (!state.selectedCadastralArea || !state.parcelNum || state.parcelNum.trim() === '') {
            setLoadings((prevState) => ({...prevState, isParcelLoading: false}));
            return;
        }

        const objToSend = {
            cadastralTerritory: {
                ...state.selectedCadastralArea
            },
            rootNumber: state.parcelNum,
            subNumber: state.parcelNumOpt || null,
            islandParcel: state.parcelType === PARCEL_TYPE_LAND ?? true,
            isBuildingParcel: state.parcelType === PARCEL_TYPE_BUILD ?? false,
        };
        setLoadings((prevState) => ({...prevState, isParcelLoading: true}));
        await handleGetParcel(objToSend, token, setState, null, setLoadings, state, setParcelNotFound, signal);
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        findParcel(signal);

        return () => {
            controller.abort();
        };
    }, [state.parcelType, debouncedParcelNum, debouncedParcelNumOpt]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        findCadastrals(debouncedCadastralAreaName, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedCadastralAreaName]);

    return (
        <Modal show={showPozemekModal} onHide={() => handleCancel()} size='lg' centered>
            <Modal.Header className='modal-header--radius modal-background' closeButton bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom modal-background'>
                <Modal.Title bsPrefix='modal-title'>{itemToUpdate ? 'Upravit' : 'Přidat'} pozemek</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-background' bsPrefix='px-sm-5 modal-body-custom d-flex flex-column'>
                <label className='d-flex flex-column label mb-4'>
                    <Row>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                            Obec (povinné)
                            <input
                                type="text"
                                className={`id-input2 bg-white ${isCityLoading ? 'with-loading-and-search' : 'with-search'}`}
                                value={state.cityName ?? ''}
                                list='city_list'
                                disabled={parcelIsReadOnly}
                                onChange={(e) => {
                                    resetPozemek(false);
                                    setState(prev => ({ ...prev, cityName: e.target.value, cadastralAreaName: '', cadastralAreaList: [], selectedCadastralArea: null, parcel: null, }));
                                }}
                            />
                            {state.cityList.length > 0 &&
                            <datalist id="city_list">
                                {state.cityList.map((city) => (
                                    <option
                                        key={city.code}
                                        value={`${city.name} ${city.districtName ? (city.districtCode === 9999 ? `(${city.districtName})` : `(Okres ${city.districtName})`) : ''}; ${city.code}`?.trim()}
                                    />
                                ))}
                            </datalist>}
                        </Col>
                        {(intention !== FORM_13_INTENTION.title) && <Col xs={12} lg={6} className='input-help-text mt-1 mt-lg-4 ps-lg-3'>Zadání obce je povinné. Portál následně dohledá všechny názvy katastrálních území pro zadanou obec.</Col>}
                    </Row>
                </label>
                <label className='d-flex flex-column label mb-4' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                    Katastrální území
                    {(state.cityName?.trim() === '' || parcelIsReadOnly) &&
                    <Row>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                            <input
                                type="text"
                                className={`id-input2 bg-white ${isCadastralsLoading ? 'with-loading' : ''}`}
                                list='cadastral_area_list'
                                value={state.cadastralAreaName ?? ''}
                                disabled={parcelIsReadOnly}
                                onChange={(e) => {
                                    resetPozemek(false);
                                    setState(prev => ({ ...prev, cadastralAreaName: e.target.value}));
                                }}
                            />
                            {!parcelIsReadOnly && state.cadastralAreaList.length > 0 &&
                                <datalist id="cadastral_area_list">
                                    {state.cadastralAreaList.map((area) => {
                                        return <option key={area.code} value={`${area.name} ${area.districtName ? (area.districtCode === 9999 ? `(${area.districtName})` : `(Okres ${area.districtName})`) : ''}`?.trim()} />;
                                    })}
                                </datalist>}
                        </Col>
                    </Row>}
                    {!(state.cityName?.trim() === '') && !parcelIsReadOnly &&
                    <Row>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                            <select
                                className={`id-input2 bg-white dropdown-input ${isCadastralsLoading ? 'with-loading' : ''}`}
                                value={state.cadastralAreaName ?? ''}
                                disabled={parcelIsReadOnly}
                                onChange={(e) => {
                                    resetPozemek(false);
                                    setState(prev => ({ ...prev, cadastralAreaName: e.target.value}));
                                    setCadastral(e.target.selectedIndex - 1);
                                }}
                            >
                                <option key='' value=''>Vyberte</option>
                                {state.cadastralAreaList.map((area) => {
                                    return <option key={area.code} value={`${area.name} ${area.districtName ? (area.districtCode === 9999 ? `(${area.districtName})` : `(Okres ${area.districtName})`) : ''}`?.trim()}>{`${area.name} ${area.districtName ? (area.districtCode === 9999 ? `(${area.districtName})` : `(Okres ${area.districtName})`) : ''}`?.trim()}</option>;
                                })}
                            </select>
                        </Col>
                    </Row>}
                </label>
                <>
                    <p style={{color: '#3B3B3B', lineHeight: '24px', letterSpacing: '0.0125em', fontSize: '1em', fontWeight: ' 400'}} className='p-0 m-0 mb-3'>Typ parcely</p>
                    <label className='d-flex align-items-center mb-2 label'>
                        <input 
                            type='radio'
                            name='id'
                            className='radio'
                            checked={state.parcelType === PARCEL_TYPE_LAND}
                            disabled={parcelIsReadOnly}
                            onChange={() => {
                                resetPozemek(false);
                            }} 
                        />
                    Pozemková
                    </label>
                    <label className='d-flex align-items-center mb-4 label'>
                        <input 
                            type='radio'
                            name='id'
                            className='radio'
                            checked={state.parcelType === PARCEL_TYPE_BUILD}
                            disabled={parcelIsReadOnly}
                            onChange={() => {
                                resetPozemek(false);
                                setState(prev => ({...prev, parcelType: PARCEL_TYPE_BUILD}));
                            }} 
                        />
                    Stavební
                    </label>
                </>

                <label className='d-flex flex-column label'>
                    <Row className='d-flex flex-column' style={{ fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868' }}>
                        Parcelní číslo
                        <Col xs={12} lg={6} className='d-lg-flex'>
                            {state.parcelType === PARCEL_TYPE_BUILD &&
                            <Col xs={12} lg={2} className='d-flex align-items-center justify-content-center' style={{ fontSize: '.909rem', color: '#686868', fontWeight: '400' }}>
                                St.
                            </Col>}
                            <Col xs={12} lg={5}>
                                <input
                                    type='text'
                                    className={`id-input2 bg-white ${isParcelLoading ? 'with-loading' : ''}`}
                                    value={state.parcelNum ?? ''}
                                    disabled={parcelIsReadOnly}
                                    style={{ width: '100%', height: '39px', border: 'solid #DDDDDD 1px', borderRadius: '3px' }}
                                    onChange={(e) => setState(prev => ({ ...prev, parcelNum: e.target.value }))}
                                />
                            </Col>
                            <Col xs={12} lg={2} className='d-flex align-items-center justify-content-center' style={{ fontSize: '.909rem', color: '#686868', fontWeight: '400' }}>
                                /
                            </Col>
                            <Col xs={12} lg={5}>
                                <input
                                    type='text'
                                    className={`id-input2 bg-white ${isParcelLoading ? 'with-loading' : ''}`}
                                    value={state.parcelNumOpt ?? ''}
                                    disabled={parcelIsReadOnly}
                                    style={{ width: '100%', height: '39px', border: 'solid #DDDDDD 1px', borderRadius: '3px' }}
                                    onChange={(e) => setState(prev => ({ ...prev, parcelNumOpt: e.target.value }))}
                                />
                            </Col>
                        </Col>
                    </Row>
                </label>
                {parcelNotFound &&
                    <Container fluid className='my-4'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Zadané parcelní číslo nebylo nalezeno.</p>
                    </Container>
                }
                <Container fluid className='my-4'>
                    {state.parcel &&
                        <FoundParcelInfo state={state} />
                    }
                </Container>

                {state.parcel &&
                    (intention === FORM_01_INTENTION.title 
                    || intention === FORM_02_INTENTION.title 
                    || intention === FORM_03_INTENTION.title 
                    || intention === FORM_04_INTENTION.title 
                    || intention === FORM_09_INTENTION.title 
                    || (intention === FORM_16_INTENTION.title && !isAffected)
                    || intention === FORM_17_INTENTION.title 
                    || intention === FORM_18_INTENTION.title
                    || (intention === FORM_19_INTENTION.title && !isAffected)
                    ) 
                    &&
                    <Container>
                        <p style={{ fontWeight: '600', fontSize: '1.21rem', letterSpacing: '0.01rem', marginBottom: '1.5rem' }}>Požadavky k pozemku</p>
                        {intention !== FORM_19_INTENTION.title && 
                        <>
                            <label className='d-flex align-items-center mb-4'>
                                <input
                                    className='me-12'
                                    type="checkbox"
                                    name="changeOfUse"
                                    checked={checkedState.changeOfUse}
                                    onChange={handleChange}
                                />
                            Změna využití území
                            </label>
                            <label className='d-flex align-items-center mb-4'>
                                <input
                                    className='me-12'
                                    type="checkbox"
                                    name="divisionOfFusion"
                                    checked={checkedState.divisionOfFusion}
                                    onChange={handleChange}
                                />
                            Dělení nebo scelení pozemku
                            </label>
                            <label className='d-flex align-items-center mb-4'>
                                <input
                                    className='me-12'
                                    type="checkbox"
                                    name="protectionZone"
                                    checked={checkedState.protectionZone}
                                    onChange={handleChange}
                                />
                            Navrhované ochranné pásmo
                            </label>
                        </>}
                        {/* TODO add mapping when it will be on BE */}
                        {(intention === FORM_19_INTENTION.title) && <label className='d-flex align-items-center mb-4'>
                            <input
                                className='me-12'
                                type="checkbox"
                                name="terrainAdjustments"
                                checked={checkedState.terrainAdjustments}
                                onChange={handleChange}
                            />
                            Terénní úprava
                        </label>}
                        {(intention === FORM_19_INTENTION.title || intention === FORM_04_INTENTION.title || intention === FORM_02_INTENTION.title) && 
                        <label className='d-flex align-items-center' style={{ marginBottom: '2.5rem' }}>
                            <input
                                className='me-12'
                                type="checkbox"
                                name="constructionSite"
                                checked={checkedState.constructionSite}
                                onChange={handleChange}
                            />
                            Staveniště
                        </label>}
                    </Container>}

                {/* FORM 06 only, when parcel data loaded */}
                {(state.parcel &&
                    intention === FORM_06_INTENTION.title) &&
                    <Container>

                        <Row className='d-flex flex-column'>
                            <p className='p-0' style={{fontWeight: '600', fontSize: '1.21rem', letterSpacing: '0.01rem', marginBottom: '1rem'}}>Užívání</p>
                            <Col lg={6}>
                                <label className='p-0'>
                                    {/** INPUT actualParcelUsage Stávající způsob užívání pozemku */}
                                    Stávající způsob užívání pozemku
                                    <input
                                        type={'text'}
                                        className='id-input2 mb-2'
                                        value={parcelState.actualParcelUsage ?? ''}
                                        onChange={(e) => setParcelState(parcelState => ({...parcelState, actualParcelUsage: e.target.value}))}
                                    />
                                </label>
                            </Col>
                            <Col lg={6}>
                                <label className='p-0'>
                                    {/** INPUT newParcelUsage Nový způsob užívání pozemku */}
                                    Nový způsob užívání pozemku
                                    <input
                                        type={'text'}
                                        className='id-input2'
                                        value={parcelState.newParcelUsage ?? ''}
                                        onChange={(e) => setParcelState(parcelState => ({...parcelState, newParcelUsage: e.target.value}))}
                                    />
                                </label>
                            </Col>
                        </Row>                    
                        <p style={{ marginBottom: '1.5rem' }}></p>
                    </Container>}

                {/* FORM 07 only, when parcel data loaded */}
                {(state.parcel &&
                    intention === FORM_07_INTENTION.title) &&
                    <Container>
                        <p style={{ fontWeight: '600', fontSize: '1.21rem', letterSpacing: '0.01rem', marginBottom: '1.5rem' }}>Dělení / scelení</p>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input 
                                type='radio'
                                name='division'
                                className='radio'
                                checked={checkedState.division}
                                onChange={() => { 
                                    setCheckedState(prev => ({...prev, division: true, fusion: false}));
                                }} 
                            />
                            Dělení
                        </label>
                        <label className='d-flex align-items-center mb-4 label'>
                            <input 
                                type='radio'
                                name='division'
                                className='radio'
                                checked={checkedState.fusion}
                                onChange={() => { 
                                    setCheckedState(prev => ({...prev, division: false, fusion: true}));
                                }} 
                            />
                            Scelení
                        </label>
                        <p style={{ marginBottom: '1.5rem' }}></p>
                    </Container>}

            </Modal.Body>

            <Modal.Footer className='modal-footer--radius modal-background' bsPrefix='d-flex px-sm-5 modal-footer-custom'>
                <Row className='button-wrapper'>
                    <Col className='d-flex flex-column flex-lg-row button-wrapper--inner' xs={12} lg={6}>
                        {
                            (!noFieldsToUpdate || !itemToUpdate) &&
                            <button className={`mb-4 mb-lg-0 me-lg-3 modal-button modal-button--filled ${isDisabled ? 'disabled-filled' : ''}`}
                                onClick={() =>{
                                    if (itemToUpdate) {
                                        handleUpdatePozemek();
                                    } else {
                                        handleAddPozemek();
                                    }
                                }}
                                disabled={isDisabled}>
                                {itemToUpdate ? 'Upravit' : 'Přidat'}
                            </button>
                        }
                        {!itemToUpdate && <button className={`modal-button ${isDisabled ? 'disabled-unfilled' : ''}`}
                            onClick={() => handleAddAnother()}
                            disabled={isDisabled}>
                            Přidat další
                        </button>}
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <button className='modal-button' onClick={() => handleCancel()}>{noFieldsToUpdate && itemToUpdate ? 'Zavřít náhled' : 'Zrušit'}</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

AddPozemekModal.propTypes = {
    itemToUpdate: PropTypes.object,
    setItemToUpdate: PropTypes.func,
    listData: PropTypes.arrayOf(Object),
    setListData: PropTypes.func,
    setTableRequest: PropTypes.func,
    showPozemekModal: PropTypes.bool.isRequired,
    setShowPozemekModal: PropTypes.func.isRequired,
    isAffected: PropTypes.bool,
    isException: PropTypes.bool,
    handleAddItem: PropTypes.func.isRequired,
    handleUpdateItem: PropTypes.func.isRequired,
    noFieldsToUpdate: PropTypes.bool,
};

export default AddPozemekModal;