import { useState } from 'react';
import { BlockBlobClient } from '@azure/storage-blob';
import { handleDeleteUpload, handleSetUpload, sendDocumentationRequest } from '../../helperFunctions/helpers';
import axios from 'axios';
import { formSave } from '../../apiCalls/formApiCalls';

const useUpload = ({   
    isNotForBlob,
    setProgressElements = undefined,
    setUploads = undefined,
    allDocuments = undefined,
    token = undefined,
    id = undefined,
    setUploadedBytes = undefined,
    urlPath = undefined,
}) => {
    const [uploadState, setUploadState] = useState({
        uploadSuccess: false,
        uploadError: false,
        uploadCancelled: false,
        uploadProgress: null,
    });
    
    const upload = isNotForBlob ?
        () => {
            setUploadState(prev => ({ ...prev, uploadProgress: 100, uploadSuccess: true }));
        } : 
        async (
            url,
            fileObject,
            abortSignal,
        ) => {
            const {blobFile: file, uid, section, size} = fileObject || {};

            if (!url || !file || !abortSignal) {
                return;
            }

            try {
                if (!setProgressElements) setUploadState(prev => ({ ...prev, uploadProgress: 0, uploadError: false }));

                if (setProgressElements) {
                    setProgressElements(prev => [...prev].map(el => {
                        if (el.uid === uid) {
                            return {...el, progress: 0};
                        }
                        return el;
                    }));
                }

                const onProgress = (prog) => {
                    if(!abortSignal.aborted) {
                        const progress = Math.floor((prog.loadedBytes / file.size) * 100);
                        if (!setProgressElements) setUploadState(prev => ({ ...prev, uploadProgress: progress }));
                        if (setProgressElements && progress > 0 && progress < 100) {
                            setProgressElements(prev => [...prev].map(el => {
                                if (el.uid === uid) {
                                    return {...el, progress};
                                }
                                return el;
                            }));
                        }
                    }
                };

                const blockBlobClient = new BlockBlobClient(url);
        
                const uploadOptions = {
                    abortSignal,
                    onProgress,
                };

                const data = await blockBlobClient.uploadData(file, uploadOptions);
                if (Math.floor(data._response.status / 100) === 2) {
                    if (!setProgressElements) setUploadState(prev => ({ ...prev, uploadSuccess: true }));
                    if (setProgressElements) {
                        setProgressElements(prev => [...prev].map(el => {
                            if (el.uid === uid) {
                                return {...el, success: true, message: undefined};
                            }
                            return el;
                        }));
                    }

                    if (setUploads) {
                        handleSetUpload(setUploads, section, uid);
                    }

                    if (!window.location.pathname.startsWith('/zadost/form')) {
                        const source = axios.CancelToken.source();
                        const requestObject = sendDocumentationRequest(id, [...(Object.values(allDocuments || {})?.flat() || []), fileObject]);
                        if (requestObject) {
                            formSave(requestObject, token, urlPath, source);
                        }
                    }

                    if (setUploadedBytes) {
                        setUploadedBytes(prev => Number(prev) + Number(size));
                    }
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    if (!setProgressElements) setUploadState(prev => ({ ...prev, uploadCancelled: true }));
                    console.log('Upload aborted');
                } else {
                    if (!setProgressElements) setUploadState(prev => ({ ...prev, uploadError: true }));         
                    console.log('Upload error:', error);
                }
                if (setUploads && setProgressElements) {
                    handleDeleteUpload(setUploads, section, uid);
                    handleDeleteUpload(setProgressElements, undefined, uid, 'forProgress');
                }
            }
        }; 

    return { 
        upload, 
        uploadSuccess: uploadState.uploadSuccess, 
        uploadError: uploadState.uploadError, 
        setUploadState, 
        uploadProgress: uploadState.uploadProgress, 
        uploadCancelled: uploadState.uploadCancelled 
    };
};

export default useUpload;
