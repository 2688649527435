import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'react-oauth2-code-pkce';
import { stop } from '../assets';
import { Button } from './index.js';

const AuthError = ({
    headerText,
    secondaryText,
    errorNumber,
}) => {
    const { login } = useContext(AuthContext);
    
    const delayedLogin = useCallback(() => {
        setTimeout(() => {
            try {
                login();
            } catch (error) {
                console.error('Login failed:', error);
            }
        }, 0);
    }, []);

    return(
        <div className='card-content card vh-100 d-flex justify-content-center align-items-center p-3'>
            <img className='error-page--img' src={stop} alt='something went wrong'/>
            <p className='error-page--header-text'>{headerText}</p>
            <p className='error-page--secondary-text'>
                {secondaryText}
            </p>
            <div className='d-flex justify-content-center'>
                <Button onClickFunction={delayedLogin} buttonLabel={'Přihlásit se'}/>
            </div>
            <i>Error {errorNumber}</i>
        </div>
    );
};

AuthError.propTypes = {
    headerText: PropTypes.string,
    secondaryText: PropTypes.string,
    errorNumber: PropTypes.string,
};

export default AuthError;
