import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { shouldNotSave } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender';
import usePrevious from '../CustomHooks/usePrevious';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent = ({setCheckbox, urlPath}) => {
    const {intention, stepValue, saveFormRef, id} = useContext(FormContext); 
    // const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            processPermitBuild: true,
            processPermitBuildFast: false,
            applicationExceptionRequirementBuild: null,
            applicationAlterPermitPublicBuild: null,
        }
    });
    const prevRequest= usePrevious(request);
    const firstRender = useFirstRender();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data.buildApplicationForms[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form, 
                        processPermitBuild: loadedData?.processPermitBuild ?? true,
                        processPermitBuildFast: loadedData?.processPermitBuildFast ?? false,
                        applicationExceptionRequirementBuild: loadedData?.applicationExceptionRequirementBuild ?? false,
                        applicationAlterPermitPublicBuild: loadedData?.applicationAlterPermitPublicBuild ?? false,
                    } 
                    }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (request.form.applicationExceptionRequirementBuild === null && request.form.applicationAlterPermitPublicBuild === null) {
            return;
        }

        setCheckbox({
            first: request.form.applicationExceptionRequirementBuild ?? false,
            second: request.form.applicationAlterPermitPublicBuild ?? false,
        });
    }, [request.form.applicationExceptionRequirementBuild, request.form.applicationAlterPermitPublicBuild]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));


    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='pb-4'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>
            <Row className='pb-3'>
                <Row className='py-4'>
                    <p className='p-0 title secondary'>Žádám o povolení</p>
                </Row>
                <Row className='py-2'>
                    <Col className='d-flex row'>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="radio"
                                name='zadost'
                                value='normal'
                                checked={request.form.processPermitBuild ?? true}
                                onChange={()=>{
                                    setRequest(state => ({...state, form: {...state.form, processPermitBuild: true, processPermitBuildFast: false }}));
                                }}
                            />
                                v řízení o povolení stavby
                        </label>
                        <Row className='pb-4'>
                            <Col md={6} className='pb-2'>
                                <label className='ls-1p25 p-0 d-flex align-items-start'>
                                    <input
                                        className='me-3'
                                        type="radio" 
                                        name='zadost'
                                        value='fast' 
                                        checked={request.form.processPermitBuildFast ?? false} 
                                        onChange={()=>{
                                            setRequest(state => ({...state, form: {...state.form, processPermitBuild: false, processPermitBuildFast: true }}));              
                                        }}
                                    />
                                        ve zrychleném řízení o povolení stavby
                                </label>
                            </Col>
                            <Col md={6} className='d-flex align-items-start ps-0 ps-md-3'>
                                <span className='fst-italic'>Pro zrychlené řízení je třeba doložit souhlasy všech účastníků řízení.</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row className='py-4'>
                <p className='p-0 title third'>Součástí žádosti je</p>
            </Row>
            <Row className='py-2 d-flex flex-column'>
                <Col md={6}>
                    <label className={'ls-1p25 p-0 pb-2 d-flex align-items-start'}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.applicationExceptionRequirementBuild ?? false}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {
                                    ...state.form, applicationExceptionRequirementBuild: e.target.checked,
                                    ...(!e.target.checked && { 
                                        applicationExceptionProvisions: '',
                                        applicationExceptionDescription: '',
                                        applicationExceptionReason: '',
                                    })
                                }}));
                            }}
                        />
                            žádost o výjimku z požadavků na výstavbu                
                    </label>
                </Col>
                <Col md={6}>
                    <label className={'ls-1p25 p-0 d-flex align-items-start'}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.applicationAlterPermitPublicBuild ?? false}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {
                                    ...state.form, applicationAlterPermitPublicBuild: e.target.checked,
                                    ...(!e.target.checked && { 
                                        permissions: [],
                                    })
                                }}));
                            }}
                        />
                            žádost o změnu nebo zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření
                    </label>
                </Col>
            </Row>
        </Container>
    );
};

RozsahComponent.propTypes = {
    
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent;